 /* eslint-disable */
import React, { useState, useEffect } from "react";
import Footer from '../../../components/footer/footer';
import Header from '../../../components/header/header';
import ProductTable from "../productManagement/productTable";
import Swal from 'sweetalert2'
import BaseUrl from "../../../../config/BaseUrl"
import MaterialTable from 'material-table'
import image_url from "../../../../config/BaseUrl"
import { Link, NavLink, useHistory } from "react-router-dom";
import DropdownButton from 'react-bootstrap/DropdownButton'
import Button from 'react-bootstrap/Button'
import { makeStyles } from "@material-ui/core/styles";
import { getApiWithToken, PutApi, PostApi, DeleteApi, OrderCancelApiPut } from "../../../../config/Api/FakeuserApi";
import { RemoveRedEye } from "@material-ui/icons"
import { Modal } from 'react-bootstrap';

import '../productManagement/productManagement.css';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    input: {
        display: "none",
    },
}));



function myfunc() {
    // var print=document.getElementById("print")

    // print.style.display = "none";
    // window.print();
    // print.style.display = "inline-block";



    var mywindow = window.open('', 'PRINT', 'height=400,width=600');

    mywindow.document.write('<html><head><title>' + "Invoice" + '</title>');
    mywindow.document.write('</head><body >');
    mywindow.document.write('<h1 style="text-align:center">' + "Invoice" + '</h1>');
    mywindow.document.write(document.getElementById("elem").innerHTML);
    mywindow.document.write('</body></html>');
    mywindow.document.close(); // necessary for IE >= 10
    mywindow.print();

    // mywindow.focus(); // necessary for IE >= 10*/

    // mywindow.close();

    return true;

}



export default function Orders(props) {

    const [GetOrders, SetGetOrders] = useState([])
    const [Loader, setLoader] = useState(false)
    const [selectedRow, setSelectedRow] = useState(null);
    const [GetAllAIvendors, SetGetAIllAvendors] = useState([])
    const [Active, SetActive] = useState(true);
    const [Accept, SetAccept] = useState(false);
    const [packed, Setpacked] = useState(false);
    const [ontheway, Setontheway] = useState(false);
    const [arrived, Setarrived] = useState(false);
    const [buttonshow, Setbuttonshow] = useState(false);
    const [buttoninv, Setbuttoninv] = useState(false);
    const [invoice, Setinvoice] = useState(false);
    // Second Modal
    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);

    const handleShow2 = () => {

        setShow2(true);

    }

    const [Getallinvoicedata, setGetallinvoicedata] = useState([]);








    const Token = localStorage.getItem('SellerToken');

    useEffect(() => {
        ViewPendingProducts("Accept")
    }, [])

const [loaderActive , setLoaderActive] = useState(true)

    // View ALL pending Products

    const [statusCancel , setStatusCancel] = useState()

    const ViewPendingProducts = (status) => {
        setStatusCancel(status)
        setLoaderActive(true)
        if (status == "Pending" ) {
            SetAccept(true)
            Setpacked(false)
            Setontheway(false)
            Setarrived(false)
            Setbuttonshow(true)
            Setbuttoninv(false)
            Setinvoice(false)
            SetActive("Pending")
        }
        if (status == "Cancelation_is_pending" ) {
            SetAccept(false)
            Setpacked(false)
            Setontheway(false)
            Setarrived(false)
            Setbuttonshow(true)
            Setbuttoninv(false)
            Setinvoice(false)
            SetActive("Cancelation_is_pending")
        }
        if (status == "Accept") {
            SetAccept(false)
            Setpacked(true)
            Setontheway(false)
            Setarrived(false)
            SetActive("Accept")
            Setbuttonshow(true)
            Setbuttoninv(true)
            Setinvoice(true)



        }
        if (status == "Packed") {
            SetAccept(false)
            Setpacked(false)
            Setontheway(true)
            Setarrived(false)
            SetActive("Packed")
            Setbuttonshow(true)
            Setbuttoninv(true)
            Setinvoice(true)



        }
        if (status == "Ontheway") {
            SetAccept(false)
            Setpacked(false)
            Setontheway(false)
            Setarrived(true)
            Setbuttonshow(true)
            SetActive("Ontheway")
            Setbuttoninv(true)
            Setinvoice(true)


        }

        if (status == "Arrived") {
            SetAccept(false)
            Setpacked(false)
            Setontheway(false)
            Setarrived(false)
            SetActive("Arrived")
            Setbuttonshow(false)
            Setbuttoninv(true)
            Setinvoice(true)



        }
        if (status == "Recieved") {
            SetAccept(false)
            Setpacked(false)
            Setontheway(false)
            Setarrived(false)
            SetActive("Recieved")
            Setbuttonshow(false)
            Setbuttoninv(true)
            Setinvoice(true)



        }

        if (status == "Cancel") {
            SetAccept(false)
            Setpacked(false)
            Setontheway(false)
            Setarrived(false)
            SetActive('Cancel')
            Setbuttonshow(false)
            Setbuttoninv(true)
            Setinvoice(true)



        }


console.log(status,'status');


        setLoader(true);

        getApiWithToken(`${BaseUrl.base_url}/GetOrder?OrderStatus=${status}`, "", Token)
            .then(({ data }) => {
                setLoader(false);
                if (data.status) {
                    SetGetOrders(data.data)
                    console.log("PENDING PRODCT==>", data.data)
                    setLoaderActive(false)

                } else {
                    // $(".loader").hide();
                    Swal.fire({
                        title: "Opps",
                        text: data.message,
                        icon: "error"
                    })
                    setLoaderActive(false)

                }
            }).catch(function (e) {
                // $(".loader").hide();
                Swal.fire({
                    title: "Opps",
                    text: e.message,
                    icon: "error"
                })
                setLoaderActive(false)

            })

    }


    // UPDATE STATUS PUT API

    const UpdateStatus = (status, id) => {
        setLoader(true);
        var formdata = new FormData();

        formdata.append("OrderStatus", status);
        formdata.append("Orderid", id);


        PutApi(`${BaseUrl.base_url}/GetOrder`, formdata, Token)
            .then(({ data }) => {
                setLoader(false);
                if (data.status) {
                    console.log("data==>", data)
                    ViewPendingProducts("Accept")
                    // handleClose2()
                    Swal.fire({
                        title: "success",
                        text: data.message,
                        icon: "success"
                    })
                } else {
                    // $(".loader").hide();
                    Swal.fire({
                        title: "Opps",
                        text: data.message,
                        icon: "error"
                    })
                }
            }).catch(function (e) {
                // $(".loader").hide();
                Swal.fire({
                    title: "Opps",
                    text: e.message,
                    icon: "error"
                })
            })
    }


    //invice api

    const ViewInvoiceData = (OrderNo) => {




        setLoader(true);

        getApiWithToken(`${BaseUrl.base_url}/GenerateInvoice?OrderNo=${OrderNo}`, "", Token)
            .then(({ data }) => {
                setLoader(false);
                if (data.status) {
                    setGetallinvoicedata(data.data)
                    handleShow2()
                    console.log("invoive==>", data)
                    // setdisable(true);
                    // $(".loader").hide();

                } else {
                    // $(".loader").hide();
                    Swal.fire({
                        title: "Opps",
                        text: data.message,
                        icon: "error"
                    })
                }
            }).catch(function (e) {
                // $(".loader").hide();
                Swal.fire({
                    title: "Opps",
                    text: e.message,
                    icon: "error"
                })
            })

    }


    const [showCancelModal, setShowCancelModal] = useState(false);
    const [cancelOrderNo, setCancelOrderNo] = useState(null);
    const [reasonCancelOrder, setreasonCancelOrder] = useState(null);
    const [reasonerrors, setreasonerrors] = useState(null);


    const handleCancelClick = (orderNo) => {
        setCancelOrderNo(orderNo);
        setShowCancelModal(true);
      };
    
      const handleCancelOrder = () => {
     console.log(cancelOrderNo,'reasonCancelOrder');
     const formdata = new FormData()
     formdata.append('order_id', cancelOrderNo.id)
     formdata.append('OrderStatus', 'Cancel')
     formdata.append('feedback', reasonCancelOrder)
        if(reasonCancelOrder){

// https://ecommerceapi4.devssh.xyz
// http://192.168.100.100:8000
PutApi(`https://ecommerceapi3.devssh.xyz/webapi/OrderCanceling`, formdata, Token)
            .then(({ data }) => {
                if (data.status) {
            setShowCancelModal(false);
                    Swal.fire({
                        title: "success",
                        text: data.message,
                        icon: "success"
                    })
                } else {
                    Swal.fire({
                        title: "Opps",
                        text: data.message,
                        icon: "error"
                    })
                }
            }).catch(function (e) {
                // $(".loader").hide();
                Swal.fire({
                    title: "Opps",
                    text: e.message,
                    icon: "error"
                })
            })



        }else{
            setreasonerrors("Select Any Reason")
        }
      };

      const hanleConfirmCancelOrder = ()=>{
// webapi/OrderConfirmCancelation
// order_id
// OrderStatus
const formdata = new FormData()
formdata.append('order_id', cancelOrderNo.id)
formdata.append('OrderStatus', 'Cancel')

PutApi(`https://ecommerceapi3.devssh.xyz/webapi/OrderConfirmCancelation`, formdata, Token)
.then(({ data }) => {
    if (data.status) {
setShowCancelModal(false);
        ViewPendingProducts("Cancelation_is_pending")
        Swal.fire({
            title: "success",
            text: data.message,
            icon: "success"
        })
    } else {
        Swal.fire({
            title: "Opps",
            text: data.message,
            icon: "error"
        })
    }
}).catch(function (e) {
    // $(".loader").hide();
    Swal.fire({
        title: "Opps",
        text: e.message,
        icon: "error"
    })
})

      }

      if(loaderActive){
        return(
          <div style={{width:'100%',height:'100vh',display:'flex',justifyContent:'center',alignItems:'center'}}>
            
            <img src={'./assets/img/loader.svg'} alt="loader"/>
        
            </div>
        )}
     
    
    return (
        <>
            {/* {Loader ? <div className="loader" /> : null} */}
            <div id="app">
                <div className="main-wrapper main-wrapper-1">
                    <Header />
                    <div className="main-content">
                        <section className="section">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card card-primary">
                                        <div className="card-header">
                                            <h4>Orders Overview</h4>
                                        </div>
                                        <div className="card-body">
                                            <section className="section">
                                                <div className="section-body">
                                                    <div className="row clearfix">
                                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                            <div className="card">

                                                                <div className="card-body" style={{ paddingTop: 0 }}>
                                                                    <div className="card-body">
                                                                        {/* <button type="button" onClick={() => ViewPendingProducts("Pending")} class={Active ? " expTopbar btn btn-primary active" : "expTopbar btn btn-outline-primary"}>Pending</button> */}
                                                                        <button type="button" onClick={() => ViewPendingProducts("Accept")}  class={Active == "Accept" ? " expTopbar btn btn-primary active" : "expTopbar btn btn-outline-primary"} >Confirmed</button>
                                                                        <button type="button" onClick={() => ViewPendingProducts("Packed")} class={Active == "Packed" ? " expTopbar btn btn-primary active" : "expTopbar btn btn-outline-primary"}>Shipped</button>
                                                                        <button type="button" onClick={() => ViewPendingProducts("Ontheway")} class={Active == "Ontheway" ? " expTopbar btn btn-primary active" : "expTopbar btn btn-outline-primary"}>Delivered</button>
                                                                        <button type="button" onClick={() => ViewPendingProducts("Arrived")} class={Active == "Arrived" ? " expTopbar btn btn-primary active" : "expTopbar btn btn-outline-primary"}>Disputed</button>
                                                                        <button type="button" onClick={() => ViewPendingProducts("Recieved")} class={Active == "Recieved" ? " expTopbar btn btn-primary active" : "expTopbar btn btn-outline-primary"}>Received</button>
                                                                        <button type="button" onClick={() => ViewPendingProducts("Cancel")} class={Active == "Cancel" ? " expTopbar btn btn-primary active" : "expTopbar btn btn-outline-primary"}>Cancelled</button>
                                                                        <button type="button" onClick={() => ViewPendingProducts("Cancelation_is_pending")} class={Active == "Cancelation_is_pending" ? " expTopbar btn btn-primary active" : "expTopbar btn btn-outline-primary"}>Cancelation is pending</button>
                                                                        {/* <button type="button" onClick={() => ViewAllProducts("Incomplete")} class="expTopbar btn btn-outline-primary">InComplete</button> */}
                                                                        {/* <button type="button" onClick={() => UpdateStatus()} class="expTopbar btn btn-outline-primary">Deleted</button> */}


                                                                    </div>


                                                                    <div className="card-body" style={{ padding: 5 }}>
                                                                        <div className="row" style={{ marginLeft: 10 }}>
                                                                            {/* <div className="col-2 onlineNav">
                                                                                <span className="dropdown">
                                                                                    <button style={{ height: 42, border: "1px solid blue" }} className="expTopbar btn btn-outline-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                                                        Category
                                                                                    </button>
                                                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                                        <li><a className="dropdown-item" href="#">Action</a></li>
                                                                                        <li><a className="dropdown-item" href="#">Another action</a></li>
                                                                                        <li><a className="dropdown-item" href="#">Something else here</a></li>
                                                                                    </ul>
                                                                                </span>
                                                                            </div> */}
                                                                            {/* <div className="col-2 onlineNav">
                                                                                <input type="text" style={{ border: "1px solid blue" }} className="form-control " id="autoSizingInput" placeholder="Order Number" />
                                                                            </div>
                                                                            <div className="col-2 onlineNav">
                                                                                <input type="text" style={{ border: "1px solid blue" }} className="form-control " id="autoSizingInput" placeholder="Costumer" />
                                                                            </div>
                                                                            <div className="col-2 onlineNav">
                                                                                <input type="text" style={{ border: "1px solid blue" }} className="form-control" id="autoSizingInput" placeholder="Product" />
                                                                            </div>
                                                                            <div className="col-2 onlineNav">
                                                                                <input type="text" style={{ border: "1px solid blue" }} className="form-control " id="autoSizingInput" placeholder="Product tag" />
                                                                            </div>
                                                                            <div className="col-2 onlineNav">
                                                                                <input type="text" style={{ border: "1px solid blue" }} className="form-control" id="autoSizingInput" placeholder="Seller SKU" />
                                                                            </div>
                                                                            <div className="col-2 onlineNav">
                                                                                <input type="text" style={{ border: "1px solid blue" }} className="form-control" id="autoSizingInput" placeholder="Payment" />
                                                                            </div> */}
                                                                            {/* <div className="col-2 onlineNav">
                                                                                <button style={{ height: 42,border: "1px solid blue"}} type="search" className="expTopbar btn btn-outline-secondary">Search</button>
                                                                            </div> */}
                                                                        </div>
                                                                        {/* <div className="row mt-2">
                                                                            <div className="col-2 pl-4">
                                                                                <label style={{ color: "blue" }}>Start Date</label>
                                                                                <span><input type="date" style={{ border: "1px solid blue" }} className="form-control" id="autoSizingInput" placeholder="Start Date" /></span>
                                                                                 <span style={{marginTop: 10, marginLeft: 7}}>Selected : 0</span>
                                                                             <button type="search" className="expTopbar btn btn-outline-secondary">Inactivate</button>
                                                                                <button type="search" className="expTopbar btn btn-outline-secondary">Batch Delete</button>
                                                                            </div>
                                                                            <div className="col-2 pl-1">
                                                                                <label style={{ color: "blue" }}>End Date</label>
                                                                                <span><input type="date" style={{ border: "1px solid blue" }} className="form-control" id="autoSizingInput" placeholder="End Date" /></span>
                                                                                 <span style={{marginTop: 10, marginLeft: 7}}>Selected : 0</span>
                                                                         <button type="search" className="expTopbar btn btn-outline-secondary">Inactivate</button>
                                                                                <button type="search" className="expTopbar btn btn-outline-secondary">Batch Delete</button>
                                                                            </div>
                                                                            <div className="col-8 onlineNav1">
                                                                                <div className="btn-group" role="group" aria-label="Basic outlined example">
                                                                                    <button style={{ height: 42, border: "1px solid blue" }} type="search" className="expTopbar btn btn-outline-secondary">Search</button>
                                                                                     <button type="button" className="btn btn-outline-primary">Product</button>
                                                                                    <button type="button" className="btn btn-outline-primary">SKU</button>
                                                                                </div>
                                                                            </div>
                                                                        </div> */}
                                                                        <div className="card-body">
                                                                            {/* <MaterialTable
                                                                                data={GetOrders}
                                                                                title=" "
                                                                                columns={[
                                                                                    // { title: 'Product', field: "", render: item => <img src={image_url.image_url(item.image1)} alt="" border="3" height="80" width="80" /> },
                                                                                    { title: 'Product_Name', field: 'Product_Name' },
                                                                                    { title: 'Order_No', field: 'OrderNo' },
                                                                                    { title: 'Send_To', field: 'UserName' },
                                                                                    // { title: 'Packed', field: '' },
                                                                                    { title: 'Order_Status', field: 'OrderStatus' },
                                                                                    { title: 'Payment_Method', field: 'PaymentMethod' },
                                                                                    { title: 'Quantity', field: 'Quantity', },
                                                                                    { title: 'Created', field: 'Created', },
                                                                                    { title: 'Address', field: 'Address' },
                                                                                    { title: 'State', field: 'State' },

                                                                                    {
                                                                                        title: `${buttoninv ? 'Invoice_Generate' : ""}`,

                                                                                        render: rowData => <>
                                                                                            {invoice ? <Button onClick={() => ViewInvoiceData(rowData.OrderNo)} variant="primary">Invoice</Button> : ""}


                                                                                        </>
                                                                                    },

                                                                                    {
                                                                                        title: `${buttonshow ? 'Status' : ""}`,



                                                                                        render: rowData => <>


                                                                                            {Accept ? <Button onClick={() => UpdateStatus("Accept", rowData.id)} variant="primary">Accept</Button> : ""}


                                                                                            {packed ? <Button onClick={() => UpdateStatus("Packed", rowData.id)} variant="primary">Packed</Button> : ""}


                                                                                            {ontheway ? <Button onClick={() => UpdateStatus("Ontheway", rowData.id)} variant="primary">Ontheway</Button> : ""}



                                                                                            {arrived ? <Button onClick={() => UpdateStatus("Arrived", rowData.id)} variant="primary">Arrived</Button> : ""}


                                                                                        </>
                                                                                    }

                                                                                ]}

                                                                                onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
                                                                                options={{
                                                                                    rowStyle: rowData => ({
                                                                                        backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                                                                                    }),
                                                                                    actionsColumnIndex: -1,
                                                                                }}
                                                                                // other props
                                                                                actions={[
                                                                                    rowData => ({
                                                                                        icon: RemoveRedEye,
                                                                                        tooltip: 'View Order Detail',
                                                                                        isFreeAction: true,
                                                                                        onClick: (event, rowData) => {
                                                                                            props.history.push({
                                                                                                pathname: '/OrderDetails',
                                                                                                state: rowData.OrderNo

                                                                                            });

                                                                                        }


                                                                                    }),



                                                                                ]}


                                                                                options={{
                                                                                    // maxBodyHeight: 400,
                                                                                    // overflowX: 'x-scroll',
                                                                                    actionsColumnIndex: 11,
                                                                                    headerStyle: {
                                                                                        backgroundColor: '#222',
                                                                                        color: "white"
                                                                                    }
                                                                                }}
                                                                            /> */}

<>
      <MaterialTable
        data={GetOrders}
        title=" "
        columns={[
          { title: 'Product_Name', field: 'Product_Name' },
          { title: 'Order_No', field: 'OrderNo' },
          { title: 'Send_To', field: 'UserName' },
          { title: 'Order_Status', field: 'OrderStatus' },
          { title: 'Payment_Method', field: 'PaymentMethod' },
          { title: 'Quantity', field: 'Quantity' },
          { title: 'Created', field: 'Created' },
          { title: 'Address', field: 'Address' },
          { title: 'State', field: 'State' },
          {
            title: `${buttoninv ? 'Invoice_Generate' : ""}`,
            render: rowData => invoice ? <Button onClick={() => ViewInvoiceData(rowData.OrderNo)} variant="primary">Invoice</Button> : ""
          },
          {
            title: `${buttonshow ? 'Status' : ""}`,
            render: rowData => (
              <>
                {Accept && <Button onClick={() => UpdateStatus("Accept", rowData.id)} variant="primary">Accept</Button>}
                {packed && <Button onClick={() => UpdateStatus("Packed", rowData.id)} variant="primary">Packed</Button>}
                {ontheway && <Button onClick={() => UpdateStatus("Ontheway", rowData.id)} variant="primary">Ontheway</Button>}
                {arrived && <Button onClick={() => UpdateStatus("Arrived", rowData.id)} variant="primary">Arrived</Button>}
              </>
            )
          },
          {
            title: 'Cancel',
            render: rowData => (
              <Button onClick={() => handleCancelClick(rowData)} variant="danger">Cancel</Button>
            )
          }
        ]}
        onRowClick={(evt, selectedRow) => setSelectedRow(selectedRow.tableData.id)}
        options={{
          rowStyle: rowData => ({
            backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
          }),
          actionsColumnIndex: -1,
        }}
        actions={[
          rowData => ({
            icon: RemoveRedEye,
            tooltip: 'View Order Detail',
            isFreeAction: true,
            onClick: (event, rowData) => {
              props.history.push({
                pathname: '/OrderDetails',
                state: rowData.OrderNo
              });
            }
          }),
        ]}
        options={{
          actionsColumnIndex: 11,
          headerStyle: {
            backgroundColor: '#222',
            color: "white"
          }
        }}
      />

      {/* Modal for cancel confirmation */}
      <Modal show={showCancelModal} onHide={() => setShowCancelModal(false)}>
        {
            statusCancel == "Cancelation_is_pending" ? <>
 <Modal.Header >
              <Modal.Title>Are you sure?</Modal.Title>
            </Modal.Header>
            {/* <Modal.Body>
                <h4>Order Cancelation</h4>
            </Modal.Body> */}
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowCancelModal(false)}>
                Close
              </Button>
              <Button variant="danger" onClick={hanleConfirmCancelOrder}>
                Cancel Confirm
              </Button>
            </Modal.Footer>
            </> :
             <>

            <Modal.Header >
              <Modal.Title>Cancel Order</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div className="col-sm-12" style={{margin:'auto'}}>
                            <div style={{marginBottom:'5px'}} >
                                <input type="radio" name='cancel_Id' onClick={(()=>setreasonCancelOrder('Item Out of Stock or Unavailable'))} />
                            <label style={{marginLeft:'5px'}} >Item Out of Stock or Unavailable</label>
                            </div>
                            <div style={{marginBottom:'5px'}} >
                                <input type="radio" name='cancel_Id' onClick={(()=>setreasonCancelOrder('Listing Error'))}/>
                            <label style={{marginLeft:'5px'}} >Listing Error</label>
                            </div>
                            <div style={{marginBottom:'5px'}} >
                                <input type="radio"  name='cancel_Id' onClick={(()=>setreasonCancelOrder('Shipping Address Issues'))}/>
                            <label style={{marginLeft:'5px'}} >Shipping Address Issues</label>
                            </div>
                            <div style={{marginBottom:'5px'}} >
                                <input type="radio"  name='cancel_Id' onClick={(()=>setreasonCancelOrder('Item Damaged or Lost'))}/>
                            <label style={{marginLeft:'5px'}} >Item Damaged or Lost</label>
                            </div>
                            <div style={{marginBottom:'5px'}} >
                                <input type="radio" name='cancel_Id' onClick={(()=>setreasonCancelOrder('Buyer Request'))}/>
                            <label style={{marginLeft:'5px'}} >Buyer Request</label>
                            </div>
                            <div style={{marginBottom:'5px'}} >
                                <input type="radio" name='cancel_Id' onClick={(()=>setreasonCancelOrder('Suspicious Activity'))}/>
                            <label style={{marginLeft:'5px'}} >Suspicious Activity</label>
                            </div>
                            <div style={{marginBottom:'5px'}} >
                                <input type="radio" name='cancel_Id' onClick={(()=>setreasonCancelOrder('Compliance with Site Policies'))} />
                            <label style={{marginLeft:'5px'}} >Compliance with Site Policies</label>
                            </div>
                            <div style={{marginBottom:'5px'}} >
                                <input type="radio" name='cancel_Id' onClick={(()=>setreasonCancelOrder('Shipping Restrictions'))}/>
                            <label style={{marginLeft:'5px'}} >Shipping Restrictions</label>
                            </div>
                            {
    !reasonCancelOrder? 
                            <p style={{color:'red'}}>{reasonerrors}</p> : null
                            }
                        </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowCancelModal(false)}>
                Close
              </Button>
              <Button variant="danger" onClick={handleCancelOrder}>
                Cancel Order
              </Button>
            </Modal.Footer>
            
            </>
        }
        

      </Modal>
    </>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </div>
                                    </div>
                                    {/* <div className="mt-5 text-muted text-center">
                                        Don't have an account? <a href="auth-register.html">Create One</a>
                                    </div> */}
                                </div>
                            </div>
                        </section>
                    </div>
                    {/*  <Footer /> */}
                </div>
            </div>


            {/* Second modal for Add SUBADMIN */}



            <Modal className="btn-close"
                size="lg"
                aria-labelledby="example-modal-sizes-title-lg"
                show={show2}
                onHide={handleClose2}>
                
                <Modal.Header >
                    <Modal.Title>Invoice</Modal.Title>
                    <i onClick={handleClose2} style={{ fontSize: "24px" }} className=" fa-10X fas fa-window-close"></i>
                </Modal.Header>
                <Modal.Body>
                    <div id="elem">
                        <body _c_t_common="1" data-new-gr-c-s-check-loaded="14.1043.0" data-gr-ext-installed="" cz-shortcut-listen="true" _c_t_j1="1">

                            <p style={{ display: 'inline', marginLeft: '15%' }}>Daraz Purchase Summary</p><p style={{ display: 'inline' }}>-<b>{Getallinvoicedata.OrderNo}</b></p><p style={{ display: 'inline', textAlign: 'center', margin: '0 auto', marginLeft: '15%' }}>{Getallinvoicedata.Created}</p>
                            {/* <button id="print" type="button" style={{ paddingLeft: '20px', paddingRight: '20px', paddingTop: '6px', paddingBottom: '6px', fontSize: '18px', marginLeft: '20%', marginBottom: '10px' }} onclick="myfunc()">Print</button> */}
                            <table style={{ width: '100%', margin: '0 auto' }}>
                                <tbody style={{ outline: '1px solid black', padding: '5px' }}>
                                    <tr>
                                        <td rowSpan={2} style={{ width: '40px', border: '1px solid black', textAlign: 'center' }}>
                                            <img style={{ width: '60%', maxWidth: '60%', minHeight: '60%', height: '60%' }} src="./invoice_files/metallic-logo-mockup-blue-wall_1389-1178.jpg" alt="" /></td>
                                        <td style={{ width: '40px', backgroundColor: 'aqua' }} />
                                        <td style={{ width: '40px', backgroundColor: 'pink', paddingRight: '5px' }} />
                                        <td style={{ width: '40px', backgroundColor: 'royalblue', paddingTop: '10px' }} />
                                        <td style={{ width: '40px', backgroundColor: 'pink', paddingBottom: '10px' }} />
                                    </tr>
                                    <tr>
                                        <td rowSpan={1} colSpan={4} style={{ borderBottom: '1px solid black', textAlign: 'center' }}><b>SELLER</b>CENTER</td>
                                    </tr>
                                    <tr style={{ border: '1px solid black' }}>
                                        <td style={{ border: '1px solid black' }}>{Getallinvoicedata.OrderNo}
                                        </td>
                                        <td style={{ bottom: '1px solid black' }}>{Getallinvoicedata.PrdouctName}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <table style={{ width: '100%', margin: '0 auto', marginTop: '10px' }}>
                                <tbody style={{ outline: '1px solid black', padding: '5px' }}>
                                    <tr style={{ border: '1px solid black', outline: '1px solid black' }}>
                                        <td style={{ width: '40px', border: '1px solid black' }}>Purchase Summery Number:</td>
                                        <td style={{ border: '1px solid black' }} colSpan={2}>02</td>
                                        <td style={{ width: '50px', border: '1px solid black' }}>Payment Method:</td>
                                        <td style={{ width: '50px', border: '1px solid black' }}>{Getallinvoicedata.PaymentMethod}</td>
                                    </tr>
                                    <tr style={{ border: '1px solid black', outline: '1px solid black' }}>
                                        <td style={{ width: '40px', border: '1px solid black' }}>Purchase Summery Date:</td>
                                        <td style={{ border: '1px solid black' }} colSpan={2}>{Getallinvoicedata.Created}</td>
                                        <td style={{ width: '50px', border: '1px solid black' }}>CNIC:</td>
                                        <td style={{ width: '50px', border: '1px solid black' }} />
                                    </tr>
                                    <tr style={{ border: '1px solid black' }}>
                                        <td style={{ width: '40px', border: '1px solid black' }}>BILL TO:</td>
                                        <td style={{ border: '1px solid black' }} colSpan={2}>{Getallinvoicedata.Billto}</td>
                                        <td style={{ width: '50px', border: '1px solid black' }}>Delivered TO:</td>
                                        <td style={{ width: '50px', border: '1px solid black' }}>{Getallinvoicedata.Deliverdto}</td>
                                    </tr>
                                    <tr style={{ border: '1px solid black' }}>
                                        <td style={{ padding: '10px', border: '1px solid black' }}>Address:</td>
                                        <td style={{ border: '1px solid black' }} colSpan={2}>{Getallinvoicedata.Address}</td>
                                        <td style={{ width: '50px', border: '1px solid black' }}>Province</td>
                                        <td style={{ width: '50px', border: '1px solid black' }}>{Getallinvoicedata.Province}</td>
                                    </tr>
                                    <tr style={{ border: '1px solid black', outline: '1px solid black' }}>
                                        <td style={{ padding: '10px', border: '1px solid black' }}>PHONE:</td>
                                        <td style={{ border: '1px solid black' }} colSpan={2}>{Getallinvoicedata.CustomerNumber}</td>
                                        <td style={{ width: '50px', border: '1px solid black' }}>PHONE:</td>
                                        <td style={{ width: '50px', border: '1px solid black' }}>{Getallinvoicedata.CustomerNumber}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <table style={{ width: '100%', margin: '0 auto', marginTop: '20px' }}>
                                <tbody style={{ outline: '1px solid black', padding: '5px' }}>
                                    <tr style={{ border: '1px solid black', outline: '1px solid black' }}>
                                        <th style={{ border: '1px solid black' }} colSpan={9}>Your Order Item:</th>
                                    </tr>
                                    <tr style={{ border: '1px solid black' }}>
                                        <th style={{ border: '1px solid black' }}>#</th>
                                        <th style={{ border: '1px solid black' }}>Product Name</th>
                                        <th style={{ border: '1px solid black' }}>Shop SKU</th>
                                        <th style={{ border: '1px solid black' }}>Seller SKU</th>
                                        <th style={{ border: '1px solid black', width: '160px' }}>Size</th>
                                        <th style={{ border: '1px solid black' }}>Paid Price</th>
                                        <th style={{ border: '1px solid black' }}>Price</th>
                                        <th style={{ border: '1px solid black' }}>Item Total</th>
                                    </tr>
                                    <tr style={{ border: '1px solid black', outline: '1px solid black' }}>
                                        <td style={{ border: '1px solid black' }}>1</td>
                                        <td style={{ border: '1px solid black' }}>{Getallinvoicedata.PrdouctName}</td>
                                        <td style={{ border: '1px solid black' }}>212954826_PK-1421272348</td>
                                        <td style={{ border: '1px solid black' }}>{Getallinvoicedata.PrdouctName}</td>
                                        <td style={{ border: '1px solid black', width: '160px' }}>Color<br />Family-Multicolor</td>
                                        <td style={{ border: '1px solid black' }}>59.00</td>
                                        <td style={{ border: '1px solid black' }}>59.00</td>
                                        <td style={{ border: '1px solid black' }}>{Getallinvoicedata.Total}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <table style={{ width: '55%', marginTop: '20px', textAlign: 'left', marginLeft: '45%' }}>
                                <tbody style={{ border: '1px solid black' }}>
                                    <tr style={{ border: '1px solid black' }}>
                                        <td style={{ border: '1px solid black' }}>Subtotal:</td>
                                        <td style={{ border: '1px solid black' }}>71.00</td>
                                    </tr>
                                    <tr style={{ border: '1px solid black' }}>
                                        <td style={{ border: '1px solid black' }}>Shipping Cost:</td>
                                        <td style={{ border: '1px solid black' }}>12.00</td>
                                    </tr>
                                    <tr style={{ border: '1px solid black' }}>
                                        <td style={{ border: '1px solid black' }}>voucher:</td>
                                        <td style={{ border: '1px solid black' }}>0</td>
                                    </tr>
                                    <tr style={{ border: '1px solid black' }}>
                                        <th style={{ border: '1px solid black', alignItems: 'start' }}>Total</th>
                                        <th style={{ border: '1px solid black' }}>{Getallinvoicedata.Total}</th>
                                    </tr>
                                </tbody>
                            </table>


                            <div id="pt-ext-root"></div>
                        </body>
                    </div>




                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => myfunc()} >
                        Print
                    </Button>
                    <Button variant="primary" onClick={() => handleClose2()}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}