/* eslint-disable */
import React, { useState, useEffect } from "react"
import AdminHeader from "../components/adminheader/AdminHeader"
import AdminFooter from "../components/adminfooter/AdminFooter"
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import image_url from "../../../../config/BaseUrl"
import Swal from 'sweetalert2'
import BaseUrl from "../../../../config/BaseUrl"



import { getApiWithToken, PutApi, PostApi, DeleteApi } from "../../../../config/Api/FakeuserApi";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    input: {
        display: "none",
    },
}));

export default function AdminProfile() {

    // STATES FOR SHOW DATA AND UPDATE DATA
    const classes = useStyles();
    const [fname, setFname] = useState("")
    const [lname, setLname] = useState("")
    const [email, setEmail] = useState("")
    const [contact, setContact] = useState("")
    const [profileimage, setprofileimage] = useState(null)
    const [passsword, setPassword] = useState('nochange')
    const [Loader, setLoader] = useState(false)
    const [Role, setRole] = useState("")


    //    FOR PIC UPLOAD
    const [UpdateThumbnail, setUpdateThumbnail] = useState(null)
    const [Selectedprofile, setSelectedprofile] = useState("")


    // update validation
    const [EditfnamekErr, SetEditfnamekErr] = useState({})
    const [EditlnameErr, SetEditlnameErr] = useState({})
    const [EditcontactErr, SetEditcontactErr] = useState({})
    const [EditimgErr, SetEditimgErr] = useState({})
    const [EditemailErr, SetEditemailErr] = useState({})



    useEffect(() => {
        GetProfileData()



    }, [])


    const Token = localStorage.getItem('Token');

    // FOR PROFILE UPLOAD CODE
    const handleInputFileChange = (event, func) => {
        var file = event.target.files[0];
        var reader = new FileReader();
        // console.log(file);
        const filesize = file.size
        const filesizeround = Math.round((filesize / 1024))
        if (filesizeround > 1024) {
            // setupdateProfileImage(null)
            // setselectProfileImage(null)
            Swal.fire({
                title: "Opps",
                text: "plz select your image less then and equals to 1mb",
                icon: "warning"
            });
        } else {
            var url = reader.readAsDataURL(file);

            reader.onloadend = function (e) {
                setSelectedprofile(reader.result);
                setUpdateThumbnail(file);
            };

            func(event.target.files[0])

        }
    }


    // Update profile

    const UpdateProfile = () => {
        const isValid = UpdateformValidation();
        if (isValid == true) {
            setLoader(true);
            var formdata = new FormData();
            formdata.append("fname", fname);
            formdata.append("lname", lname);
            formdata.append("contact", contact);
            formdata.append("profleimage", profileimage);

            // console.log("formdata",formdata)


            PutApi(`${BaseUrl.Admin_Url}/superadminlogin`, formdata, Token)
                .then(({ data }) => {
                    setLoader(false);
                    if (data.status) {
                        GetProfileData()
                        // console.log(GetProfileData(),"GetProfileData")
                        // handleClose()
                        console.log("dataaPut==>", data)


                        Swal.fire({
                            title: "success",
                            text: data.message,
                            icon: "success"
                        })
                    } else {
                        // $(".loader").hide();
                        Swal.fire({
                            title: "Opps",
                            text: data.message,
                            icon: "error"
                        })
                    }
                }).catch(function (e) {
                    // $(".loader").hide();
                    Swal.fire({
                        title: "Opps",
                        text: e.message,
                        icon: "error"
                    })
                })
        }
        else {
            UpdateformValidation()


        }
    }



    //updateapi validation




    const UpdateformValidation = () => {
        const EditfnamekErr = {};
        const EditlnameErr = {};
        const EditcontactErr = {};
        const EditimgErr = {};
        const EditemailErr = {};






        let isValid = true;

        if (fname.length == 0) {
            EditfnamekErr.firstNamenull = "Required First Name";
            isValid = false;
        }
        if (lname.length == 0) {
            EditlnameErr.lastNamenull = "Required Last Name";
            isValid = false;
        }
        if (contact.length == 0) {
            EditcontactErr.emailnull = "Required Contact";
            isValid = false;
        }

        if (profileimage == null) {
            EditimgErr.emailnull = "Required Image";
            isValid = false;
        }
        if (email.length == null) {
            EditemailErr.emailnull = "Required email";
            isValid = false;
        }





        SetEditfnamekErr(EditfnamekErr);
        SetEditlnameErr(EditlnameErr);
        SetEditcontactErr(EditcontactErr);
        SetEditimgErr(EditimgErr);
        SetEditemailErr(EditemailErr);




        return isValid;
    }

    // GET PROFILE DATA API

    const GetProfileData = () => {
        setLoader(true);

        getApiWithToken(`${BaseUrl.Admin_Url}/superadminlogin`, "", Token)
            .then(({ data }) => {
                setLoader(false);
                if (data.status) {
                    // console.log("dataadminprofile",data)
                    setFname(data.data.fname)
                    setLname(data.data.lname)
                    setEmail(data.data.email)
                    setPassword(data.data.passsword)
                    setContact(data.data.contactNo)
                    setprofileimage(data.data.profile)
                    setRole(data.data.role)


                } else {
                    // $(".loader").hide();
                    Swal.fire({
                        title: "Opps",
                        text: data.message,
                        icon: "error"
                    })
                }
            }).catch(function (e) {
                // $(".loader").hide();
                Swal.fire({
                    title: "Opps",
                    text: e.message,
                    icon: "error"
                })
            })

    }


    return (
        <>
            {Loader ? <div class="loader"></div> : null}
            <div id="app">
                <AdminHeader />
                <div class="main-wrapper main-wrapper-1">
                    <div className="main-content">
                        <section className="section">
                            <ul className="breadcrumb breadcrumb-style ">
                                <li className="breadcrumb-item">
                                    <h4 className="page-title m-b-0">Profile</h4>
                                </li>

                                <li className="breadcrumb-item active">Profile</li>
                            </ul>

                            <form>
                                <div className="form-row text-center " style={{ display: "block" }}>


                                    <div className="row">
                                        <div className="col-md-12 ">
                                            <center>
                                                <div className="upload_photo_main">
                                                    <input
                                                        onChange={(e) => handleInputFileChange(e, setprofileimage)}
                                                        accept="image/*"
                                                        className={classes.input}
                                                        id="icon-button-file"
                                                        type="file"

                                                    />
                                                    <label htmlFor="icon-button-file">
                                                        <IconButton
                                                            color="#414141"
                                                            aria-label="upload picture"
                                                            component="span"
                                                            className="upload_photo_main"
                                                        >
                                                            <img src={Selectedprofile ? Selectedprofile : `${(profileimage)}`} style={{ minWidth: "120px", maxWidth: "120px", borderRadius: "20px" }} className="upload_photo_main" />
                                                        </IconButton>

                                                    </label>
                                                    <div>
                                                        {Object.keys(EditimgErr).map((key) => {
                                                            return <div style={{ color: "red" }}>{EditimgErr[key]}</div>
                                                        })}
                                                    </div>

                                                </div>

                                            </center>
                                        </div>
                                    </div>

                                    {/* <div className="author-box-center">
                                            <img alt="image" src="assets/img/users/user-1.png" width="100px"   className="rounded-circle author-box-picture img-fluid" />
                                            <div className="clearfix" />


                                        </div> */}
                                </div>
                                <div className="form-row mt-4">
                                    <div className="form-group col-md-6">
                                        <label htmlFor="inputFN">First Name</label>
                                        <input value={fname} onChange={e => setFname(e.target.value)} type="text" className="form-control" id="inputFN" placeholder="Enter Your First Name" />
                                        <div>
                                            {Object.keys(EditfnamekErr).map((key) => {
                                                return <div style={{ color: "red" }}>{EditfnamekErr[key]}</div>
                                            })}
                                        </div>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="inputLN">Last Name</label>
                                        <input value={lname} onChange={e => setLname(e.target.value)} type="text" className="form-control" id="inputLN" placeholder="Enter Your First Name" />
                                        <div>
                                            {Object.keys(EditlnameErr).map((key) => {
                                                return <div style={{ color: "red" }}>{EditlnameErr[key]}</div>
                                            })}
                                        </div>
                                    </div>
                                </div>

                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label htmlFor="inputEmail">Email</label>
                                        <input value={email} disabled type="email" className="form-control" id="inputEmail" placeholder="Email " />
                                        <div>
                                            {Object.keys(EditemailErr).map((key) => {
                                                return <div style={{ color: "red" }}>{EditemailErr[key]}</div>
                                            })}
                                        </div>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="inputPS">Password</label>
                                        <input value={passsword} onChange={e => setPassword(e.target.value)} disabled type="password" className="form-control" id="inputPS" placeholder="" />

                                    </div>

                                </div>
                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label htmlFor="inputCN">Contact Number</label>
                                        <input value={contact} onChange={e => setContact(e.target.value)} type="number" min="0" max="11111111111111111" className="form-control" id="inputCN" placeholder="Contact Number " />
                                        <div>
                                            {Object.keys(EditcontactErr).map((key) => {
                                                return <div style={{ color: "red" }}>{EditcontactErr[key]}</div>
                                            })}
                                        </div>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="inputRI">Role </label>
                                        <input disabled value={Role} onChange={e => setRole(e.target.value)} type="text" className="form-control" id="inputRI" placeholder="Enter Role" />
                                    </div>

                                </div>

                                <div className="form-row">



                                </div>
                                <div class="row justify-content-center">
                                    <button type="button" class="btn btn-primary btn-lg" onClick={() => UpdateProfile()}>Update</button>
                                </div>


                            </form>

                        </section>

                    </div>
                    <AdminFooter />
                </div>
            </div>


        </>
    )
}