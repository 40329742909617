/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/img-redundant-alt */
import { useEffect, useState } from "react";
import Footer from "../../../components/footer/footer"
import axios from 'axios';
import Slider from 'react-slick';
import BaseUrl from "../../../../config/BaseUrl";
import Swal from "sweetalert2";
import Header from "../../../components/header/header";
const WantedList = () => {

  const [products, setProducts] = useState(null);
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const [ID, setID] = useState();

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [index]: {
        ...formData[index],
        [name]: value,
      }
    });
  };

  const handleSubmit = (index, event) => {
    event.preventDefault();

    const form = formData[index] || {};
    const { price, product_description, desired_price_including_shipping, is_price_negotiable } = form;

    // Validate form fields
    if (!price || !product_description || !desired_price_including_shipping || !is_price_negotiable) {
      setErrors({
        ...errors,
        [index]: "Please fill and submit",
      });
      return;
    }

    setErrors({
      ...errors,
      [index]: "",
    });

    // Add is_available field with default value true
    const formWithAvailability = {
      price:price,
      desired_price_including_shipping:desired_price_including_shipping == "yes" ? true : false,
      product_description:product_description,
      is_price_negotiable:is_price_negotiable,
      is_available: true,
      id: ID
    };

    const token = localStorage.getItem('SellerToken');

    // Submit the form data
    axios.put(`${BaseUrl.base_url}/WantedlistProduct`, formWithAvailability, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        console.log("Product data submitted successfully:", response.data);
        // Clear the form data after submission
        Swal.fire({
          text: "Offer send successfully",
          icon: "success"
        });
        GetData()
        setFormData({
          ...formData,
          [index]: {
            price: '',
            product_description: ''
          }
        });
      })
      .catch(error => {
        Swal.fire({
                    title: "Opps",
                    text: error.message,
                    icon: "error",
                  });
      });
  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const GetData = () => {


    const token = localStorage.getItem('SellerToken');

    axios.get(`${BaseUrl.base_url}/WantedlistProduct`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        setProducts(response.data);
        setLoading(false);
      })
      .catch(error => {
        console.error("There was an error fetching the product data!", error);
        setLoading(false);
      });
  }
  useEffect(() => {
    GetData()
  }, []);

  if (!products) {
    return (
      <div style={{ width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

        <img src={'./assets/img/loader.svg'} alt="loader" />

      </div>
    )

  }


  return (
    <>
      <Header />

      {products.length === 0 ? <>

        <h4 style={{
          minHeight: '224px',
          paddingLeft: '280px',
          paddingRight: '30px',
          paddingTop: '94px',
          width: '100%',
          position: 'relative'
        }}>
          Wanted List</h4>

        <h4 style={{
          minHeight: '224px',
          paddingLeft: '280px',
          paddingRight: '30px',
          paddingTop: '94px',
          width: '100%',
          position: 'relative'
        }}>No Wanted List Product Available.</h4>
      </> :
        <div id="app">
          <h4 style={{
            paddingLeft: '280px',
            paddingRight: '30px',
            paddingBottom: '24px',
            paddingTop: '94px',

            width: '100%',
            position: 'relative'
          }}>Wanted List</h4>

          <div className="main-wrapper main-wrapper-1">
            <div className="" style={{
              minHeight: '224px',
              paddingLeft: '280px',
              paddingRight: '30px',
              // paddingTop: '94px',
              width: '100%',
              position: 'relative'
            }}>
              <section className="section">
                <div id="form" className="row">
                  <div className="col-md-12">

                    <div className="card card-primary">

                      <div className="card-body">
                        {products.map((product, index) => (
                          <div key={index} className="row product-card mb-4">
                            <div className="col-md-6" style={{ margin: 'auto' }}>
                              <Slider {...sliderSettings}>
                                {product.images.map((image, imgIndex) => (
                                  <div key={imgIndex}>
                                    <img style={{ margin: 'auto' }} src={image.image} alt={`Product image`} className="img-fluid" />
                                  </div>
                                ))}
                              </Slider>
                            </div>
                            <div className="col-md-6">
                              <p><strong>Product Name:</strong> {product.Product_Name}</p>
                              <p><strong>Condition:</strong> {product.product_condition}</p>
                              <p><strong>Description:</strong> {product.long_Description}</p>
                              <form onSubmit={(e) => handleSubmit(index, e)}>
                                <label htmlFor={`price-${index}`} style={{ fontWeight: 'bold' }}>Price</label>
                                <div>
                                  <input
                                    type="text"
                                    name="price"
                                    style={{ width: '100%', padding: '5px', border: '1px solid #d72329' }}
                                    placeholder='Enter Product Price'
                                    value={formData[index]?.price || ''}
                                    onChange={(e) => handleInputChange(index, e)}
                                  />
                                </div>
                                <label htmlFor={`desired_price_including_shipping-${index}`} style={{ fontWeight: 'bold', marginTop: '10px', borderRadius: "5px" }}>Desired price including shipping?</label>
                                <select
                                  name="desired_price_including_shipping"
                                  style={{ width: '100%', padding: '5px', border: '1px solid #d72329' }}
                                  placeholder='seletc desired_price_including_shipping'
                                  value={formData[index]?.desired_price_including_shipping || ''}
                                  onChange={(e) => handleInputChange(index, e)}
                                >

                                  <option value="">Select desired price including shipping?</option>
                                  <option value="yes">Yes</option>
                                  <option value="no">No</option>
                                </select>
                                <label htmlFor={`is_price_negotiable-${index}`} style={{ fontWeight: 'bold', marginTop: '10px', borderRadius: "5px" }}>Is price negotiable?</label>
                                <select
                                  name="is_price_negotiable"
                                  style={{ width: '100%', padding: '5px', border: '1px solid #d72329' }}
                                  value={formData[index]?.is_price_negotiable || ''}
                                  onChange={(e) => handleInputChange(index, e)}
                                >
                                  <option value="">Select is price negotiable?</option>
                                  <option value="yes">Yes</option>
                                  <option value="no">No</option>
                                </select>
                                <label htmlFor={`product_description-${index}`} style={{ fontWeight: 'bold', marginTop: '10px', borderRadius: "5px" }}>Product Description</label>
                                <div>
                                  <textarea
                                    name="product_description"
                                    style={{ padding: '5px', width: '100%', border: '1px solid #d72329', borderRadius: "5px" }}
                                    placeholder='Enter description'
                                    value={formData[index]?.product_description || ''}
                                    onChange={(e) => handleInputChange(index, e)}
                                  />
                                </div>

                                {errors[index] && (
                                  <p style={{ color: 'red' }}>{errors[index]}</p>
                                )}

                                <button
                                  onClick={() => setID(product.id)}
                                  type="submit"
                                  style={{ border: '1px solid #d72329', backgroundColor: 'transparent', color: '#d72329', padding: '10px', width: '100%', margin: 'auto', borderRadius: '5px' }}
                                >
                                  Product Available
                                </button>
                              </form>
                            </div>
                          </div>
                        ))}
                      </div>

                    </div>
                  </div>
                </div>
              </section>
            </div>
            {/* <Footer /> */}
          </div>
        </div>
      }
    </>
  )
}

export default WantedList;