/* eslint-disable */
import React from "react"
import AdminHeader from "../components/adminheader/AdminHeader"
import AdminFooter from "../components/adminfooter/AdminFooter"
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    input: {
        display: "none",
    },
}));

export default function AddRole() {
    const classes = useStyles();
    return (
        <>
            {/* <div class="loader"></div> */}
            <div id="app">
                <AdminHeader />
                <div class="main-wrapper main-wrapper-1">
                    <div className="main-content">
                        <section className="section">
                            <ul className="breadcrumb breadcrumb-style ">
                                <li className="breadcrumb-item">
                                    <h4 className="page-title m-b-0">  Subadmin</h4>
                                </li>

                                <li className="breadcrumb-item active">Add Role</li>
                            </ul>



                            <form>
                                <div className="form-row text-center " style={{ display: "block" }}>


                                    <div className="row">
                                        <div className="col-md-12 ">
                                            <center>
                                                <div className="upload_photo_main">
                                                    <input
                                                        accept="image/*"
                                                        className={classes.input}
                                                        id="icon-button-file"
                                                        type="file"

                                                    />
                                                    <label htmlFor="icon-button-file">
                                                        <IconButton
                                                            color="#414141"
                                                            aria-label="upload picture"
                                                            component="span"
                                                            className="upload_photo_main"
                                                        >
                                                            <img style={{ minWidth: "120px", maxWidth: "120px", borderRadius: "20px" }} src="assets/img/users/user-1.png" className="upload_photo_main" />
                                                        </IconButton>
                                                    </label>
                                                </div>
                                            </center>
                                        </div>
                                    </div>

                                    {/* <div className="author-box-center">
                                            <img alt="image" src="assets/img/users/user-1.png" width="100px"   className="rounded-circle author-box-picture img-fluid" />
                                            <div className="clearfix" />


                                        </div> */}



                                </div>
                                <div className="form-row mt-4">
                                    <div className="form-group col-md-12">
                                        <label htmlFor="inputAR">Add Role</label>
                                        <input type="text" className="form-control" id="inputAR" placeholder=" Assign User Role" />
                                    </div>

                                </div>



                                <div class="row justify-content-center">
                                    <button type="submit" class="btn btn-primary btn-lg ">Add</button>
                                </div>

                                {/* <button type="submit" className="btn btn-primary btn-lg justify-content-center">Sign in</button> */}
                            </form>

                        </section>
                        <div className="settingSidebar">
                            <a href="javascript:void(0)" className="settingPanelToggle"> <i className="fa fa-spin fa-cog" />
                            </a>
                            <div className="settingSidebar-body ps-container ps-theme-default">
                                <div className=" fade show active">
                                    <div className="setting-panel-header">Setting Panel
                                    </div>
                                    <div className="p-15 border-bottom">
                                        <h6 className="font-medium m-b-10">Select Layout</h6>
                                        <div className="selectgroup layout-color w-50">
                                            <label className="selectgroup-item">
                                                <input type="radio" name="value" defaultValue={1} className="selectgroup-input-radio select-layout" defaultChecked />
                                                <span className="selectgroup-button">Light</span>
                                            </label>
                                            <label className="selectgroup-item">
                                                <input type="radio" name="value" defaultValue={2} className="selectgroup-input-radio select-layout" />
                                                <span className="selectgroup-button">Dark</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="p-15 border-bottom">
                                        <h6 className="font-medium m-b-10">Sidebar Color</h6>
                                        <div className="selectgroup selectgroup-pills sidebar-color">
                                            <label className="selectgroup-item">
                                                <input type="radio" name="icon-input" defaultValue={1} className="selectgroup-input select-sidebar" />
                                                <span className="selectgroup-button selectgroup-button-icon" data-toggle="tooltip" data-original-title="Light Sidebar"><i className="fas fa-sun" /></span>
                                            </label>
                                            <label className="selectgroup-item">
                                                <input type="radio" name="icon-input" defaultValue={2} className="selectgroup-input select-sidebar" defaultChecked />
                                                <span className="selectgroup-button selectgroup-button-icon" data-toggle="tooltip" data-original-title="Dark Sidebar"><i className="fas fa-moon" /></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="p-15 border-bottom">
                                        <h6 className="font-medium m-b-10">Color Theme</h6>
                                        <div className="theme-setting-options">
                                            <ul className="choose-theme list-unstyled mb-0">
                                                <li title="white" className="active">
                                                    <div className="white" />
                                                </li>
                                                <li title="cyan">
                                                    <div className="cyan" />
                                                </li>
                                                <li title="black">
                                                    <div className="black" />
                                                </li>
                                                <li title="purple">
                                                    <div className="purple" />
                                                </li>
                                                <li title="orange">
                                                    <div className="orange" />
                                                </li>
                                                <li title="green">
                                                    <div className="green" />
                                                </li>
                                                <li title="red">
                                                    <div className="red" />
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="p-15 border-bottom">
                                        <div className="theme-setting-options">
                                            <label className="m-b-0">
                                                <input type="checkbox" name="custom-switch-checkbox" className="custom-switch-input" id="mini_sidebar_setting" />
                                                <span className="custom-switch-indicator" />
                                                <span className="control-label p-l-10">Mini Sidebar</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="p-15 border-bottom">
                                        <div className="theme-setting-options">
                                            <label className="m-b-0">
                                                <input type="checkbox" name="custom-switch-checkbox" className="custom-switch-input" id="sticky_header_setting" />
                                                <span className="custom-switch-indicator" />
                                                <span className="control-label p-l-10">Sticky Header</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="mt-4 mb-4 p-3 align-center rt-sidebar-last-ele">
                                        <a href="#" className="btn btn-icon icon-left btn-primary btn-restore-theme">
                                            <i className="fas fa-undo" /> Restore Default
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                    <AdminFooter />
            </div>


        </>
    )
}