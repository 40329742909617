 /* eslint-disable */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
// import { useHistory } from "react-router"
import BaseUrl from "../../config/BaseUrl"
import { postApi } from "../../config/Api/FakeuserApi";
import { connect, useDispatch } from "react-redux";
import { checkEmailorPhone, setValue } from "../../config/Redux/Actions/Index"
import Swal from 'sweetalert2'
import background from "../auth/background.png"


const SignUp = (props) => {


    const history = useHistory();
    const [Loader, setLoader] = useState(false)
    const [name, setName] = useState('');
    const dispatch = useDispatch()
    const [email, setEmail] = useState('');

    const [password, setPassword] = useState('');
    const [renterpassword, setRenterpassword] = useState('');

    const [nameErr, setNameErr] = useState({});
    const [emailErr, setEmailErr] = useState({});
    const [passwordErr, setPasswordErr] = useState({});
    const [renterpasswordErr, setRenterpasswordErr] = useState({});
    // console.log("emailverificationcode", props.data.EmailVarificationCode)


    const onSubmit = (e) => {

        const isValid = formValidation();
        if (isValid == true) {
            setLoader(true)
            // e.preventDefault();
            const formdata = new FormData()
            formdata.append("Email", email)
            props.checkEmailorPhone(formdata)
            .then(({ status }) => {
                setLoader(false);
                if (status) {
                    props.setValue("email", email)
                    props.setValue("name", name)
                    props.setValue("renterpassword", renterpassword)



                  //   this.props.navigation.navigate("EnterName")
                  history.push("./varify")
                  // alert("your emailverificationcode is"+ props.data.EmailVarificationCode )

                }
              }).catch(function(e) {
                  setLoader(false)
                Swal.fire({
                    title: "Email Already Exist",
                    text: e.message,
                    icon: "error"
                })
              })

        }else{
            formValidation()
        }
    }

    const formValidation = () => {
        const nameErr = {};
        const emailErr = {};
        const passwordErr = {};
        const renterpasswordErr = {};

        const validPassword = new RegExp('^(?=.*?[A-Za-z])(?=.*?[0-9]).{6,}$');

        let isValid = true;

        if (name == 0) {
            nameErr.nameNull = "You name is required";
            isValid = false;
        }
        if (email == 0) {
            emailErr.emailNull = "Email is required";
            isValid = false;
        }
        if (!validPassword.test(password)) {
            passwordErr.passwordNull = "Enter Strong Password";
            isValid = false;
        }
        if (password.length == 0) {
            passwordErr.passwordNull = "Password is required";
            isValid = false;
        } else if (!validPassword.test(renterpassword)) {
            renterpasswordErr.nameNull = "Enter Strong Password";
            isValid = false;
        }
        if (renterpassword.length == 0) {
            renterpasswordErr.nameNull = "Re-enter password is required";
            // if (renterpassword.trim() == password.trim()) {
            //     renterpasswordErr.passNotmatched = "Password is not matched";
            // }
            isValid = false;
        }
        if (password != renterpassword) {
            renterpasswordErr.nameNull = "Password not match!";
            isValid = false;
        }

        setNameErr(nameErr);
        setEmailErr(emailErr);
        setPasswordErr(passwordErr);
        setRenterpasswordErr(renterpasswordErr);
        // console.log(props)

        return isValid;
    }

    return (
        <>
            {Loader ? <div className="loader" /> : null}
            <div id="app" style={{backgroundImage: `url(${background})`, marginTop: '-50px', backgroundSize: 'cover', height: '115vh' }}>
                <section className="section">
                    <div className="container mt-5">
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <img style={{ width: "20%" }} alt="" src="./assets/img/fav.png"></img>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-8 offset-lg-2 col-xl-4 offset-xl-4">
                                <div className="card card-primary">
                                    <div className="card-header">
                                        <h4>Get Started Selling on Super Friday</h4>
                                    </div>
                                    <div className="card-body">
                                        <form >
                                            <div className="form-group mb-1">
                                                <label htmlFor="na">Your Name</label>
                                                <input value={name} onChange={(e) => { setName(e.target.value) }} id="name" type="text" className="form-control" name="name" tabIndex={1} autofocus />
                                                <div>
                                                    {Object.keys(nameErr).map((key) => {
                                                        return <div style={{ color: "red" }}>{nameErr[key]}</div>
                                                    })}
                                                </div>
                                            </div>
                                            <div className="form-group mb-1">
                                                <label htmlFor="email">Email</label>
                                                <input value={email} onChange={(e) => { setEmail(e.target.value) }} id="email" type="email" className="form-control" name="email" tabIndex={1} autofocus />
                                                <div>
                                                    {Object.keys(emailErr).map((key) => {
                                                        return <div style={{ color: "red" }}>{emailErr[key]}</div>
                                                    })}
                                                </div>
                                            </div>
                                            <div className="form-group mb-1">
                                                <div className="d-block">
                                                    <label htmlFor="password" className="control-label">Password</label>

                                                </div>
                                                <input value={password} onChange={(e) => { setPassword(e.target.value) }} id="password" type="password" className="form-control" name="password" tabIndex={2} />
                                                <div>
                                                    {Object.keys(passwordErr).map((key) => {
                                                        return <div style={{ color: "red" }}>{passwordErr[key]}</div>
                                                    })}
                                                </div>
                                            </div>
                                            <div className="form-group ">
                                                <div className="d-block">
                                                    <label htmlFor="password" className="control-label">Re-enter password</label>
                                                    {/* <div className="float-right">
                                                        <a href="#" className="text-small">
                                                            Forgot Password?
                                                        </a>
                                                    </div> */}
                                                </div>
                                                <input value={renterpassword} onChange={(e) => { setRenterpassword(e.target.value) }} id="password" type="password" className="form-control" name="password" tabIndex={2} />
                                                <div>
                                                    {Object.keys(renterpasswordErr).map((key) => {
                                                        return <div style={{ color: "red" }}>{renterpasswordErr[key]}</div>
                                                    })}
                                                </div>
                                            </div>
                                            {/* <div className="form-group">
                                                <div className="custom-control custom-checkbox">
                                                    <input type="checkbox" name="remember" className="custom-control-input" tabIndex={3} id="remember-me" />
                                                    <label className="custom-control-label" htmlFor="remember-me">Remember Me</label>
                                                </div>
                                            </div> */}
                                            <div className="form-group">
                                                <button type="button" onClick={()=>onSubmit()} className="btn btn-primary btn-md btn-block">
                                                    NEXT
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="mb-4 text-muted text-center">
                                        Already Registered? <a className='customPointer' onClick={() => history.push('/SellerLogin')}>Login</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}
const mapStateToProps = (state) => ({
    otp: state.otp,
    // Name: state.Name,
    // password: state.password

})

const mapDispatchToProps = {
    checkEmailorPhone,
    setValue
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUp)