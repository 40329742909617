/* eslint-disable */
import React, { useState, useEffect } from "react";
import axios from "axios";
import SuperAdminHeader from "../components/SAheader/Header";
import SuperAdminFooter from "../components/SAfooter/Footer";
import MaterialTable from "material-table";
import Swal from "sweetalert2";
import BaseUrl from "../../../../config/BaseUrl";

export default function Users() {
    const [selectedRow, setSelectedRow] = useState(null);

    // States
    const [GetAccounts, SetGetAccounts] = useState([]);
    const [Loader, setLoader] = useState(false);
    const [AllPackages, SetAllPackages] = useState([]);

    const Token = localStorage.getItem("SuperToken");

    useEffect(() => {
        ViewAllAdminAccounts();
        GetAdminPackages();
    }, []);

    const ViewAllAdminAccounts = () => {
        setLoader(true);

        axios.get(`${BaseUrl.Super_Admin_Url}/GetUser`, {
            headers: { Authorization: `Bearer ${Token}` },
        })
        .then(({ data }) => {
            setLoader(false);
            if (data.status) {
                SetGetAccounts(data.data);
                console.log("Accounts==>", data);
            } else {
                Swal.fire({
                    title: "Oops",
                    text: data.message,
                    icon: "error",
                });
            }
        })
        .catch((e) => {
            setLoader(false);
            Swal.fire({
                title: "Oops",
                text: e.message,
                icon: "error",
            });
        });
    };

    const GetAdminPackages = () => {
        axios.get(`${BaseUrl.Super_Admin_Url}/GetUser`, {
            headers: { Authorization: `Bearer ${Token}` },
        })
        .then(({ data }) => {
            setLoader(false);
            if (data.status) {
                console.log("dataarole==>", data);
                SetAllPackages(data.data);
            } else {
                Swal.fire({
                    title: "Oops",
                    text: data.message,
                    icon: "error",
                });
            }
        })
        .catch((e) => {
            setLoader(false);
            Swal.fire({
                title: "Oops",
                text: e.message,
                icon: "error",
            });
        });
    };

    const handleToggleActive = (rowData) => {
        const newStatus =  rowData.Status == 'Active' ? 'InActive' : 'Active' 

    Swal.fire({
            title: `Are you sure you want to ${newStatus} this user?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "No",
        }).then((result) => {
            if (result.isConfirmed) {
                axios.put(`${BaseUrl.Super_Admin_Url}/UpdateUserStatus/${rowData?.id}/`, 
                    { Status: rowData.Status == 'Active' ? 'InActive' : 'Active'   },
                    { headers: { Authorization: `Bearer ${Token}` } }
                )
                .then(({ data }) => {
                    if (data.status) {
                        Swal.fire({
                            title: "Success",
                            text: `User ${newStatus} successfully!`,
                            icon: "success",
                        });
                        ViewAllAdminAccounts(); // Refresh the user list
                    } else {
                        Swal.fire({
                            title: "Error",
                            text: data.message,
                            icon: "error",
                        });
                    }
                })
                .catch((e) => {
                    Swal.fire({
                        title: "Error",
                        text: e.message,
                        icon: "error",
                    });
                });
            }
        });
    };

    return (
        <>
            {Loader ? <div className="loader"></div> : null}
            <div id="app">
                <SuperAdminHeader />
                <div className="main-wrapper main-wrapper-1">
                    <div className="main-content">
                        <section className="section">
                            <ul className="breadcrumb breadcrumb-style">
                                <li className="">
                                    <h4 className="page-title m-b-0">Users</h4>
                                </li>
                            </ul>

                            <div style={{ maxWidth: "100%" }}>
                                <MaterialTable
                                    data={GetAccounts}
                                    title=""
                                    columns={[
                                        { title: "User Name", field: "Username" },
                                        { title: "First Name", field: "First_name" },
                                        { title: "Last Name", field: "Last_name" },
                                        { title: "Email", field: "Email", type: "string" },
                                        { title: "Status", field: "Status",  },
                                    ]}
                                    onRowClick={(evt, selectedRow) => setSelectedRow(selectedRow.tableData.id)}
                                    options={{
                                        rowStyle: rowData => ({
                                            backgroundColor: selectedRow === rowData.tableData.id ? "#EEE" : "#FFF",
                                        }),
                                        actionsColumnIndex: -1,
                                        maxBodyHeight: 400,
                                        overflowX: "auto",
                                        headerStyle: {
                                            backgroundColor: "#222",
                                            color: "white",
                                        },
                                    }}
                                    actions={[
                                        rowData => ({
                                            icon: "swap_horiz", // You can use any Material-UI icon
                                            tooltip: rowData.isActive ? "Deactivate" : "Activate",
                                            onClick: (event, rowData) => handleToggleActive(rowData),
                                        }),
                                    ]}
                                />
                            </div>
                        </section>
                    </div>
                    <SuperAdminFooter />
                </div>
            </div>
        </>
    );
}
