/* eslint-disable */
import React, { useState, useEffect } from "react"
import { useHistory } from "react-router"
import Country from "../components/countries/countries"
import { checkEmailorPhone, setValue } from "../../config/Redux/Actions/Index";
import { connect } from "react-redux";
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import { PostApiWithOutToken } from "../../config/Api/FakeuserApi";
import Swal from 'sweetalert2'
import BaseUrl from "../../config/BaseUrl"
import { getApiWithToken, PutApi, PostApi, DeleteApi } from "../../config/Api/FakeuserApi";
import background from "../auth/background.png"


const SignupDetails = (props) => {

    const [ShopNames, setShopNames] = useState([]);
    // console.log("ShopNames", ShopNames);
    const [Available, setAvailable] = useState(true);
    const [Loader, setLoader] = useState(false)


    const handleInputChange = (event, func) => {
        // const array1 = [{shopName:"J.A Desire"}, {shopName:"K.A Store"}, "Walls", "Omore", 44];

        const found = ShopNames.find(element => element.Store_Name == event.target.value);
        console.log("found", found);

        if (found?.Store_Name === event.target.value) {
            setAvailable(false);
            func("alreadyexist")
            // Swal.fire({
            //     title: "Opps",
            //     text: "Store Name alredy exist",
            //     icon: "error"
            // })
        } else {
            setAvailable(true);
            func(event.target.value)
        }

    }

    const Token = localStorage.getItem('Token');
    useEffect(() => {
        getShopNames();
    }, [])


    const getShopNames = () => {
        setLoader(true);

        getApiWithToken(`${BaseUrl.base_url}/GetShopName`, "", Token)
            .then(({ data }) => {
                setLoader(false);
                // console.log("data", data);
                const Data = data.data
                if (data.status) {
                    setShopNames(Data)


                } else {
                    // $(".loader").hide();
                    Swal.fire({
                        title: "Opps",
                        text: data.message,
                        icon: "error"
                    })
                }
            }).catch(function (e) {
                // $(".loader").hide();
                Swal.fire({
                    title: "Opps",
                    text: e.message,
                    icon: "error"
                })
            })

    }




    // console.log("PROPS", props.data)
    const history = useHistory();
    const [Step1, setStep1] = useState(true)
    const [Step2, setStep2] = useState(false)
    const [Step3, setStep3] = useState(false)
    const [Step4, setStep4] = useState(false)
    const [Step5, setStep5] = useState(false)
    const [Step6, setStep6] = useState(false)

    // PERSONAL INFO VALIDATION
    const [passportNum, setPassportnum] = useState(null);
    const [addressLine1, setAddressline1] = useState('');
    const [addressLine2, setAddressline2] = useState('');
    const [Business_Address_StreetNumber, setBusiness_Address_StreetNumber] = useState('');
    const [phoneNum, setPhonenum] = useState();
    const [ZipCode, setZipCode] = useState(null);

    const [CountryofCitizenship, SetCountryofCitizenship] = useState("")
    const [CountryofBirth, SetCountryofBirth] = useState("")
    const [ProofofIdentity, SetProofofIdentity] = useState("")
    const [Countryofissue, SetCountryofissue] = useState("")
    const [DateofBirth, SetDateofBirth] = useState("")
    const [City, SetCity] = useState("")



    const [BusinessAddressCountry, SetBusinessAddressCountry] = useState("")

    const [passportNumErr, setPassportnumErr] = useState({});
    const [addressLine1Err, setAddressline1Err] = useState({});
    const [addressLine2Err, setAddressline2Err] = useState({});
    const [streetNoErr, setStreetnoErr] = useState({});
    const [phoneNumErr, setPhonenumErr] = useState({});
    const [ZipcodeErr, setZipcodeErr] = useState({});
    const [DateofBirthErr, setDateofBirthErr] = useState({});

    const [CountryofCitizenshipErr, setCountryofCitizenshipErr] = useState({});
    const [CountryofBirthErr, setCountryofBirthErr] = useState({});
    const [CountryofissueErr, setCountryofissueErr] = useState({});
    const [BusinessAddressCountryErr, setBusinessAddressCountryErr] = useState({});
    const [ProofofIdentityErr, setProofofIdentityErr] = useState({});
    const [CityErr, setCityErr] = useState({});



    // console.log("CountryofCitizenship",CountryofCitizenship)
    // console.log("passportNum",passportNum)



    const onSubmitPerinfo = (e) => {
        e.preventDefault();
        const isValid = formValidationPerinfo();
        if (isValid == true) {
            props.setValue("Country_of_Citizenship", CountryofCitizenship)
            props.setValue("City", City)

            props.setValue("Country_of_Birth", CountryofBirth)
            props.setValue("Proof_of_Identity", ProofofIdentity)
            props.setValue("Country_of_issue", Countryofissue)
            props.setValue("Business_Address_Country", BusinessAddressCountry)
            props.setValue("Passportno", passportNum)
            props.setValue("Business_address1", addressLine1)
            props.setValue("Business_address2", addressLine2)
            props.setValue("Phone_Number", phoneNum)
            props.setValue("Business_Address_Zipcode", ZipCode)
            props.setValue("Business_Address_StreetNumber", Business_Address_StreetNumber)

            props.setValue("DOB", DateofBirth)




            NextStep(2);
        }
    }

    const formValidationPerinfo = () => {
        const passportNumErr = {};
        const addressLine1Err = {};
        const addressLine2Err = {};
        const streetNoErr = {};
        const phoneNumErr = {};
        const ZipcodeErr = {};
        const DateofBirthErr = {};

        const CountryofCitizenshipErr = {}
        const CountryofBirthErr = {}
        const CountryofissueErr = {}
        const BusinessAddressCountryErr = {}
        const ProofofIdentityErr = {}
        const CityErr = {}




        let isValid = true;

        if (passportNum == null) {
            passportNumErr.passportNumnull = "Passport/ID Num is required";
            isValid = false;
        }
        if (addressLine1.trim().length == 0) {
            addressLine1Err.addressLine1Null = "Address required";
            isValid = false;
        }
        if (addressLine2.trim().length == 0) {
            addressLine2Err.addressLine2Null = "Address required";
            isValid = false;
        }
        if (Business_Address_StreetNumber.trim().length == 0) {
            streetNoErr.streetnumNull = "Your Street num is required";
            isValid = false;
        }
        // if (phoneNum == 0) {
        //     phoneNumErr.phoneNumNull = "Phone Number is required";
        //     isValid = false;
        // }
        if (ZipCode == null) {
            ZipcodeErr.phoneNumNull = "Zip Code is required";
            isValid = false;
        }
        if (DateofBirth == 0) {
            DateofBirthErr.phoneNumNull = "Plese Enter Date Of Birth ";
            isValid = false;
        }
        if (CountryofCitizenship == "") {
            CountryofCitizenshipErr.CountryofCitizenshipNull = "Plese select Country of Citizenship ";
            isValid = false;
        }
        if (CountryofBirth == "") {
            CountryofBirthErr.CountryofBirthNull = "Plese select Country of Birth ";
            isValid = false;
        }
        if (Countryofissue == "") {
            CountryofissueErr.CountryofissueNull = "Plese select Country of issue ";
            isValid = false;
        }
        if (BusinessAddressCountry == "") {
            BusinessAddressCountryErr.BusinessAddressCountryNull = "Plese select Business Address Country ";
            isValid = false;
        }
        if (ProofofIdentity == "") {
            ProofofIdentityErr.ProofofIdentityNull = "Plese select Proof of Identity ";
            isValid = false;
        }

        if (City == "") {
            CityErr.ProofcityNull = "Plese select your City ";
            isValid = false;
        }



        setPassportnumErr(passportNumErr);
        setAddressline1Err(addressLine1Err);
        setAddressline2Err(addressLine2Err);
        setStreetnoErr(streetNoErr);
        setPhonenumErr(phoneNumErr);
        setZipcodeErr(ZipcodeErr);
        setDateofBirthErr(DateofBirthErr);

        setCountryofCitizenshipErr(CountryofCitizenshipErr);
        setCountryofBirthErr(CountryofBirthErr);
        setCountryofissueErr(CountryofissueErr);
        setBusinessAddressCountryErr(BusinessAddressCountryErr);
        setProofofIdentityErr(ProofofIdentityErr);
        setCityErr(CityErr)



        return isValid;
    }

    // BILLING INFO VALIDATION
    const [creditCardnum, setCreditcardnum] = useState("");
    const [cardHoldername, setCardholdername] = useState("");
    const [expiry, setExpiry] = useState("");
    const [cvc, setCvc] = useState("");
    const [Billing_Address, setBilling_Address] = useState("");
    const [Billing_Addres2, setBilling_Addres2] = useState("");
    const [Billing_Addres_Country, setBilling_Addres_Country] = useState("");
    const [Billing_Addres_StreetNumber, setBilling_Addres_StreetNumber] = useState("");

    const [Focus, setFocus] = useState("");


    const [creditCardnumErr, setCreditcardnumErr] = useState({});
    const [expiryMonthErr, setExpirymonthErr] = useState({});
    // const [expiryYearErr, setExpiryearErr] = useState({});
    const [cardHoldernameErr, setCardholdernameErr] = useState({});
    const [CvcErr, setCvcErr] = useState({});

    const onSubmitBillinfo = (e) => {
        e.preventDefault();
        const isValid = formValidationBillinfo();
        if (isValid == true) {
            // console.log("isValid working")
            props.setValue("Credit_Card_Number", creditCardnum);
            props.setValue("Card_Holder_Name", cardHoldername);
            props.setValue("Expiration_Year", expiry);
            props.setValue("cvc", cvc);
            props.setValue("Billing_Address", Billing_Address);
            props.setValue("Billing_Addres2", Billing_Addres2);
            props.setValue("Billing_Addres_Country", Billing_Addres_Country);
            props.setValue("Billing_Addres_StreetNumber", Billing_Addres_StreetNumber);

            NextStep(3);
        }
    }

    const formValidationBillinfo = () => {
        const creditCardnumErr = {};
        const expiryMonthErr = {};
        // const expiryYearErr = {};
        const cardHoldernameErr = {};
        const CvcErr = {};

        let isValid = true;

        if (creditCardnum.trim().length < 16) {
            creditCardnumErr.creditCardNumnull = "Please enter the card number which is equal to 16 digits";
            isValid = false;
        }
        if (creditCardnum.trim().length > 16) {
            creditCardnumErr.creditCardNumnull = "Please enter the card number which is equal to 16 digits";
            isValid = false;
        }
        if (creditCardnum.trim().length == 0) {
            creditCardnumErr.creditCardNumnull = "Credit card number is required";
            isValid = false;
        }
        if (expiry == 0) {
            expiryMonthErr.expiryMonthNull = "Enter expiry year date";
            isValid = false;
        }
        // if (expiryYear == 0) {
        //     expiryYearErr.expiryYearNull = "Enter expiry year";
        //     isValid = false;
        // }
        if (cardHoldername.trim().length == 0) {
            cardHoldernameErr.cardHolderNameNull = "Please enter a Card holder name";
            isValid = false;
        }
        if (cvc.trim().length < 3) {
            CvcErr.CvcNull = "Please enter the cvc number which is equal to 3 digits";
            isValid = false;
        }
        if (cvc.trim().length > 3) {
            CvcErr.CvcNull = "Please enter the cvc number which is equal to 3 digits";
            isValid = false;
        }
        if (cvc.trim().length == 0) {
            CvcErr.CvcNull = "Please enter a cvc";
            isValid = false;
        }

        setCreditcardnumErr(creditCardnumErr);
        setExpirymonthErr(expiryMonthErr);
        // setExpiryearErr(expiryYearErr);
        setCardholdernameErr(cardHoldernameErr);
        setCvcErr(CvcErr)

        return isValid;
    }

    // STORE VALIDATION
    const [storeName, setStoreName] = useState("");
    // console.log("storeName", storeName)

    const [UniversalProduct, SetUniversalProduct] = useState("")
    const [Brandowner, SetBrandowner] = useState("")
    const [GovernmentRegistered, SetGovernmentRegistered] = useState("")

    const [storeNameErr, setStoreNameErr] = useState({});
    const [UniversalProductErr, setUniversalProductErr] = useState({});
    const [BrandownerErr, setBrandownerErr] = useState({});
    const [GovernmentRegisteredErr, setGovernmentRegisteredErr] = useState({});

    const onSubmitStore = (e) => {
        e.preventDefault();
        const isValid = formValidationStore();
        if (isValid == true) {


            props.setValue("Store_Name", storeName)
            props.setValue("UniversalProduct", UniversalProduct)

            props.setValue("Brandowner", Brandowner)
            props.setValue("GovernmentRegistered", GovernmentRegistered)
            NextStep(4);

        }

        else {
            formValidationStore();
            // console.log("else is working")
        }
    }

    const formValidationStore = () => {
        const storeNameErr = {};
        const UniversalProductErr = {};
        const BrandownerErr = {};
        const GovernmentRegisteredErr = {};

        let isValid = true;

        if (storeName.trim().length === 0) {
            storeNameErr.storeNamenull = "Please enter your store name";
            isValid = false;
        }
        if (storeName.trim() === "alreadyexist") {
            storeNameErr.storeNamenull = "Please enter another store name";
            isValid = false;
        }
        if (UniversalProduct.trim().length === 0) {
            UniversalProductErr.UniversalProductnull = "Please Select Options";
            isValid = false;
        }
        if (Brandowner.trim().length === 0) {
            BrandownerErr.Brandownernull = "Please Select Options";
            isValid = false;
        }
        if (GovernmentRegistered.trim().length === 0) {
            GovernmentRegisteredErr.GovernmentRegisterednull = "Please Select Options";
            isValid = false;
        }

        setStoreNameErr(storeNameErr);
        setUniversalProductErr(UniversalProductErr);
        setBrandownerErr(BrandownerErr);
        setGovernmentRegisteredErr(GovernmentRegisteredErr);
        return isValid;
    }

    // CONFIRMATION CODE VALIDATION
    const [confirmationCode, setConfirmationcode] = useState("");
    const [confirmationCodeErr, setConfirmationcodeErr] = useState({});
    const [Ctrue, setCtrue] = useState(false);





    console.log(".Business_Address_StreetNumber====", Business_Address_StreetNumber)
    console.log("props.data.Business_Address_StreetNumber====", props.data.Business_Address_StreetNumber)



    const onSubmitconf = (e) => {


        // console.log("finish")
        e.preventDefault();
        const isValid = formValidationConf();
        if (isValid == true) {






            NextStep(6);
        }
    }

    const formValidationConf = () => {
        const confirmationCodeErr = {};

        let isValid = true;

        if (confirmationCode.trim().length === 0) {
            confirmationCodeErr.confirmationCodenull = "Enter Confirmation Code";
            isValid = false;
            setConfirmationcodeErr(confirmationCodeErr);
            setCtrue(false);
            return isValid;
        }



        else if (confirmationCode.length < 4) {
            // console.log("lrnth")

            confirmationCodeErr.confirmationCodenull = "Please enter maximum 4 digits otp code";
            isValid = false;

            setConfirmationcodeErr(confirmationCodeErr);
            setCtrue(false);
            return isValid;

        }

        else if (confirmationCode.length > 4) {


            confirmationCodeErr.confirmationCodenull = "Please enter maximum 4 digits otp code";
            isValid = false;

            setConfirmationcodeErr(confirmationCodeErr);
            setCtrue(false);
            return isValid;

        }

        else if (confirmationCode == props.data.MobileverificationCode) {
            confirmationCodeErr.confirmationCodenull = "success";
            CreateAccount()
            isValid = true;
            setConfirmationcodeErr(confirmationCodeErr);
            setCtrue(true);
            return isValid;





        }
        else {

            confirmationCodeErr.confirmationCodenull = "Please enter correct OTP number";
            isValid = false;
            setConfirmationcodeErr(confirmationCodeErr);
            setCtrue(false);
            return isValid;
        }



    }


    const CreateAccount = () => {
        setLoader(true)
        // console.log("mydata",props.data)
        setLoader(true);
        var formdata = new FormData();


        formdata.append("Business_Location", props.data.Business_Location);
        formdata.append("Business_Type", props.data.Business_Type);
        formdata.append("First_name", props.data.First_name);
        formdata.append("Middle_name", props.data.Middle_name);
        formdata.append("last_name", props.data.last_name);
        formdata.append("Country_of_Citizenship", props.data.Country_of_Citizenship);
        formdata.append("City", props.data.City);

        formdata.append("Country_of_Birth", props.data.Country_of_Birth);
        formdata.append("DOB", props.data.DOB);
        formdata.append("Proof_of_Identity", props.data.Proof_of_Identity);
        formdata.append("Country_of_issue", props.data.Country_of_issue);
        formdata.append("Business_address1", props.data.Business_address1);
        formdata.append("Business_address2", props.data.Business_address2);
        formdata.append("Business_Address_Country", props.data.Business_Address_Country);
        formdata.append("Business_Address_Zipcode", props.data.Business_Address_Zipcode);
        formdata.append("Businessadress_Country_Code", props.data.Businessadress_Country_Code);
        formdata.append("Business_Address_StreetNumber", props.data.Business_Address_StreetNumber);

        formdata.append("Businessadress_Number", props.data.Businessadress_Number);
        formdata.append("north_america_status", "");
        formdata.append("Credit_Card_Number", props.data.Credit_Card_Number);
        formdata.append("Date", props.data.Expiration_Year);
        formdata.append("Card_Holder_Name", props.data.Card_Holder_Name);
        formdata.append("Billing_Address", "sdghg");
        formdata.append("Store_Name", props.data.Store_Name);
        formdata.append("Universal_Products", props.data.UniversalProduct);
        formdata.append("Are_you_Brand_Owner", props.data.Brandowner);
        formdata.append("Do_You_Goverment_registered_trademark", props.data.GovernmentRegistered);
        formdata.append("Email", props.data.email);
        formdata.append("password", props.data.renterpassword);
        formdata.append("Cvc", props.data.cvc);
        formdata.append("MembershipPlansId", props.data.Membershipid);
        formdata.append("Name", props.data.name);
        formdata.append("PassportNumber", props.data.Passportno);

        formdata.append("Billing_Address", props.data.Billing_Address);
        formdata.append("Billing_Addres2", props.data.Billing_Addres2);
        formdata.append("Billing_Addres_Country", props.data.Billing_Addres_Country);
        formdata.append("Billing_Addres_StreetNumber", props.data.Billing_Addres_StreetNumber);



        PostApiWithOutToken(`${BaseUrl.base_url}/CreateSellerAccount`, formdata)
            .then(({ data }) => {
                setLoader(false);
                if (data.status) {
                    // console.log("AddCategory==>", data)
                    history.push("/SellerLogin")

                    Swal.fire({
                        title: "success",
                        text: data.message,
                        icon: "success"
                    })
                } else {
                    // $(".loader").hide();
                    Swal.fire({
                        title: "Opps",
                        text: data.message,
                        icon: "error"
                    })
                }
            }).catch(function (e) {
                // $(".loader").hide();
                Swal.fire({
                    title: "Opps",
                    text: e.message,
                    icon: "error"
                })
            })


    }


    const NextStep = (val) => {
        if (val == 1) {
            setStep1(false)
            setStep2(true)
            setStep3(false)
            setStep4(false)
            setStep5(false)
            setStep6(false)
        } else if (val == 2) {
            setStep1(false)
            setStep2(false)
            setStep3(true)
            setStep4(false)
            setStep5(false)
            setStep6(false)
        } else if (val == 3) {
            setStep1(false)
            setStep2(false)
            setStep3(false)
            setStep4(true)
            setStep5(false)
            setStep6(false)
        } else if (val == 4) {
            setStep1(false)
            setStep2(false)
            setStep3(false)
            setStep4(false)
            setStep5(true)
            setStep6(false)
        } else if (val == 5) {

            setTimeout(() => {
                alert("your emaiverificationcode is" + " " + props.data.MobileverificationCode)

            }, 500);

            setStep1(false)
            setStep2(false)
            setStep3(false)
            setStep4(false)
            setStep5(false)
            setStep6(true)
        } else if (val == 6) {
            setStep1(false)
            setStep2(false)
            setStep3(false)
            setStep4(false)
            setStep5(false)
            setStep6(true)
        }
    }
    const PreviousStep = (val) => {
        if (val == 1) {
            setStep1(true);
            setStep2(false)
            setStep3(false)
            setStep4(false)
            setStep5(false)
            setStep6(false)
        } else if (val == 2) {
            setStep1(true)
            setStep2(false)
            setStep3(false)
            setStep4(false)
            setStep5(false)
            setStep6(false)
        } else if (val == 3) {
            setStep1(false)
            setStep2(true)
            setStep3(false)
            setStep4(false)
            setStep5(false)
            setStep6(false)
        } else if (val == 4) {
            setStep1(false)
            setStep2(false)
            setStep3(true)
            setStep4(false)
            setStep5(false)
            setStep6(false)
        } else if (val == 5) {
            setStep1(false)
            setStep2(false)
            setStep3(false)
            setStep4(true)
            setStep5(false)
            setStep6(false)
        }
        else if (val == 6) {
            setStep1(false)
            setStep2(false)
            setStep3(false)
            setStep4(false)
            setStep5(true)
            setStep6(false)
        }
    }
    const CurruntStep = (val) => {
        if (val == 1) {
            setStep1(true);
            setStep2(false)
            setStep3(false)
            setStep4(false)
            setStep5(false)
            setStep6(false)
        } else if (val == 2) {
            setStep1(false)
            setStep2(true)
            setStep3(false)
            setStep4(false)
            setStep5(false)
            setStep6(false)
        } else if (val == 3) {
            setStep1(false)
            setStep2(false)
            setStep3(true)
            setStep4(false)
            setStep5(false)
            setStep6(false)
        } else if (val == 4) {
            setStep1(false)
            setStep2(false)
            setStep3(false)
            setStep4(true)
            setStep5(false)
            setStep6(false)
        } else if (val == 5) {
            setStep1(false)
            setStep2(false)
            setStep3(false)
            setStep4(false)
            setStep5(true)
            setStep6(false)
        }
        else if (val == 6) {


            setStep1(false)
            setStep2(false)
            setStep3(false)
            setStep4(false)
            setStep5(false)
            setStep6(true)
        }
    }
    return (
        <>
            {Loader ? <div class="loader"></div> : null}
            <div id="app"  style={{backgroundImage: `url(${background})`, marginTop: '-50px', backgroundSize: 'cover', height: '210vh' }}>
                <section className="section">
                    <div className="container-fluid mt-5">
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <img style={{ width: "20%" }} src="./assets/img/fav.png"></img>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card card-primary">
                                    <div className="card-header">
                                        <h4>Sign UP Details</h4>
                                    </div>
                                    <div className="card-body">
                                        {Step1 ?
                                            <>
                                                {/* <h3>Personal Information</h3> */}
                                                <form method="POST" onSubmit={onSubmitPerinfo}>
                                                    <div className="row">
                                                        <div className="col-md-12 mb-2 text-center">
                                                            <button onClick={() => CurruntStep(1)} style={{ width: "16%", height: "50px", fontSize: "15px" }} className="btn btn-primary">Personal Information</button>
                                                            {/* <button disabled onClick={() => CurruntStep(2)} style={{ width: "14%", height: "50px", fontSize: "15px" }} className="btn btn-primary ml-2">Marketplaces</button> */}
                                                            <button disabled onClick={() => CurruntStep(3)} style={{ width: "14%", height: "50px", fontSize: "15px" }} className="btn btn-primary ml-2">Billing</button>
                                                            <button disabled onClick={() => CurruntStep(4)} style={{ width: "14%", height: "50px", fontSize: "15px" }} className="btn btn-primary ml-2">Store</button>
                                                            <button disabled onClick={() => CurruntStep(5)} style={{ width: "14%", height: "50px", fontSize: "15px" }} className="btn btn-primary ml-2">Verification</button>
                                                            <button disabled onClick={() => CurruntStep(6)} style={{ width: "14%", height: "50px", fontSize: "15px" }} className="btn btn-primary ml-2">Confirmation</button>
                                                        </div>
                                                    </div>
                                                    <fieldset style={{ marginInline: "78px", border: "2px solid rgb(212, 212, 212)", padding: "12px", boxShadow: " 0px 0px 20px #cdd3d8" }}>
                                                        <div className="form-group form-float">
                                                            <div className="form-line">
                                                                <label className="form-label"><b>Country of citizenship</b></label>
                                                                <Country
                                                                    value={CountryofCitizenship}
                                                                    onSelect={(country) => {
                                                                        SetCountryofCitizenship(country)
                                                                    }}
                                                                />
                                                                <div>
                                                                    {Object.keys(CountryofCitizenshipErr).map((key) => {
                                                                        return <div style={{ color: "red" }}>{CountryofCitizenshipErr[key]}</div>
                                                                    })}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="form-group form-float">
                                                            <div className="form-line">
                                                                <label className="form-label"><b>City</b></label>
                                                                <input value={City} onChange={(e) => SetCity(e.target.value)} placeholder="Enter your City" type="text" className="form-control" />



                                                                <div>
                                                                    {Object.keys(CityErr).map((key) => {
                                                                        return <div style={{ color: "red" }}>{CityErr[key]}</div>
                                                                    })}
                                                                </div>


                                                            </div>
                                                        </div>
                                                        <div className="form-group form-float">

                                                            <div className="form-line">
                                                                <label className="form-label"><b>Country of birth</b></label>
                                                                <Country
                                                                    value={CountryofBirth}
                                                                    onSelect={(country) => {
                                                                        SetCountryofBirth(country)
                                                                    }}
                                                                />
                                                                <div>
                                                                    {Object.keys(CountryofBirthErr).map((key) => {
                                                                        return <div style={{ color: "red" }}>{CountryofBirthErr[key]}</div>
                                                                    })}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <div className="form-group form-float">
                                                                    <div className="form-line">
                                                                        <label className="form-label"><b>Proof of Identity</b></label>
                                                                        <select value={ProofofIdentity} onChange={(e) => SetProofofIdentity(e.target.value)} className="form-control">
                                                                        <option >Select Proof Of Identity...</option>

                                                                            <option value={"Passport"}>Passport</option>
                                                                            <option value={"IDCard"} >ID Card</option>
                                                                        </select>
                                                                        <div>
                                                                            {Object.keys(ProofofIdentityErr).map((key) => {
                                                                                return <div style={{ color: "red" }}>{ProofofIdentityErr[key]}</div>
                                                                            })}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-6">
                                                                <label className="form-label"> &nbsp;</label>
                                                                <input value={passportNum} onChange={(e) => setPassportnum(e.target.value)} placeholder="Enter your Passport/Id NO" type="number" className="form-control" />
                                                                <div>
                                                                    {Object.keys(passportNumErr).map((key) => {
                                                                        return <div style={{ color: "red" }}>{passportNumErr[key]}</div>
                                                                    })}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6">

                                                                <div className="form-group form-float">
                                                                    <div className="form-line">
                                                                        <label className="form-label"><b>Country of issue</b></label>
                                                                        <Country
                                                                            value={Countryofissue}
                                                                            onSelect={(country) => {
                                                                                SetCountryofissue(country)
                                                                            }}
                                                                        />
                                                                        <div>
                                                                            {Object.keys(CountryofissueErr).map((key) => {
                                                                                return <div style={{ color: "red" }}>{CountryofissueErr[key]}</div>
                                                                            })}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">

                                                                <div className="form-group form-float">
                                                                    <div className="form-line">
                                                                        <label className="form-label"><b>Date Of Birth</b></label>
                                                                        <input value={DateofBirth} onChange={(e) => SetDateofBirth(e.target.value)} onFocus={(e) => setFocus(e.target.name)} placeholder="Enter Date Of Birth" type="date" name="creditCardnum" className="form-control" />
                                                                        <div>
                                                                            {Object.keys(DateofBirthErr).map((key) => {
                                                                                return <div style={{ color: "red" }}>{DateofBirthErr[key]}</div>
                                                                            })}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <hr />
                                                        <div className="form-group form-float">
                                                            <div className="form-line">
                                                                <label className="form-label"><b>Business Address</b></label>
                                                                <div className="alert alert-secondary" role="alert">
                                                                    {/* <h4 className="alert-heading">Well done!</h4> */}
                                                                    {/* <p>Aww yeah, you successfully read this important alert message. This example text is going to run a bit longer so that you can see how spacing within an alert works with this kind of content.</p> */}
                                                                    {/* <hr /> */}
                                                                    <div className="row">
                                                                        <div className="col-lg-1">
                                                                            <i style={{ fontSize: "55px" }} className="fas fa-exclamation-circle"></i>

                                                                        </div>
                                                                        <div className="col-md-11">
                                                                            <p className="mb-0" style={{ color: 'rgb(82, 80, 80)' }}> We will validate this address by sending you a postcard containing a varification code. To avoid any delays please enter your address in English characters only.</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <input value={addressLine1} onChange={(e) => setAddressline1(e.target.value)} placeholder="Address Line 1" type="text" className="form-control" />
                                                                <div>
                                                                    {Object.keys(addressLine1Err).map((key) => {
                                                                        return <div style={{ color: "red" }}>{addressLine1Err[key]}</div>
                                                                    })}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <input value={addressLine2} onChange={(e) => setAddressline2(e.target.value)} placeholder="Address Line 2" type="text" className="form-control" />
                                                                <div>
                                                                    {Object.keys(addressLine2Err).map((key) => {
                                                                        return <div style={{ color: "red" }}>{addressLine2Err[key]}</div>
                                                                    })}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-2">
                                                            <div className="col-md-6">
                                                                <Country
                                                                    value={BusinessAddressCountry}
                                                                    onSelect={(country) => {
                                                                        SetBusinessAddressCountry(country)
                                                                    }}
                                                                />
                                                                <div>
                                                                    {Object.keys(BusinessAddressCountryErr).map((key) => {
                                                                        return <div style={{ color: "red" }}>{BusinessAddressCountryErr[key]}</div>
                                                                    })}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <input value={Business_Address_StreetNumber} onChange={(e) => setBusiness_Address_StreetNumber(e.target.value)} placeholder="Street No" type="text" className="form-control" />
                                                                <div>
                                                                    {Object.keys(streetNoErr).map((key) => {
                                                                        return <div style={{ color: "red" }}>{streetNoErr[key]}</div>
                                                                    })}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <hr />
                                                        <div className="row mt-2">
                                                            {/* <div className="col-md-6">
                                                                <input value={phoneNum} onChange={(e) => setPhonenum(e.target.value)} placeholder="Phone Number" type="number" className="form-control" placeholder="Enter Phone No" />
                                                                <div>
                                                                    {Object.keys(phoneNumErr).map((key) => {
                                                                        return <div style={{ color: "red" }}>{phoneNumErr[key]}</div>
                                                                    })}
                                                                </div>
                                                            </div> */}
                                                            <div className="col-md-6">
                                                                <input value={ZipCode} onChange={(e) => setZipCode(e.target.value)} placeholder="zip code" type="number" className="form-control" placeholder="Enter Zip Code" />
                                                                <div>
                                                                    {Object.keys(ZipcodeErr).map((key) => {
                                                                        return <div style={{ color: "red" }}>{ZipcodeErr[key]}</div>
                                                                    })}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* <intlTelInput/> */}
                                                    </fieldset>
                                                    <div className="row">
                                                        <div className="col-md-12 pt-3 text-center">
                                                            <hr />
                                                            {/* <button className="btn btn-primary col-md-3 mx-2" onClick={() => PreviousStep(1)}>Previous</button> */}
                                                            <button className="btn btn-primary col-md-3 mx-2" type="submit">Next</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </>
                                            : null
                                        }
                                        {/* {Step2 ? <>
                                            <div className="row">
                                                <div className="col-md-12 mb-2 text-center">
                                                    <button onClick={() => CurruntStep(1)} style={{ width: "14%", height: "50px", fontSize: "15px" }} className="btn btn-primary">Personal Information</button>
                                                    <button onClick={() => CurruntStep(2)} style={{ width: "14%", height: "50px", fontSize: "15px" }} className="btn btn-primary ml-2">Marketplaces</button>
                                                    <button disabled onClick={() => CurruntStep(3)} style={{ width: "14%", height: "50px", fontSize: "15px" }} className="btn btn-primary ml-2">Billing</button>
                                                    <button disabled onClick={() => CurruntStep(4)} style={{ width: "14%", height: "50px", fontSize: "15px" }} className="btn btn-primary ml-2">Store</button>
                                                    <button disabled onClick={() => CurruntStep(5)} style={{ width: "14%", height: "50px", fontSize: "15px" }} className="btn btn-primary ml-2">Verification</button>
                                                    <button disabled onClick={() => CurruntStep(6)} style={{ width: "14%", height: "50px", fontSize: "15px" }} className="btn btn-primary ml-2">Confirmation</button>
                                                </div>
                                            </div>
                                            <fieldset style={{ marginInline: "78px", border: "2px solid rgb(212, 212, 212)", padding: "12px", boxShadow: " 0px 0px 20px #cdd3d8" }}>
                                                <div className="form-group form-float">
                                                    <div className="form-line">

                                                        <h4 className="mb-0">Marketplace specific details</h4>
                                                        <p className="text-muted" style={{ fontSize: "18px" }}>Information required to register in United States and Canada marketplace</p>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className="form-group form-float">
                                                    <div className="form-line">

                                                        <h4 className="mb-0">North America</h4>
                                                        <p className="text-success mb-0" style={{ fontSize: "18px" }}>No extra information needed</p>
                                                        <div className="custom-control custom-checkbox">
                                                            <input type="checkbox" name="remember" className="custom-control-input" tabIndex={3} id="remember-me" />
                                                            <label className="custom-control-label" htmlFor="remember-me">Remember Me</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </fieldset>
                                            <div className="row">
                                                <div className="col-md-12 pt-3 text-center">
                                                    <hr />
                                                    <button className="btn btn-primary col-md-3 mx-2" onClick={() => PreviousStep(2)}>Previous</button>
                                                    <button className="btn btn-primary col-md-3 mx-2" onClick={() => NextStep(2)}>Next</button>
                                                </div>
                                            </div>
                                        </> : null} */}
                                        {Step3 ?
                                            <>
                                                {/* <h3>Billing</h3> */}
                                                <form method="POST" onSubmit={onSubmitBillinfo}>
                                                    <div className="row">
                                                        <div className="col-md-12 mb-2 text-center">
                                                            <button onClick={() => CurruntStep(1)} style={{ width: "16%", height: "50px", fontSize: "15px" }} className="btn btn-primary">Personal Information</button>
                                                            {/* <button onClick={() => CurruntStep(2)} style={{ width: "14%", height: "50px", fontSize: "15px" }} className="btn btn-primary ml-2">Marketplaces</button> */}
                                                            <button onClick={() => CurruntStep(3)} style={{ width: "14%", height: "50px", fontSize: "15px" }} className="btn btn-primary ml-2">Billing</button>
                                                            <button disabled onClick={() => CurruntStep(4)} style={{ width: "14%", height: "50px", fontSize: "15px" }} className="btn btn-primary ml-2">Store</button>
                                                            <button disabled onClick={() => CurruntStep(5)} style={{ width: "14%", height: "50px", fontSize: "15px" }} className="btn btn-primary ml-2">Verification</button>
                                                            <button disabled onClick={() => CurruntStep(6)} style={{ width: "14%", height: "50px", fontSize: "15px" }} className="btn btn-primary ml-2">Confirmation</button>
                                                        </div>
                                                    </div>
                                                    <fieldset style={{ marginInline: "78px", border: "2px solid rgb(212, 212, 212)", padding: "12px", boxShadow: " 0px 0px 20px #cdd3d8" }}>
                                                        <div className="form-group form-float">
                                                            <div className="form-line">
                                                                {/* <label className="form-label">Username*</label>
                                                                                <input type="text" className="form-control" name="username" required /> */}
                                                                <h5 className="mb-0">Billing Information</h5>
                                                                <p className="text-muted" >Credit card details</p>
                                                            </div>
                                                        </div>
                                                        <hr />
                                                        <div className="form-group form-float">
                                                            <div className="form-line">
                                                                <p className="text-muted" style={{ fontSize: "15px" }}>We use credit card for account varification. A one time only one US dollar ($ 1) account varification charge will be applied to your credit card upon complition of account setup.</p>
                                                            </div>
                                                        </div>
                                                        <div className="row " id="SignupDetails">
                                                            <div className="col-md-12 text-center">

                                                                <Cards
                                                                    number={creditCardnum}
                                                                    name={cardHoldername}
                                                                    expiry={expiry}
                                                                    cvc={cvc}
                                                                    focused={Focus}
                                                                />
                                                            </div>
                                                        </div>


                                                        <div className="row">
                                                            <div className="col-md-6">

                                                                <div className="form-group form-float">
                                                                    <div className="form-line">
                                                                        <label className="form-label"><b>Credit card number</b></label>
                                                                        <input value={creditCardnum} onChange={(e) => setCreditcardnum(e.target.value)} onFocus={(e) => setFocus(e.target.name)} placeholder="Enter Credit Card Number" type="number" name="creditCardnum" className="form-control" />
                                                                        <div>
                                                                            {Object.keys(creditCardnumErr).map((key) => {
                                                                                return <div style={{ color: "red" }}>{creditCardnumErr[key]}</div>
                                                                            })}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">


                                                                <div className="form-group form-float">
                                                                    <div className="form-line">
                                                                        <label className="form-label"><b>Card holder's name</b></label>
                                                                        <input value={cardHoldername} onChange={(e) => setCardholdername(e.target.value)} placeholder="Enter Card Holder Name" name="cardHoldername" type="text" className="form-control" />
                                                                        <div>
                                                                            {Object.keys(cardHoldernameErr).map((key) => {
                                                                                return <div style={{ color: "red" }}>{cardHoldernameErr[key]}</div>
                                                                            })}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <div className="form-group form-float">
                                                                    <div className="form-line">
                                                                        <label className="form-label"><b>Expiry </b></label>
                                                                        <input value={expiry} onChange={(e) => setExpiry(e.target.value)} onFocus={(e) => setFocus(e.target.name)} name="expiry" placeholder="MM/YY Expiry" type="date" className="form-control" />
                                                                        <div>
                                                                            {Object.keys(expiryMonthErr).map((key) => {
                                                                                return <div style={{ color: "red" }}>{expiryMonthErr[key]}</div>
                                                                            })}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="form-group form-float">
                                                                    <div className="form-line">
                                                                        <label className="form-label"><b>Cvc</b></label>
                                                                        <input value={cvc} onChange={(e) => setCvc(e.target.value)} onFocus={(e) => setFocus(e.target.name)} placeholder="Enter Cvc" name="cvc" type="number" className="form-control" />
                                                                        <div>
                                                                            {Object.keys(CvcErr).map((key) => {
                                                                                return <div style={{ color: "red" }}>{CvcErr[key]}</div>
                                                                            })}
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-12 ml-4">
                                                                <div className="form-group form-float">
                                                                    <div className="form-line">
                                                                        {/* <label className="form-label">Username*</label> */}
                                                                        <h5 className=" mb-0" >Billing Address</h5>
                                                                        {/* <div className="custom-control custom-checkbox mt-2 ml-2">
                                                                            <input type="checkbox" name="address" className="custom-control-input" tabIndex={3} id="address" />
                                                                            <label className="custom-control-label" htmlFor="address">14 Evergreen Terrace, New Brunswick, NJ, 08904, US</label>
                                                                        </div> */}
                                                                        {/* <a href="#" className="mt-2 ml-2">+ Add a new address</a> */}
                                                                        <p>
                                                                            <a className="mt-2 ml-2" data-toggle="collapse" href="#collapseExample" role="button"
                                                                                aria-expanded="false" aria-controls="collapseExample">
                                                                                + Add new billing address
                                                                            </a>
                                                                        </p>
                                                                        <div className="collapse" style={{ width: "98%" }} id="collapseExample">
                                                                            <div className="row">
                                                                                <div className="col-md-6">
                                                                                    <input value={Billing_Address} onChange={(e) => setBilling_Address(e.target.value)} placeholder="Address Line 1" type="text" className="form-control" />
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <input value={Billing_Addres2} onChange={(e) => setBilling_Addres2(e.target.value)} placeholder="Address Line 2" type="text" className="form-control" />
                                                                                </div>
                                                                            </div>
                                                                            <div className="row mt-2">
                                                                                <div className="col-md-6">
                                                                                    <Country
                                                                                        // onSelect={(e)=>setBilling_Addres_Country(e.target.value)}
                                                                                        value={Billing_Addres_Country}
                                                                                        onSelect={(country) => {
                                                                                            setBilling_Addres_Country(country)
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <input value={Billing_Addres_StreetNumber} onChange={(e) => setBilling_Addres_StreetNumber(e.target.value)} placeholder="Street No" type="text" className="form-control" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </fieldset>
                                                    <div className="row">
                                                        <div className="col-md-12 pt-3 text-center">
                                                            <hr />
                                                            <button className="btn btn-primary col-md-3 mx-2" onClick={() => PreviousStep(2)}>Previous</button>
                                                            <button className="btn btn-primary col-md-3 mx-2" type="submit" >Next</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </>
                                            : null
                                        }
                                        {Step4 ?
                                            <>
                                                {/* <h3>Store</h3> */}
                                                <form method="POST" onSubmit={onSubmitStore}>
                                                    <div className="row">
                                                        <div className="col-md-12 mb-2 text-center">
                                                            <button onClick={() => CurruntStep(1)} style={{ width: "16%", height: "50px", fontSize: "15px" }} className="btn btn-primary">Personal Information</button>
                                                            {/* <button onClick={() => CurruntStep(2)} style={{ width: "14%", height: "50px", fontSize: "15px" }} className="btn btn-primary ml-2">Marketplaces</button> */}
                                                            <button onClick={() => CurruntStep(3)} style={{ width: "14%", height: "50px", fontSize: "15px" }} className="btn btn-primary ml-2">Billing</button>
                                                            <button onClick={() => CurruntStep(4)} style={{ width: "14%", height: "50px", fontSize: "15px" }} className="btn btn-primary ml-2">Store</button>
                                                            <button disabled onClick={() => CurruntStep(5)} style={{ width: "14%", height: "50px", fontSize: "15px" }} className="btn btn-primary ml-2">Verification</button>
                                                            <button disabled onClick={() => CurruntStep(6)} style={{ width: "14%", height: "50px", fontSize: "15px" }} className="btn btn-primary ml-2">Confirmation</button>
                                                        </div>
                                                    </div>
                                                    <fieldset style={{ marginInline: "78px", border: "2px solid rgb(212, 212, 212)", padding: "12px", boxShadow: " 0px 0px 20px #cdd3d8" }}>
                                                        <div className="form-group form-float">
                                                            <div className="form-line">
                                                                {/* <label className="form-label">Username*</label>
                                                                                <input type="text" className="form-control" name="username" required /> */}
                                                                <h5 className="mb-0">Store Information</h5>
                                                                <p className="text-muted" >Store name and product information</p>
                                                            </div>
                                                        </div>
                                                        <hr />
                                                        <div className="form-group form-float">
                                                            <div className="form-line">
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <label className="form-label"><b>Store name</b></label>
                                                                    </div>
                                                                    <div className="col-md-6 text-right">
                                                                        {Available ? <>
                                                                            <i className="fas fa-check-circle text-success"></i>
                                                                            <label className="form-label"> Available</label>
                                                                        </> : <>
                                                                            <i className="fas fa-times-circle text-danger"></i>
                                                                            <label className="form-label"> Not Available</label>
                                                                        </>}
                                                                    </div>
                                                                </div>
                                                                <input type="text" onChange={(e) => handleInputChange(e, setStoreName)} name="name" className="form-control" />
                                                                <div>
                                                                    {Object.keys(storeNameErr).map((key) => {
                                                                        return <div style={{ color: "red" }}>{storeNameErr[key]}</div>
                                                                    })}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <hr />
                                                        <div>
                                                            {/* <label className="form-label d-block"><b>Do you have Universal Product Codes (UPCs) for all yor products</b></label> */}
                                                            <div className="section-title">Do you have Universal Product Codes (UPCs) for all yor products</div>
                                                            <div className="custom-control custom-radio ml-2">
                                                                <input value={"Yes"} onClick={e => SetUniversalProduct(e.target.value)} type="radio" id="customRadio1" name="customRadio" className="custom-control-input" />
                                                                <label className="custom-control-label" htmlFor="customRadio1">Yes</label>
                                                            </div>
                                                            <div className="custom-control custom-radio ml-2">
                                                                <input value={"No"} onClick={e => SetUniversalProduct(e.target.value)} type="radio" id="customRadio2" name="customRadio" className="custom-control-input" />
                                                                <label className="custom-control-label" htmlFor="customRadio2">No</label>
                                                            </div>
                                                            <div>
                                                                {Object.keys(UniversalProductErr).map((key) => {
                                                                    return <div style={{ color: "red" }}>{UniversalProductErr[key]}</div>
                                                                })}
                                                            </div>
                                                            {/* <label className="form-label d-block"><b>Are you manufacturer or brand owner (or agent or representative of the brand) for any of the products you want to sell on Babylon?</b></label> */}
                                                            <div className="section-title">Are you manufacturer or brand owner (or agent or representative of the brand) for any of the products you want to sell on Babylon?</div>
                                                            <div className="custom-control custom-radio ml-2">
                                                                <input value={"Yes"} onClick={e => SetBrandowner(e.target.value)} type="radio" id="customRadio3" name="customRadio1" className="custom-control-input" />
                                                                <label className="custom-control-label" htmlFor="customRadio3">Yes</label>
                                                            </div>
                                                            <div className="custom-control custom-radio ml-2">
                                                                <input value={"No"} onClick={e => SetBrandowner(e.target.value)} type="radio" id="customRadio4" name="customRadio1" className="custom-control-input" />
                                                                <label className="custom-control-label" htmlFor="customRadio4">No</label>
                                                            </div>
                                                            <div>
                                                                {Object.keys(BrandownerErr).map((key) => {
                                                                    return <div style={{ color: "red" }}>{BrandownerErr[key]}</div>
                                                                })}
                                                            </div>
                                                            {/* <label className="form-label d-block"><b>Do you own government-registered trademark for the branded products you want to sell on Babylon?</b></label> */}
                                                            <div className="section-title">Do you own government-registered trademark for the branded products you want to sell on Babylon?</div>
                                                            <div className="custom-control custom-radio ml-2">
                                                                <input value={"Yes"} onClick={e => SetGovernmentRegistered(e.target.value)} type="radio" id="customRadio5" name="customRadio2" className="custom-control-input" />
                                                                <label className="custom-control-label" htmlFor="customRadio5">Yes</label>
                                                            </div>
                                                            <div className="custom-control custom-radio ml-2">
                                                                <input value={"No"} onClick={e => SetGovernmentRegistered(e.target.value)} type="radio" id="customRadio6" name="customRadio2" className="custom-control-input" />
                                                                <label className="custom-control-label" htmlFor="customRadio6">No</label>
                                                            </div>
                                                            <div className="custom-control custom-radio ml-2">
                                                                <input value={"Someofthem"} onClick={e => SetGovernmentRegistered(e.target.value)} type="radio" id="customRadio7" name="customRadio2" className="custom-control-input" />
                                                                <label className="custom-control-label" htmlFor="customRadio7">Some of them</label>
                                                            </div>
                                                            <div>
                                                                {Object.keys(GovernmentRegisteredErr).map((key) => {
                                                                    return <div style={{ color: "red" }}>{GovernmentRegisteredErr[key]}</div>
                                                                })}
                                                            </div>
                                                        </div>
                                                    </fieldset>
                                                    <div className="row">
                                                        <div className="col-md-12 pt-3 text-center">
                                                            <hr />
                                                            <button className="btn btn-primary col-md-3 mx-2" onClick={() => PreviousStep(4)} >Previous</button>
                                                            <button className="btn btn-primary col-md-3 mx-2" type="submit" disabled={!Available}>Next</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </> : null}
                                        {Step5 ?
                                            <>
                                                {/* <h3>varification</h3> */}
                                                <div className="row">
                                                    <div className="col-md-12 mb-2 text-center">
                                                        <button onClick={() => CurruntStep(1)} style={{ width: "14%", height: "50px", fontSize: "15px" }} className="btn btn-primary">Personal Information</button>
                                                        <button onClick={() => CurruntStep(2)} style={{ width: "14%", height: "50px", fontSize: "15px" }} className="btn btn-primary ml-2">Marketplaces</button>
                                                        <button onClick={() => CurruntStep(3)} style={{ width: "14%", height: "50px", fontSize: "15px" }} className="btn btn-primary ml-2">Billing</button>
                                                        <button onClick={() => CurruntStep(4)} style={{ width: "14%", height: "50px", fontSize: "15px" }} className="btn btn-primary ml-2">Store</button>
                                                        <button onClick={() => CurruntStep(5)} style={{ width: "14%", height: "50px", fontSize: "15px" }} className="btn btn-primary ml-2">Verification</button>
                                                        <button disabled onClick={() => CurruntStep(6)} style={{ width: "14%", height: "50px", fontSize: "15px" }} className="btn btn-primary ml-2">Confirmation</button>
                                                    </div>
                                                </div>
                                                <fieldset style={{ marginInline: "78px", border: "2px solid rgb(212, 212, 212)", padding: "12px", boxShadow: " 0px 0px 20px #cdd3d8" }}>
                                                    <div className="form-group form-float">
                                                        <div className="form-line">
                                                            <h5 className="text-center mb-0">Address Validation</h5>
                                                            <hr />
                                                            <p className="text-muted">A postcard containing a code will bemailed to the adress mentioned below. please confirm the address to receive the postcard. Once you confirm the address it will take 7 days for the mail to arrive. In case you want to change the address than click on previous.</p>
                                                            <h5 className="mb-0" >{addressLine1} {Business_Address_StreetNumber}</h5>
                                                            <p className="text-muted mb-0">{BusinessAddressCountry}</p>
                                                            <p className="text-muted">{phoneNum}</p>
                                                        </div>
                                                    </div>
                                                </fieldset>
                                                <div className="row">
                                                    <div className="col-md-12 pt-3 text-center">
                                                        <hr />
                                                        <button className="btn btn-primary col-md-3 mx-2" onClick={() => PreviousStep(5)}>Previous</button>
                                                        <button className="btn btn-primary col-md-3 mx-2" onClick={() => NextStep(5)}>Next</button>
                                                    </div>
                                                </div>
                                            </>
                                            : null}
                                        {Step6 ?
                                            <>
                                                {/* <h3>Confirmation</h3> */}
                                                <form method="POST" onSubmit={onSubmitconf}>
                                                    <div className="row">
                                                        <div className="col-md-12 mb-2 text-center">
                                                            <button onClick={() => CurruntStep(1)} style={{ width: "16%", height: "50px", fontSize: "15px" }} className="btn btn-primary">Personal Information</button>
                                                            {/* <button onClick={() => CurruntStep(2)} style={{ width: "14%", height: "50px", fontSize: "15px" }} className="btn btn-primary ml-2">Marketplaces</button> */}
                                                            <button onClick={() => CurruntStep(3)} style={{ width: "14%", height: "50px", fontSize: "15px" }} className="btn btn-primary ml-2">Billing</button>
                                                            <button onClick={() => CurruntStep(4)} style={{ width: "14%", height: "50px", fontSize: "15px" }} className="btn btn-primary ml-2">Store</button>
                                                            <button onClick={() => CurruntStep(5)} style={{ width: "14%", height: "50px", fontSize: "15px" }} className="btn btn-primary ml-2">Verification</button>
                                                            <button onClick={() => CurruntStep(6)} style={{ width: "14%", height: "50px", fontSize: "15px" }} className="btn btn-primary ml-2">Confirmation</button>
                                                        </div>
                                                    </div>
                                                    <fieldset style={{ marginInline: "78px", border: "2px solid rgb(212, 212, 212)", padding: "12px", boxShadow: " 0px 0px 20px #cdd3d8" }}>

                                                        <div className="form-group form-float">
                                                            <div className="form-line">
                                                                <h5 className="text-center mb-0">Address Validation</h5>
                                                                <hr />
                                                                <p className="text-muted">A postcard with your code was mailed to the address mentioned below. It takes 7 days for the mail to arrive and you can track the status below.</p>
                                                                <h5 className="mb-0">{addressLine1} {Business_Address_StreetNumber}</h5>
                                                                <p className="text-muted mb-0">{BusinessAddressCountry}</p>
                                                                <p className="text-muted">{phoneNum}</p>
                                                                <h5 className="text-danger mb-0">Preparing</h5>
                                                                <h5 className="d-inline mb-0">Address Validation</h5>
                                                                <p className="text-success d-inline mb-0"> 15-May-2020</p>
                                                            </div>
                                                        </div>
                                                        <div className="form-group form-float">
                                                            <div className="form-line">
                                                                <div className="row">
                                                                    <div className="col-md-3">
                                                                        <label className="form-label text-info"><b>Enter code below</b></label>
                                                                        <input type="number" className="form-control" value={confirmationCode} onChange={(e) => setConfirmationcode(e.target.value)} name="OTP" />
                                                                        {Ctrue ?
                                                                            <div>
                                                                                {Object.keys(confirmationCodeErr).map((key) => {
                                                                                    return <div style={{ color: "green" }}>{confirmationCodeErr[key]}</div>
                                                                                })}
                                                                            </div>
                                                                            :
                                                                            <div>
                                                                                {Object.keys(confirmationCodeErr).map((key) => {
                                                                                    return <div style={{ color: "red" }}>{confirmationCodeErr[key]}</div>
                                                                                })}
                                                                            </div>
                                                                        }
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>

                                                    </fieldset>
                                                    <div className="row">
                                                        <div className="col-md-12 pt-3 text-center">
                                                            <hr />
                                                            <button className="btn btn-primary col-md-3 mx-2" onClick={() => PreviousStep(6)}>Previous</button>

                                                            <button className="btn btn-primary col-md-3 mx-2" type="submit" >Finish</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </>
                                            : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    data: state.Register

})

const mapDispatchToProps = {
    checkEmailorPhone,
    setValue
}

export default connect(mapStateToProps, mapDispatchToProps)(SignupDetails)