/* eslint-disable no-unused-vars */
import Footer from '../../../components/footer/footer';
import Header from '../../../components/header/header';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import BaseUrl from '../../../../config/BaseUrl';
import { Modal, Button, Form } from 'react-bootstrap';

const Auction = () => {
  const [products, setProducts] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);

  const GetData = () => {
    const token = localStorage.getItem('SellerToken');

    axios.get(`${BaseUrl.base_url}/products-with-auctions/`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        setProducts(response.data);
        setLoading(false);
      })
      .catch(error => {
        console.error("There was an error fetching the product data!", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    GetData();
  }, []);

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const handleViewProduct = (product) => {
    setSelectedProduct(product);
    setShowModal(true);
  };

  const handleViewProductBids = (product) => {
    setSelectedProduct(product);
    setShowModal2(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setShowModal2(false);
    setSelectedProduct(null);
  };


  const [searchTerm, setSearchTerm] = useState('');

  const handleSearchChange = (e) => {
    setSearchTerm(e?.target.value?.toLowerCase());
  };

  const filteredBids = selectedProduct?.auctions
    ? selectedProduct?.auctions?.flatMap(auction => auction.bids || [])
        .filter(bid => 
          bid?.bidder?.toLowerCase().includes(searchTerm) || 
          bid?.bid_amount?.toString().includes(searchTerm)
        )
    : [];

    if(!products){
      return(
        <div style={{width:'100%',height:'100vh',display:'flex',justifyContent:'center',alignItems:'center'}}>
          
          <img src={'./assets/img/loader.svg'} alt="loader"/>
      
          </div>
      )}

  return (
    <div id="app">
      <div className="main-wrapper main-wrapper-1">
        <Header />
        <div className="main-content">
          <section className="section">
            <div className="container">
              <div className="row">
                {/* Product Cards */}
                {products.map((product) => (
                  <div key={product.id} className="col-md-4 mb-3 d-flex">
                    <div className="card w-100">
                      <div className="card-header">
                        <h5 className="mb-0">{product.Product_Name}</h5>
                      </div>
                      <div className="card-body p-3 d-flex flex-column">
                        {/* Product Images Carousel */}
                        {product.images && product.images.length > 0 && (
                          <Slider {...sliderSettings}>
                            {product.images.map((image, i) => (
                              <div key={i} className="slider-item">
                                <img
                                  src={image.url}
                                  alt={image.name || 'Product Image'}
                                  style={{ width: '100%', height: 'auto', objectFit: 'cover' }}
                                  className="w-full h-auto"
                                />
                              </div>
                            ))}
                          </Slider>
                        )}
                        <div className="mt-auto" style={{display:'flex' ,justifyContent:'space-around'}}>
                          <Button onClick={() => handleViewProduct(product)} variant="primary" className="mt-3">
                            View details
                          </Button>
                          <Button onClick={() => handleViewProductBids(product)} variant="primary" className="mt-3">
                            View Bids
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
        </div>
        {/* <Footer /> */}
      </div>

      {/* Modal for Viewing Product Details */}
      {selectedProduct && (
        <>
     <Modal show={showModal} onHide={handleCloseModal} size="lg">
     <Modal.Header>
       <Modal.Title>{selectedProduct.Product_Name}</Modal.Title>
     </Modal.Header>
     <Modal.Body>
       {/* Product Images Carousel */}
       {selectedProduct.images && selectedProduct.images.length > 0 && (
<div style={{width:'300px',margin:'auto'}}>


         <Slider {...sliderSettings} className="mb-4">
           {selectedProduct.images.map((image, i) => (
             <div key={i} className="slider-item">
               <img
                 src={image.url}
                 alt={image.name || 'Product Image'}
                 style={{ width: '100%', height: '100%', objectFit: '' }}
                 className="rounded shadow"
               />
             </div>
           ))}
         </Slider>

         </div>
       )}
   
       {/* Product Details and Auction Details */}
       <div className="container">
         <div className="row">
           {/* Product Details */}
           <div className="col-md-6 mb-3">
             <p className="mb-2">
               <strong>Category:</strong> {selectedProduct.Category}
             </p>
             <p>
               <strong>Description:</strong>
               <div
                 dangerouslySetInnerHTML={{ __html: selectedProduct.long_Description }}
                 className="text-muted"
               />
             </p>
           </div>
           {/* Auction Details */}
           <div className="col-md-6 mb-3">
             <p className="mb-2">
               <strong>Starting Price:</strong> ${selectedProduct?.auctions[0].starting_price}
             </p>
             <p className="mb-0">
               <strong>Auction Duration:</strong> {selectedProduct?.auctions[0].auction_duration} Days
             </p>
           </div>
         </div>
       </div>
     </Modal.Body>
     <Modal.Footer>
       <Button variant="secondary" onClick={handleCloseModal}>
         Close
       </Button>
     </Modal.Footer>
   </Modal>






   <Modal show={showModal2} onHide={handleCloseModal} size="lg">
      <Modal.Header >
        <Modal.Title>Bids for {selectedProduct.Product_Name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* Search Filter */}
        <Form.Control
          type="text"
          placeholder="Search by amount..."
          value={searchTerm}
          onChange={handleSearchChange}
          className="mb-3"
        />

        {/* Bids Details */}
        <div className="container">
          {filteredBids.length > 0 ? (
            <div className="list-group">
              {filteredBids.map(bid => (
                <div key={bid.id} className="list-group-item d-flex justify-content-between align-items-center">
                  <div>
                    {/* <strong>Bidder:</strong> {bid.bidder} */}
                    <strong>Amount:</strong> ${bid.bid_amount}
                    <br />
                    <strong>Time:</strong> {new Date(bid.bid_time).toLocaleString()}
                  </div>
                  <span style={{color:'white'}} className="badge bg-primary rounded-pill">Bid</span>
                </div>
              ))}
            </div>
          ) : (
            <div className="alert alert-info" role="alert">
              No bids match the search criteria.
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseModal}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>

        </>



   
    
      )}
    </div>
  );
};
export default Auction;