/* eslint-disable */
import React, { useState, useEffect } from "react"
import AdminHeader from "../components/adminheader/AdminHeader"
import AdminFooter from "../components/adminfooter/AdminFooter"
import { makeStyles } from "@material-ui/core/styles";
import MaterialTable from 'material-table'
import { RemoveRedEye } from "@material-ui/icons"
import Swal from 'sweetalert2'
import BaseUrl from "../../../../config/BaseUrl"
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import IconButton from "@material-ui/core/IconButton"
import { getApiWithToken, PutApi, PostApi, DeleteApi } from "../../../../config/Api/FakeuserApi";

import BootstrapSwitchButton from 'bootstrap-switch-button-react'





const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    input: {
        display: "none",
    },
}));

export default function ActiveVendors() {
    const [selectedRow, setSelectedRow] = useState(null);

    // // GetAllCategories STATES
    const [GetAllAvendors, SetGetAllAvendors] = useState([])
    // console.log("safdar",typeof GetAllAvendors)
    const [Loader, setLoader] = useState(false)
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [SellerAccount, SetSellerAccount] = useState("")

    const [MemAccount, SetMemAccount] = useState("")
     // Second Modal
     const [show2, setShow2] = useState(false);
     const handleClose2 = () => setShow2(false);
     const handleShow2 = () => setShow2(true);


    // console.log("SellerAccount",SellerAccount)


      // VIEW AllBrandsName API
      const Token = localStorage.getItem('Token');
      useEffect(() => {
        ViewActiveVendors()
      }, [])


    const ViewActiveVendors = () => {
        setLoader(true);

        getApiWithToken(`${BaseUrl.Admin_Url}/GetActiveSellers`, "", Token)
            .then(({ data }) => {
                setLoader(false);
                if (data.status) {
                    SetGetAllAvendors(data.data)


                } else {
                    // $(".loader").hide();
                    Swal.fire({
                        title: "Opps",
                        text: data.message,
                        icon: "error"
                    })
                }
            }).catch(function (e) {
                // $(".loader").hide();
                Swal.fire({
                    title: "Opps",
                    text: e.message,
                    icon: "error"
                })
            })

    }


       // UPDATE STATUS PUT API

       const UpdateStatus = (Sellerid,status) => {
        setLoader(true);
        var formdata = new FormData();

        formdata.append("sellerid", Sellerid);
        formdata.append("Status","False");
        // formdata.append("Percentage",EditPercentage);

        PutApi(`${BaseUrl.Admin_Url}/GetActiveSellers`, formdata, Token)
            .then(({ data }) => {
                setLoader(false);
                if (data.status) {
                    console.log("inactive==>", data)
                    ViewActiveVendors()
                    // handleClose2()
                    Swal.fire({
                        title: "success",
                        text: data.message,
                        icon: "success"
                    })
                } else {
                    // $(".loader").hide();
                    Swal.fire({
                        title: "Opps",
                        text: data.message,
                        icon: "error"
                    })
                }
            }).catch(function (e) {
                // $(".loader").hide();
                Swal.fire({
                    title: "Opps",
                    text: e.message,
                    icon: "error"
                })
            })
    }



    return (
        <>
    {Loader?<div class="loader"></div>:null}
            <div id="app">
                <AdminHeader />
                <div class="main-wrapper main-wrapper-1">
                    <div className="main-content">
                        <section className="section">
                            <ul className="breadcrumb breadcrumb-style ">
                                <li className="breadcrumb-item">
                                    <h4 className="page-title m-b-0"> Subadmin</h4>
                                </li>

                                <li className="breadcrumb-item active">Active Vendors </li>


                                {/* <div class="row">
                                    <div class="col-md-12  text-right">
                                        <button   type="button" class="btn btn-primary">Add Category</button>

                                    </div>
                                </div> */}
                            </ul>

                            <div style={{ maxWidth: '100%' }}>
                                <MaterialTable
                                 data={GetAllAvendors}

                                    title=""
                                    columns={[

                                        {
                                            title: 'Seller Name',
                                            render: rowData => rowData?.First_name + " " +  rowData?.last_name},
                                        // { title: "Brand_Name", field: "Brand_Name"?"Brand_Name":"" },
                                        { title: "status", field: "Account_Status" },

                                        {
                                            title: 'Switch',
                                            render: rowData => <BootstrapSwitchButton onChange={()=>UpdateStatus(rowData.sellerid,rowData.status)}    checked={true} size="md" />
                                          }



                                        // { title: "Percentage ", field: "Percentage", },

                                        //  <Button >Button</Button>



                                    ]}


                                    onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
                                    options={{
                                        rowStyle: rowData => ({
                                            backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                                        }),
                                        actionsColumnIndex: -1,
                                    }}
                                    // other props
                                    actions={[
                                        rowData => ({
                                            icon: RemoveRedEye,
                                            tooltip: 'View Seller Detail',
                                            isFreeAction: true,
                                            onClick: (event, rowData) => {
                                                // console.log(rowData.Sellerid, "rowdatasell")
                                                SetSellerAccount(rowData)
                                                console.log("right",rowData.sellerid)
                                                handleShow()

                                            }


                                        }),

                                        rowData => ({
                                            icon: RemoveRedEye,
                                            tooltip: 'View Membership Details',
                                            isFreeAction: true,
                                            onClick: (event, rowData) => {
                                                // console.log(rowData.Sellerid, "rowdatasell")
                                                SetMemAccount(rowData.MembershipId)
                                                console.log("wrong",rowData.id)
                                                handleShow2()

                                            }

                                        }),

                                        // rowData => ({
                                        //     icon: 'delete',
                                        //     tooltip: 'Delete User',
                                        //     onClick: () => {
                                        //     }
                                        // })

                                    ]}
                                    options={{
                                        maxBodyHeight: 400,
                                        overflowX: 'x-scroll',
                                        actionsColumnIndex: 7,
                                        headerStyle: {
                                            backgroundColor: '#222',
                                            color: "white"
                                          }
                                      }}
                                />


                            </div>


                        </section>

                    </div>
                    <AdminFooter />
                </div>
            </div>

            <Modal
                scrollable={true}
                size="lg"
                // aria-labelledby="example-modal-sizes-title-lg"
                show={show}
                onHide={handleClose}>
                <Modal.Header >
                    <Modal.Title>Seller Details</Modal.Title>
                    <hr />
                    <i onClick={handleClose} style={{ fontSize: "24px" }} className=" fa-10X fas fa-window-close"></i>
                </Modal.Header>




                <Modal.Body  >

                    <form>


                        <div className="row">
                            <div className="col-md-6">
                                <small style={{ fontSize: "13px" }}>First Name</small>
                                <p style={{ fontSize: "15px", fontWeight: "bold" }}>{SellerAccount.First_name}</p>

                            </div>
                            <div className="col-md-6">
                                <small style={{ fontSize: "13px" }}>Middle Name</small>
                                <p style={{ fontSize: "15px", fontWeight: "bold" }}>{SellerAccount.Middle_name ? SellerAccount.Middle_name : "---"}</p>

                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <small style={{ fontSize: "13px" }}>Last Name</small>
                                <p style={{ fontSize: "15px", fontWeight: "bold" }}>{SellerAccount.last_name}</p>

                            </div>
                            <div className="col-md-6">
                                <small style={{ fontSize: "13px" }}>Email</small>
                                <p style={{ fontSize: "15px", fontWeight: "bold" }}>{SellerAccount.Email}</p>

                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <small style={{ fontSize: "13px" }}>Store Name</small>
                                <p style={{ fontSize: "15px", fontWeight: "bold" }}>{SellerAccount.Store_Name}</p>

                            </div>
                            <div className="col-md-6">
                                <small style={{ fontSize: "13px" }}>Are you Brand Owner</small>
                                <p style={{ fontSize: "15px", fontWeight: "bold" }}>{SellerAccount.Are_you_Brand_Owner}</p>

                            </div>
                        </div>


                        <div className="row">
                            <div className="col-md-6">
                                <small style={{ fontSize: "13px" }}>Account Status</small>
                                <p style={{ fontSize: "15px", fontWeight: "bold" }}>{SellerAccount.Account_Status}</p>

                            </div>
                            <div className="col-md-6">
                                <small style={{ fontSize: "13px" }}>Shop Status</small>
                                <p style={{ fontSize: "15px", fontWeight: "bold" }}>{SellerAccount.Shop_Status}</p>

                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <small style={{ fontSize: "13px" }}>Do You Goverment registered trademark</small>
                                <p style={{ fontSize: "15px", fontWeight: "bold" }}>{SellerAccount.Do_You_Goverment_registered_trademark}</p>

                            </div>

                            <div className="col-md-6">
                                <small style={{ fontSize: "13px" }}>Date Of Birth</small>
                                <p style={{ fontSize: "15px", fontWeight: "bold" }}>{SellerAccount.DOB}</p>

                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <small style={{ fontSize: "13px" }}>Business Location</small>
                                <p style={{ fontSize: "15px", fontWeight: "bold" }}>{SellerAccount.Business_Location}</p>

                            </div>
                            <div className="col-md-6">
                                <small style={{ fontSize: "13px" }}>Business Type</small>
                                <p style={{ fontSize: "15px", fontWeight: "bold" }}>{SellerAccount.Business_Type}</p>

                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <small style={{ fontSize: "13px" }}>Country of Citizenship</small>
                                <p style={{ fontSize: "15px", fontWeight: "bold" }}>{SellerAccount.Country_of_Citizenship}</p>

                            </div>
                            <div className="col-md-6">
                                <small style={{ fontSize: "13px" }}>Country of Birth </small>
                                <p style={{ fontSize: "15px", fontWeight: "bold" }}>{SellerAccount.Country_of_Birth}</p>

                            </div>
                        </div>
                        <div className="row">
                        <div className="col-md-6">
                                <small style={{ fontSize: "13px" }}>Registration Date</small>
                                <p style={{ fontSize: "15px", fontWeight: "bold" }}>{SellerAccount.Registration_Date}</p>

                            </div>
                            <div className="col-md-6">
                                <small style={{ fontSize: "13px" }}>ExpirationDate</small>
                                <p style={{ fontSize: "15px", fontWeight: "bold" }}>{SellerAccount.ExpirationDate}</p>

                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <small style={{ fontSize: "13px" }}>Proof of Identity</small>
                                <p style={{ fontSize: "15px", fontWeight: "bold" }}>{SellerAccount.Proof_of_Identity}</p>

                            </div>
                            <div className="col-md-6">
                                <small style={{ fontSize: "13px" }}>Country of issue</small>
                                <p style={{ fontSize: "15px", fontWeight: "bold" }}>{SellerAccount.Country_of_issue}</p>

                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <small style={{ fontSize: "13px" }}>Business_address1</small>
                                <p style={{ fontSize: "15px", fontWeight: "bold" }}>{SellerAccount.Business_address1}</p>

                            </div>
                            <div className="col-md-6">
                                <small style={{ fontSize: "13px" }}>Business_address2</small>
                                <p style={{ fontSize: "15px", fontWeight: "bold" }}>{SellerAccount.Business_address2}</p>

                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <small style={{ fontSize: "13px" }}>Business Address Country</small>
                                <p style={{ fontSize: "15px", fontWeight: "bold" }}>{SellerAccount.Business_Address_Country}</p>

                            </div>
                            <div className="col-md-6">
                                <small style={{ fontSize: "13px" }}>Business Address Zipcode</small>
                                <p style={{ fontSize: "15px", fontWeight: "bold" }}>{SellerAccount.Business_Address_Zipcode}</p>

                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <small style={{ fontSize: "13px" }}>Businessadress Country Code</small>
                                <p style={{ fontSize: "15px", fontWeight: "bold" }}>{SellerAccount.Businessadress_Country_Code}</p>

                            </div>
                            <div className="col-md-6">
                                <small style={{ fontSize: "13px" }}>Businessadress_Number</small>
                                <p style={{ fontSize: "15px", fontWeight: "bold" }}>{SellerAccount.Businessadress_Number}</p>

                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <small style={{ fontSize: "13px" }}>Universal_Products</small>
                                <p style={{ fontSize: "15px", fontWeight: "bold" }}>{SellerAccount.Universal_Products}</p>

                            </div>
                            <div className="col-md-6">
                                <small style={{ fontSize: "13px" }}>Billing_Address</small>
                                <p style={{ fontSize: "15px", fontWeight: "bold" }}>{SellerAccount.Billing_Address}</p>

                            </div>
                        </div>



                    </form>
                </Modal.Body>

                <Modal.Footer>

                    {/* <Button variant="primary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" >
                        Update
                    </Button> */}
                </Modal.Footer>
            </Modal>


            {/* Membership Detail Modal  */}


            <Modal
                scrollable={true}
                size="lg"
                // aria-labelledby="example-modal-sizes-title-lg"
                show={show2}
                onHide={handleClose2}>
                <Modal.Header >
                    <Modal.Title>MemberShip Details</Modal.Title>
                    <hr />
                    <i onClick={handleClose2} style={{ fontSize: "24px" }} className=" fa-10X fas fa-window-close"></i>
                </Modal.Header>




                <Modal.Body  >

                    <form>


                        <div className="row">
                            <div className="col-md-6">
                                <small style={{ fontSize: "13px" }}>Membership_Name</small>
                                <p style={{ fontSize: "15px", fontWeight: "bold" }}>{MemAccount.Membership_Name}</p>

                            </div>
                            <div className="col-md-6">
                                <small style={{ fontSize: "13px" }}>Membership_Description</small>
                                <p style={{ fontSize: "15px", fontWeight: "bold" }}>{MemAccount.Membership_Description}</p>

                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <small style={{ fontSize: "13px" }}>Membership_DurationStart</small>
                                <p style={{ fontSize: "15px", fontWeight: "bold" }}>{MemAccount.Membership_DurationStart}</p>

                            </div>
                            <div className="col-md-6">
                                <small style={{ fontSize: "13px" }}>Membership_DurationEnd</small>
                                <p style={{ fontSize: "15px", fontWeight: "bold" }}>{MemAccount.Membership_DurationEnd}</p>

                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <small style={{ fontSize: "13px" }}>Membership_Price</small>
                                <p style={{ fontSize: "15px", fontWeight: "bold" }}>{MemAccount.Membership_Price}</p>

                            </div>
                            <div className="col-md-6">
                                <small style={{ fontSize: "13px" }}>Country</small>
                                <p style={{ fontSize: "15px", fontWeight: "bold" }}>{MemAccount.Country}</p>

                            </div>
                        </div>


                        <div className="row">
                            <div className="col-md-6">
                                <small style={{ fontSize: "13px" }}> Status</small>
                                <p style={{ fontSize: "15px", fontWeight: "bold" }}>{MemAccount.Status}</p>

                            </div>
                            <div className="col-md-6">
                                <small style={{ fontSize: "13px" }}>Membership ID</small>
                                <p style={{ fontSize: "15px", fontWeight: "bold" }}>{MemAccount.id}</p>

                            </div>
                        </div>




                    </form>
                </Modal.Body>
                <Modal.Footer>

                    {/* <Button variant="primary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" >
                        Update
                    </Button> */}
                </Modal.Footer>
            </Modal>


        </>
    )
}
