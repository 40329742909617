/* eslint-disable */
import React, { useState, useEffect, } from "react"
import { useHistory } from "react-router"
import ReactDOM from "react-dom";
import { BrowserRouter, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { connect, useDispatch } from "react-redux";
import background from "../auth/background.png"


const EmailVarify=(props) =>{
    console.log("props", props)
    console.log("emailverificationcode", props.data.EmailVarificationCode)

    useEffect(() => {

        alert("your emailverificationcode is" + " " + props.data.EmailVarificationCode )
    }, [])



    const history = useHistory();
    const [otp, setOtp] = useState();
    const location = useLocation();
    // location.state
    // console.log("  location.state", location.state)

    const [otpErr, setOtperr] = useState({});

    const onSubmit = (e) => {
        e.preventDefault();
        const isValid = formValidation();
        if (isValid == true) {
              if(otp.length < 4){
                const otpErr = {};
                let isValid = true;

                otpErr.otpIncorrect = "Please enter minimum 4 digits otp code";
                isValid = false;


                setOtperr(otpErr);
                return isValid;

            }else if(otp.length > 4){
                const otpErr = {};
                let isValid = true;

                otpErr.otpIncorrect = "Please enter maximum 4 digits otp code";
                isValid = false;

                setOtperr(otpErr);
                return isValid;
            }else if (otp == props.data.EmailVarificationCode ) {
                history.push("/businessDetails");
            }else {
                const otpErr = {};
                let isValid = true;
                otpErr.otpIncorrect = "Please enter correct OTP number";
                isValid = false;
                setOtperr(otpErr);
                return isValid;
            }
        }
    }

    const formValidation = () => {
        const otpErr = {};

        let isValid = true;

        if (otp === 0) {
            otpErr.otpIncorrect = "Please enter an OTP number";
            isValid = false;
        }

        setOtperr(otpErr);
        return isValid;
    }


    return (
        <>
            {/* <div className="loader" /> */}
            <div id="app" style={{backgroundImage: `url(${background})`, marginTop: '-50px', backgroundSize: 'cover', height: '100vh' }}>
                <section className="section">
                    <div className="container mt-5">
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <img style={{ width: "20%" }} src="./assets/img/fav.png"></img>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
                                <div className="card card-primary">
                                    <div className="card-header">
                                        <h4>Varify email address</h4>
                                    </div>
                                    <div className="card-body">
                                        <p style={{ lineHeight: "22px" }} className="mb-0 text-muted">To varify your emial, we,ve sent a One Time Password (OTP) to testing abc321@gmail.com </p>
                                        <a href="#">(Change)</a>
                                        <form method="POST" onSubmit={onSubmit}>
                                            <div className="form-group">
                                                <label htmlFor="email">Enter OTP</label>
                                                <input value={otp} onChange={(e) => { setOtp(e.target.value) }} placeholder="0" type="number" className="form-control" name="OTP" tabIndex={1} autofocus />
                                                <div>
                                                    {Object.keys(otpErr).map((key) => {
                                                        return <div style={{ color: "red" }}>{otpErr[key]}</div>
                                                    })}
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <button type="submit" className="btn btn-primary btn-lg btn-block" tabIndex={4}>
                                                    Continue
                                                </button>
                                            </div>
                                        </form>
                                        <div className=" mt-4 mb-3">
                                            <div className="text-job text-muted">By creating an account, you agree to Babylon's <a href="#">Condition of Use</a> and <a href="#">Privacy Notice</a>.</div>
                                        </div>
                                        {/* <div className="text-center mt-4 mb-3">
                                            <div className="text-job text-muted">Login With Social</div>
                                        </div>
                                        <div className="row sm-gutters">
                                            <div className="col-6">
                                                <a className="btn btn-block btn-social btn-facebook">
                                                    <span className="fab fa-facebook" /> Facebook
                                                </a>
                                            </div>
                                            <div className="col-6">
                                                <a className="btn btn-block btn-social btn-twitter">
                                                    <span className="fab fa-twitter" /> Twitter
                                                </a>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                                {/* <div className="mt-5 text-muted text-center">
                                    Don't have an account? <a href="auth-register.html">Create One</a>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

const mapStateToProps = (state) => ({
   data: state.Register

})

export default connect(mapStateToProps, null)(EmailVarify)

