/* eslint-disable */
import React,{useState,useEffect} from "react"
import SuperAdminHeader from "../components/SAheader/Header"
import SuperAdminFooter from "../components/SAfooter/Footer"
import Swal from 'sweetalert2'
import BaseUrl from "../../../../config/BaseUrl"
import { getApiWithToken, PutApi, PostApi, DeleteApi } from "../../../../config/Api/FakeuserApi";




export default function SuperAdminDashboard() {
    const [Loader, setLoader] = useState(false)
    const [Sdash, setSdash] = useState([])
    // console.log("sdash",Sdash)



    const Token = localStorage.getItem('SuperToken');


    useEffect(() => {

        GetSAdminDashboard()

    }, [])


     // VIEW SuperADmin Dashb API

     const GetSAdminDashboard = () => {
        setLoader(true);


        getApiWithToken(`${BaseUrl.Super_Admin_Url}/GetDashboardDetails`, "", Token)
            .then(({ data }) => {
                setLoader(false);
                if (data.status) {
                    setSdash(data)
                    console.log("superadmin data==>", data)
                    // setdisable(true);
                    // SetUstatus
                    // $(".loader").hide();

                } else {
                    // $(".loader").hide();
                    // Swal.fire({
                    //     title: "Opps",
                    //     text: data.message,
                    //     icon: "error"
                    // })
                }
            }).catch(function (e) {
                // $(".loader").hide();
                Swal.fire({
                    title: "Opps",
                    text: e.message,
                    icon: "error"
                })
            })

    }
    // console.log("sdash",Sdash)

    return (
        <>

           {Loader? <div class="loader"></div>:null}
            <div id="app">
                <SuperAdminHeader/>
                <div class="main-wrapper main-wrapper-1">
                    <div className="main-content">
                        <section className="section">
                            <ul className="breadcrumb breadcrumb-style ">
                                <li className="breadcrumb-item">
                                    <h4 className="page-title m-b-0">Dashboard</h4>
                                </li>


                            </ul>
                            <div className="row">

                            <div className="col-lg-4 col-sm-6">
                                    <div className="card">
                                        <div className="card-statistic-5">
                                            <div className="info-box7-block">
                                                <div className="row ">
                                                    <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                                                        <div className="banner-img">
                                                            <img src="assets/img/banner/1.png" alt="" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-7 col-md-7 col-sm-7 col-xs-7">
                                                        <h6 className="m-b-20 text-right">Active Products </h6>
                                                        <h4 className="text-right">{Sdash.ActiveProducts}<span>
                                                        </span>
                                                        </h4>
                                                    </div>
                                                </div>
                                                {/* <div id="cardChart2" /> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="col-lg-4 col-sm-6">
                                    <div className="card">
                                        <div className="card-statistic-5">
                                            <div className="info-box7-block">
                                                <div className="row ">
                                                    <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                                                        <div className="banner-img">
                                                            <img src="assets/img/banner/vendors.png" alt="" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-7 col-md-7 col-sm-7 col-xs-7">
                                                        <h6 className="m-b-20 text-right">Pending Products</h6>
                                                        <h4 className="text-right"><span>{Sdash.PendingProducts}</span>
                                                        </h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="col-lg-4 col-sm-6">
                                    <div className="card">
                                        <div className="card-statistic-5">
                                            <div className="info-box7-block">
                                                <div className="row ">
                                                    <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                                                        <div className="banner-img">
                                                            <img src="assets/img/banner/active.png" alt="" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-7 col-md-7 col-sm-7 col-xs-7">
                                                        <h6 className="m-b-20 text-right">Total Products</h6>
                                                        <h4 className="text-right"><span>{Sdash.TotalProducts}</span>
                                                        </h4>
                                                    </div>
                                                </div>
                                                {/* <div id="cardChart1"/> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            <div className="col-lg-4 col-sm-6">
                                    <div className="card">
                                        <div className="card-statistic-5">
                                            <div className="info-box7-block">
                                                <div className="row ">
                                                    <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                                                        <div className="banner-img">
                                                            <img src="assets/img/banner/admin.PNG" alt="" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-7 col-md-7 col-sm-7 col-xs-7">
                                                        <h6 className="m-b-20 text-right"> Admin</h6>
                                                        <h4 className="text-right"><span>{Sdash.AdminCount}</span>
                                                        </h4>
                                                    </div>
                                                </div>
                                                {/* <div id="cardChart3" /> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6">
                                    <div className="card">
                                        <div className="card-statistic-5">
                                            <div className="info-box7-block">
                                                <div className="row ">
                                                    <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                                                        <div className="banner-img">
                                                            <img src="assets/img/banner/1.png" alt="" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-7 col-md-7 col-sm-7 col-xs-7">
                                                        <h6 className="m-b-20 text-right">New Customers</h6>
                                                        <h4 className="text-right"><span>{Sdash.Customers}</span>
                                                        </h4>
                                                    </div>
                                                </div>
                                                {/* <div id="cardChart2" /> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6">
                                    <div className="card">
                                        <div className="card-statistic-5">
                                            <div className="info-box7-block">
                                                <div className="row ">
                                                    <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                                                        <div className="banner-img">
                                                            <img src="assets/img/banner/vendors.png" alt="" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-7 col-md-7 col-sm-7 col-xs-7">
                                                        <h6 className="m-b-20 text-right">Active Vendors</h6>
                                                        <h4 className="text-right"><span>{Sdash.ActiveVendor}</span>
                                                        </h4>
                                                    </div>
                                                </div>
                                                {/* <div id="cardChart1"/> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <div className="col-lg-4 col-sm-6">
                                    <div className="card">
                                        <div className="card-statistic-5">
                                            <div className="info-box7-block">
                                                <div className="row ">
                                                    <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                                                        <div className="banner-img">
                                                            <img src="assets/img/banner/active.png" alt="" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-7 col-md-7 col-sm-7 col-xs-7">
                                                        <h6 className="m-b-20 text-right">Pending Vendors</h6>
                                                        <h4 className="text-right"><span>{Sdash.PendingVendor}</span>
                                                        </h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="col-lg-4 col-sm-6">

                                    <div className="card">
                                        <div className="card-statistic-5">
                                            <div className="info-box7-block">
                                                <div className="row ">
                                                    <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                                                        <div className="banner-img">
                                                            <img src="assets/img/banner/2.png" alt="" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-7 col-md-7 col-sm-7 col-xs-7">
                                                        <h6 className="m-b-20 text-right">Orders Received</h6>
                                                        <h4 className="text-right"><span>{Sdash.Orders}</span>
                                                        </h4>
                                                    </div>
                                                </div>
                                                {/* <div id="cardChart4" /> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="col-lg-4 col-sm-6">
                                    <div className="card">
                                        <div className="card-statistic-5">
                                            <div className="info-box7-block">
                                                <div className="row ">
                                                    <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                                                        <div className="banner-img">
                                                            <img src="assets/img/banner/4.png" alt="" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-7 col-md-7 col-sm-7 col-xs-7">
                                                        <h6 className="m-b-20 text-right">Revenue Today</h6>
                                                        <h4 className="text-right"><span>{Sdash.Revenue}</span>
                                                        </h4>
                                                    </div>
                                                </div>
                                                {/* <div id="cardChart4" /> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            {/* <div className="row">
                                <div className="col-md-12 col-lg-8 col-xl-8 ">
                                    <div className="card">
                                        <div className="card-header">
                                            <h4>Revenue Chart</h4>
                                            <div className="card-header-action">
                                                <ul className="nav nav-pills" role="tablist" id="chart-tabs">
                                                    <li className="nav-item">
                                                        <a className="nav-link active card-tab-style" data-toggle="tab" data-id={1} role="tab" aria-selected="true">2017</a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a className="nav-link card-tab-style" data-toggle="tab" data-id={2} role="tab" aria-selected="false">2018</a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a className="nav-link card-tab-style" data-toggle="tab" data-id={3} role="tab" aria-selected="false">2019</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div id="chart1" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 col-lg-4 col-xl-4">
                                    <div className="card">
                                        <div className="card-header">
                                            <h4>Project Team</h4>
                                        </div>
                                        <div className="card-body">
                                            <div className="media-list position-relative">
                                                <div className="table-responsive" id="project-team-scroll">
                                                    <table className="table table-hover table-xl mb-0">
                                                        <thead>
                                                            <tr>
                                                                <th>Project Name</th>
                                                                <th>Employees</th>
                                                                <th>Cost</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td className="text-truncate">Project X</td>
                                                                <td className="text-truncate">
                                                                    <ul className="list-unstyled order-list m-b-0">
                                                                        <li className="team-member team-member-sm"><img className="rounded-circle" src="assets/img/users/user-8.png" alt="user" data-toggle="tooltip" title data-original-title="Wildan Ahdian" /></li>
                                                                        <li className="team-member team-member-sm"><img className="rounded-circle" src="assets/img/users/user-9.png" alt="user" data-toggle="tooltip" title data-original-title="John Deo" /></li>
                                                                        <li className="team-member team-member-sm"><img className="rounded-circle" src="assets/img/users/user-10.png" alt="user" data-toggle="tooltip" title data-original-title="Sarah Smith" /></li>
                                                                        <li className="avatar avatar-sm"><span className="badge badge-primary">+3</span></li>
                                                                    </ul>
                                                                </td>
                                                                <td className="text-truncate">$8999</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="text-truncate">Project AB2</td>
                                                                <td className="text-truncate">
                                                                    <ul className="list-unstyled order-list m-b-0">
                                                                        <li className="team-member team-member-sm"><img className="rounded-circle" src="assets/img/users/user-1.png" alt="user" data-toggle="tooltip" title data-original-title="Wildan Ahdian" /></li>
                                                                        <li className="team-member team-member-sm"><img className="rounded-circle" src="assets/img/users/user-3.png" alt="user" data-toggle="tooltip" title data-original-title="John Deo" /></li>
                                                                        <li className="team-member team-member-sm"><img className="rounded-circle" src="assets/img/users/user-2.png" alt="user" data-toggle="tooltip" title data-original-title="Sarah Smith" /></li>
                                                                        <li className="avatar avatar-sm"><span className="badge badge-primary">+1</span></li>
                                                                    </ul>
                                                                </td>
                                                                <td className="text-truncate">$5550</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="text-truncate">Project DS3</td>
                                                                <td className="text-truncate">
                                                                    <ul className="list-unstyled order-list m-b-0">
                                                                        <li className="team-member team-member-sm"><img className="rounded-circle" src="assets/img/users/user-5.png" alt="user" data-toggle="tooltip" title data-original-title="Wildan Ahdian" /></li>
                                                                        <li className="team-member team-member-sm"><img className="rounded-circle" src="assets/img/users/user-9.png" alt="user" data-toggle="tooltip" title data-original-title="Sarah Smith" /></li>
                                                                        <li className="avatar avatar-sm"><span className="badge badge-primary">+4</span></li>
                                                                    </ul>
                                                                </td>
                                                                <td className="text-truncate">$9000</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="text-truncate">Project XCD</td>
                                                                <td className="text-truncate">
                                                                    <ul className="list-unstyled order-list m-b-0">
                                                                        <li className="team-member team-member-sm"><img className="rounded-circle" src="assets/img/users/user-8.png" alt="user" data-toggle="tooltip" title data-original-title="Wildan Ahdian" /></li>
                                                                        <li className="team-member team-member-sm"><img className="rounded-circle" src="assets/img/users/user-3.png" alt="user" data-toggle="tooltip" title data-original-title="John Deo" /></li>
                                                                        <li className="team-member team-member-sm"><img className="rounded-circle" src="assets/img/users/user-5.png" alt="user" data-toggle="tooltip" title data-original-title="Sarah Smith" /></li>
                                                                        <li className="avatar avatar-sm"><span className="badge badge-primary">+2</span></li>
                                                                    </ul>
                                                                </td>
                                                                <td className="text-truncate">$7500</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="text-truncate">Project Z2</td>
                                                                <td className="text-truncate">
                                                                    <ul className="list-unstyled order-list m-b-0">
                                                                        <li className="team-member team-member-sm"><img className="rounded-circle" src="assets/img/users/user-8.png" alt="user" data-toggle="tooltip" title data-original-title="Wildan Ahdian" /></li>
                                                                        <li className="team-member team-member-sm"><img className="rounded-circle" src="assets/img/users/user-10.png" alt="user" data-toggle="tooltip" title data-original-title="Sarah Smith" /></li>
                                                                        <li className="avatar avatar-sm"><span className="badge badge-primary">+3</span></li>
                                                                    </ul>
                                                                </td>
                                                                <td className="text-truncate">$8500</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="text-truncate">Project GTe</td>
                                                                <td className="text-truncate">
                                                                    <ul className="list-unstyled order-list m-b-0">
                                                                        <li className="team-member team-member-sm"><img className="rounded-circle" src="assets/img/users/user-3.png" alt="user" data-toggle="tooltip" title data-original-title="Wildan Ahdian" /></li>
                                                                        <li className="team-member team-member-sm"><img className="rounded-circle" src="assets/img/users/user-5.png" alt="user" data-toggle="tooltip" title data-original-title="Sarah Smith" /></li>
                                                                        <li className="avatar avatar-sm"><span className="badge badge-primary">+3</span></li>
                                                                    </ul>
                                                                </td>
                                                                <td className="text-truncate">$8500</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            {/* <div className="row">
                                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <h4>Revenue Chart</h4>
                                        </div>
                                        <div className="card-body">
                                            <div className="row text-center p-t-10">
                                                <div className="col-sm-4 col-6">
                                                    <h4 className="margin-0">$ 209 </h4>
                                                    <p className="text-muted"> Today's Income</p>
                                                </div>
                                                <div className="col-sm-4 col-6">
                                                    <h4 className="margin-0">$ 837 </h4>
                                                    <p className="text-muted">This Week's Income</p>
                                                </div>
                                                <div className="col-sm-4 col-6">
                                                    <h4 className="margin-0">$ 3410 </h4>
                                                    <p className="text-muted">This Month's Income</p>
                                                </div>
                                            </div>
                                            <div id="amchartLineDashboard" className="amChartHeight" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <h4>Revenue Chart</h4>
                                        </div>
                                        <div className="card-body">
                                            <div className="row text-center p-t-10">
                                                <div className="col-sm-4 col-6">
                                                    <h4 className="margin-0">$ 209 </h4>
                                                    <p className="text-muted"> Today's Income</p>
                                                </div>
                                                <div className="col-sm-4 col-6">
                                                    <h4 className="margin-0">$ 837 </h4>
                                                    <p className="text-muted">This Week's Income</p>
                                                </div>
                                                <div className="col-sm-4 col-6">
                                                    <h4 className="margin-0">$ 3410 </h4>
                                                    <p className="text-muted">This Month's Income</p>
                                                </div>
                                            </div>
                                            <div id="dumbbellPlotChart" className="amChartHeight" />
                                        </div>
                                    </div>
                                </div>
                            </div> */}

                            {/* <div className="row">
                                <div className="col-md-12 col-lg-4 col-xl-4">
                                    <div className="card">
                                        <div className="card-header">
                                            <h4>Project List</h4>
                                        </div>
                                        <div className="card-body">
                                            <div className="table-responsive" id="project-list">
                                                <table className="table table-hover table-xl mb-0">
                                                    <tbody>
                                                        <tr>
                                                            <td>Java Software</td>
                                                            <td className="text-right">
                                                                <span className="badge-outline col-purple">25%</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Landing Page</td>
                                                            <td className="text-right">
                                                                <div className="badge-outline col-red">Rejected</div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Logo Design</td>
                                                            <td className="text-right">
                                                                <div className="badge-outline col-green">Completed</div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>E-commerce Website</td>
                                                            <td className="text-right">
                                                                <span className="badge-outline col-purple">40%</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>.Net Project</td>
                                                            <td className="text-right">
                                                                <span className="badge-outline col-orange">Pending</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>PHP Website</td>
                                                            <td className="text-right">
                                                                <span className="badge-outline col-green">Completed</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Angular Website</td>
                                                            <td className="text-right">
                                                                <span className="badge-outline col-purple">98%</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>SEO Website</td>
                                                            <td className="text-right">
                                                                <span className="badge-outline col-red">Rejected</span>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 col-lg-8 col-xl-8">
                                    <div className="card">
                                        <div className="card-header">
                                            <h4>Client Details</h4>
                                        </div>
                                        <div className="card-body">
                                            <div className="tableBody" id="client-details">
                                                <div className="table-responsive">
                                                    <table className="table table-hover dashboard-task-infos">
                                                        <thead>
                                                            <tr>
                                                                <th>#</th>
                                                                <th>Name</th>
                                                                <th>Email</th>
                                                                <th>Rating</th>
                                                                <th>Project Name</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td className="table-img">
                                                                    <img src="assets/img/users/user-5.png" alt="" />
                                                                </td>
                                                                <td>John Doe</td>
                                                                <td>xyz@email.com</td>
                                                                <td>
                                                                    <i className="fas fa-star col-orange" />
                                                                    <i className="fas fa-star col-orange" />
                                                                    <i className="fas fa-star col-orange" />
                                                                    <i className="fas fa-star col-orange" />
                                                                    <i className="far fa-star col-orange" />
                                                                </td>
                                                                <td>ERP System</td>
                                                                <td>
                                                                    <a className="btn tblEditBtn" data-toggle="tooltip" title data-original-title="Edit">
                                                                        <i className="material-icons">mode_edit</i>
                                                                    </a>
                                                                    <a className="btn tblDelBtn" data-toggle="tooltip" title data-original-title="Delete">
                                                                        <i className="material-icons">delete</i>
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="table-img">
                                                                    <img src="assets/img/users/user-2.png" alt="" />
                                                                </td>
                                                                <td>Sarah Smith</td>
                                                                <td>xyz@email.com</td>
                                                                <td>
                                                                    <i className="fas fa-star col-orange" />
                                                                    <i className="fas fa-star col-orange" />
                                                                    <i className="fas fa-star-half-alt col-orange" />
                                                                    <i className="far fa-star col-orange" />
                                                                    <i className="far fa-star col-orange" />
                                                                </td>
                                                                <td>Abc Website</td>
                                                                <td>
                                                                    <a className="btn tblEditBtn" data-toggle="tooltip" title data-original-title="Edit">
                                                                        <i className="material-icons">mode_edit</i>
                                                                    </a>
                                                                    <a className="btn tblDelBtn" data-toggle="tooltip" title data-original-title="Delete">
                                                                        <i className="material-icons">delete</i>
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="table-img">
                                                                    <img src="assets/img/users/user-7.png" alt="" />
                                                                </td>
                                                                <td>Airi Satou</td>
                                                                <td>xyz@email.com</td>
                                                                <td>
                                                                    <i className="fas fa-star col-orange" />
                                                                    <i className="fas fa-star col-orange" />
                                                                    <i className="fas fa-star col-orange" />
                                                                    <i className="fas fa-star col-orange" />
                                                                    <i className="fas fa-star-half-alt col-orange" />
                                                                </td>
                                                                <td>Android App</td>
                                                                <td>
                                                                    <a className="btn tblEditBtn" data-toggle="tooltip" title data-original-title="Edit">
                                                                        <i className="material-icons">mode_edit</i>
                                                                    </a>
                                                                    <a className="btn tblDelBtn" data-toggle="tooltip" title data-original-title="Delete">
                                                                        <i className="material-icons">delete</i>
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="table-img">
                                                                    <img src="assets/img/users/user-1.png" alt="" />
                                                                </td>
                                                                <td>Ashton Cox</td>
                                                                <td>xyz@email.com</td>
                                                                <td>
                                                                    <i className="fas fa-star col-orange" />
                                                                    <i className="fas fa-star col-orange" />
                                                                    <i className="fas fa-star col-orange" />
                                                                    <i className="fas fa-star col-orange" />
                                                                    <i className="fas fa-star col-orange" />
                                                                </td>
                                                                <td>Java Website</td>
                                                                <td>
                                                                    <a className="btn tblEditBtn" data-toggle="tooltip" title data-original-title="Edit">
                                                                        <i className="material-icons">mode_edit</i>
                                                                    </a>
                                                                    <a className="btn tblDelBtn" data-toggle="tooltip" title data-original-title="Delete">
                                                                        <i className="material-icons">delete</i>
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="table-img">
                                                                    <img src="assets/img/users/user-9.png" alt="" />
                                                                </td>
                                                                <td>Cara Stevens</td>
                                                                <td>xyz@email.com</td>
                                                                <td>
                                                                    <i className="fas fa-star col-orange" />
                                                                    <i className="fas fa-star col-orange" />
                                                                    <i className="fas fa-star col-orange" />
                                                                    <i className="fas fa-star col-orange" />
                                                                    <i className="far fa-star col-orange" />
                                                                </td>
                                                                <td>Desktop App</td>
                                                                <td>
                                                                    <a className="btn tblEditBtn" data-toggle="tooltip" title data-original-title="Edit">
                                                                        <i className="material-icons">mode_edit</i>
                                                                    </a>
                                                                    <a className="btn tblDelBtn" data-toggle="tooltip" title data-original-title="Delete">
                                                                        <i className="material-icons">delete</i>
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="table-img">
                                                                    <img src="assets/img/users/user-8.png" alt="" />
                                                                </td>
                                                                <td>Angelica Ramos</td>
                                                                <td>xyz@email.com</td>
                                                                <td>
                                                                    <i className="fas fa-star col-orange" />
                                                                    <i className="fas fa-star col-orange" />
                                                                    <i className="fas fa-star col-orange" />
                                                                    <i className="fas fa-star col-orange" />
                                                                    <i className="far fa-star col-orange" />
                                                                </td>
                                                                <td>Angular App</td>
                                                                <td>
                                                                    <a className="btn tblEditBtn" data-toggle="tooltip" title data-original-title="Edit">
                                                                        <i className="material-icons">mode_edit</i>
                                                                    </a>
                                                                    <a className="btn tblDelBtn" data-toggle="tooltip" title data-original-title="Delete">
                                                                        <i className="material-icons">delete</i>
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}

                        </section>
                        {/* <div className="settingSidebar">
                            <a href="javascript:void(0)" className="settingPanelToggle"> <i className="fa fa-spin fa-cog" />
                            </a>
                            <div className="settingSidebar-body ps-container ps-theme-default">
                                <div className=" fade show active">
                                    <div className="setting-panel-header">Setting Panel
                                    </div>
                                    <div className="p-15 border-bottom">
                                        <h6 className="font-medium m-b-10">Select Layout</h6>
                                        <div className="selectgroup layout-color w-50">
                                            <label className="selectgroup-item">
                                                <input type="radio" name="value" defaultValue={1} className="selectgroup-input-radio select-layout" defaultChecked />
                                                <span className="selectgroup-button">Light</span>
                                            </label>
                                            <label className="selectgroup-item">
                                                <input type="radio" name="value" defaultValue={2} className="selectgroup-input-radio select-layout" />
                                                <span className="selectgroup-button">Dark</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="p-15 border-bottom">
                                        <h6 className="font-medium m-b-10">Sidebar Color</h6>
                                        <div className="selectgroup selectgroup-pills sidebar-color">
                                            <label className="selectgroup-item">
                                                <input type="radio" name="icon-input" defaultValue={1} className="selectgroup-input select-sidebar" />
                                                <span className="selectgroup-button selectgroup-button-icon" data-toggle="tooltip" data-original-title="Light Sidebar"><i className="fas fa-sun" /></span>
                                            </label>
                                            <label className="selectgroup-item">
                                                <input type="radio" name="icon-input" defaultValue={2} className="selectgroup-input select-sidebar" defaultChecked />
                                                <span className="selectgroup-button selectgroup-button-icon" data-toggle="tooltip" data-original-title="Dark Sidebar"><i className="fas fa-moon" /></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="p-15 border-bottom">
                                        <h6 className="font-medium m-b-10">Color Theme</h6>
                                        <div className="theme-setting-options">
                                            <ul className="choose-theme list-unstyled mb-0">
                                                <li title="white" className="active">
                                                    <div className="white" />
                                                </li>
                                                <li title="cyan">
                                                    <div className="cyan" />
                                                </li>
                                                <li title="black">
                                                    <div className="black" />
                                                </li>
                                                <li title="purple">
                                                    <div className="purple" />
                                                </li>
                                                <li title="orange">
                                                    <div className="orange" />
                                                </li>
                                                <li title="green">
                                                    <div className="green" />
                                                </li>
                                                <li title="red">
                                                    <div className="red" />
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="p-15 border-bottom">
                                        <div className="theme-setting-options">
                                            <label className="m-b-0">
                                                <input type="checkbox" name="custom-switch-checkbox" className="custom-switch-input" id="mini_sidebar_setting" />
                                                <span className="custom-switch-indicator" />
                                                <span className="control-label p-l-10">Mini Sidebar</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="p-15 border-bottom">
                                        <div className="theme-setting-options">
                                            <label className="m-b-0">
                                                <input type="checkbox" name="custom-switch-checkbox" className="custom-switch-input" id="sticky_header_setting" />
                                                <span className="custom-switch-indicator" />
                                                <span className="control-label p-l-10">Sticky Header</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="mt-4 mb-4 p-3 align-center rt-sidebar-last-ele">
                                        <a href="#" className="btn btn-icon icon-left btn-primary btn-restore-theme">
                                            <i className="fas fa-undo" /> Restore Default
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
<SuperAdminFooter/>
                </div>
            </div>


        </>
    )
}