/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import "./message.css";
import Header from '../../../components/header/header';
// import BaseUrl from "../../../../config/BaseUrl";
import axios from "axios";
import { io } from "socket.io-client";

const ChatUI = () => {
  // const BASE_URL = BaseUrl.base_url
  const [allChatList, setAllChatList] = useState([]);
  const [singleChat, setSingleChat] = useState('');
  const [message, setMessage] = useState('');
  const [roomData, setRoomData] = useState('');
  const [messageList, setMessageList] = useState([]);

  const getChatList = async () => {
    const response = await axios.get(`https://ecommerceapi4.devssh.xyz/UserApi/get-user/${JSON.parse(localStorage.getItem("seller"))?.id}`, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${localStorage.getItem('SellerToken')}`,
      },
    });
    setAllChatList(response?.data?.users);
  };

  const getChatMessageSingle = async (list) => {
    try {
      const response = await axios.get(`https://ecommerceapi4.devssh.xyz/UserApi/get-messages`, {
        params: {
          id: JSON.parse(localStorage.getItem("seller"))?.id,
          receiver: list?.id,
        },
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem("SellerToken")}`,
        }
      });
      console.log(response?.data?.results, 'messageList');
      setMessageList(response?.data?.results);
    } catch (err) {
      console.log(err?.response, 'erroraya');
    }
  };

  const getChatByRoom = async (list) => {
    try {
      const response = await axios.post(`https://ecommerceapi4.devssh.xyz/UserApi/CreateChatRoom`, {
        id: JSON.parse(localStorage.getItem("seller"))?.user?.id,
        receiver_id: list?.id,
        is_seller: true,
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("SellerToken")}`,
        },
      });
      console.log(response?.data, 'getChtByRoom');
      setRoomData(response?.data?.chat_room_data)
    } catch (err) {
      console.log(err?.response?.data?.chat_room_data, 'erroraya');
      setRoomData(err?.response?.data?.chat_room_data)
    }
  };



  const handlerSubmitChat = async (e) => {
    e.preventDefault();
    try {

      const response = await axios.post(`https://ecommerceapi4.devssh.xyz/UserApi/send-message/`, {
        id: JSON.parse(localStorage.getItem("seller"))?.user?.id,
        is_seller: true,
        receiver: roomData?.receiver_id,
        message: message,
        message_type: "text"
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            'SellerToken'
          )}`,
        },
      });
      setMessage("");
      getChatMessageSingle(singleChat);
      console.log(response, 'responseresponse')
    } catch (err) {
      console.log(err?.response?.data);
    }
  };

  useEffect(() => {
    getChatList()
  }, []);

  const SOCKET_URL = 'http://3.220.200.1:5006/';
  useEffect(() => {
    const socket = io(SOCKET_URL, {
      query: { channel: `private_chat__${singleChat?.id}` },
    });

    socket.on('connect', () => {
      console.log('Connected to server');
    });

    socket.on(`private_chat__${singleChat?.id}`, (data) => {
      console.log('Private Chat Data:', data);
      setMessageList(prevMessageList => [...prevMessageList, data?.message]);
    });

    socket.on('disconnect', () => {
      console.log('Disconnected from server');
    });

    return () => {
      socket.disconnect();
    };

  }, [messageList]);



  return (
    <>
      <Header />

      <div className="app"
        style={{
          paddingLeft: '280px',
          paddingRight: '30px',
          paddingBottom: '24px',
          paddingTop: '94px',
          width: '100%',
          position: 'relative'
        }}>
        <div id="frame">
          <div id="sidepanel">
            <div id="search">
              <label htmlFor="">
                <i className="fa fa-search" aria-hidden="true" />
              </label>
              <input type="text" placeholder="Search contacts..." />
            </div>
            <div id="contacts">
              <ul>
                {
                  allChatList?.map((list, index) => (
                    <>
                      <li key={index} className="contact" onClick={() => {
                        getChatByRoom(list);
                        getChatMessageSingle(list);
                        setSingleChat(list);
                      }}>
                        <div className="wrap">
                          <span className="contact-status away" />
                          <img className="me-2" src="https://thumbs.dreamstime.com/b/default-avatar-profile-icon-vector-social-media-user-image-182145777.jpg" alt="" />
                          <div className="meta">
                            <p className="name">{list?.username}</p>
                          </div>
                        </div>
                      </li>
                    </>
                  ))
                }
              </ul>
            </div>
          </div>
          {
            singleChat ? (
              <>
                <div className="content">
                  <div className="contact-profile d-flex align-items-center">
                    <img src="https://thumbs.dreamstime.com/b/default-avatar-profile-icon-vector-social-media-user-image-182145777.jpg" alt="" />
                    <p className="name">{singleChat?.username}</p>
                  </div>
                  <div className="messages pt-2 pb-4">
                    <ul>
                      {
                        messageList
                          ?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
                          .reverse()
                          .map((msg) => (
                            <li
                              key={msg?.id}
                              className={msg?.sender?.type === "user" ? "sent" : "replies"}
                            >
                              <img
                                src={"https://thumbs.dreamstime.com/b/default-avatar-profile-icon-vector-social-media-user-image-182145777.jpg"}
                                alt=""
                              />
                              <p>{msg?.message}</p>
                            </li>
                          ))
                      }
                    </ul>
                  </div>
                  <form className="message-input" onSubmit={handlerSubmitChat}>
                    <div className="wrap">
                      <input type="text" value={message} onChange={(e) => setMessage(e.target.value)} placeholder="Write your message..." />
                      <button className="submit">
                        <i className="fa fa-paper-plane" aria-hidden="true" />
                      </button>
                    </div>
                  </form>
                </div>
              </>
            ) : (
              <>
                <div className="d-flex justify-content-center align-items-center h-100">
                  <h1>Please Select Chat List</h1>
                </div>
              </>
            )
          }
        </div>
      </div>
    </>
  );
};
export default ChatUI;