//  baseURL of web and img

 // eslint-disable-next-line import/no-anonymous-default-export
 export default {
    //  base_url : "https://backend.fridaymart.ml/webapi",
    //  Super_Admin_Url :"https://superadminapi.fridaymart.ml/superadminApi",
    //  Admin_Url : "https://adminapi.fridaymart.ml/adminApi",
    //  image_url : (name)=> `https://backend.fridaymart.ml${name}`


     base_url : "https://ecommerceapi3.devssh.xyz/webapi",
     Super_Admin_Url :"https://ecommerceapi1.devssh.xyz/superadminApi",
     Admin_Url : "https://ecommerceapi2.devssh.xyz/adminApi",
     image_url : (name)=> `https://ecommerceapi3.devssh.xyz${name}`

     // base_url : "http://192.168.0.145:8000/webapi",
     // Super_Admin_Url :"http://192.168.0.145:8000/superadminApi",
     // Admin_Url : "http://192.168.0.145:8000/adminApi",
     // image_url : (name)=> `http://192.168.0.145:8000${name}`
     
// https://babylonadmin.creativewebssolutions.com/





     // base_url : 'https://babylon.pythonanywhere.com/webapi',
     // base_url : 'http://192.168.114.6:8000/webapi',
    // Super_Admin_Url:"https://babylon.pythonanywhere.com/superadminApi",
    // Admin_Url:"https://babylon.pythonanywhere.com/adminApi",
    // image_url : (name) => `https://babylon.pythonanywhere.com${name}`,
    //  image_url : (name) => `http://192.168.114.6:8000${name}`

}
