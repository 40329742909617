/* eslint-disable */
import React, { useState, useEffect } from "react"
import AdminHeader from "../components/adminheader/AdminHeader"
import AdminFooter from "../components/adminfooter/AdminFooter"
import { makeStyles } from "@material-ui/core/styles";
import MaterialTable from 'material-table'
import { useHistory } from "react-router";
import Swal from 'sweetalert2'
import BaseUrl from "../../../../config/BaseUrl"
import image_url from "../../../../config/BaseUrl"
import { getApiWithToken, PutApi, PostApi, DeleteApi } from "../../../../config/Api/FakeuserApi";
import { Button } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import IconButton from "@material-ui/core/IconButton";
import { view } from "react-dom-factories";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    input: {
        display: "none",
    },
}));

export default function Category() {

    const [viewCategories, setViewCategories] = useState()
    const [mainCategories, setMainCategories] = useState();
    const [subCategories, setSubCategories] = useState([]);
    const [Loader, setLoader] = useState(false)

    // Add Categories Modal States
    const [showAddCateModal, setShowAddCateModal] = useState(false);
    const [checkMainSubCate, setCheckMainSubCate] = useState("");
    console.log("checkMainSubCate = =>", checkMainSubCate)
    const handleCloseAddCateModal = () => setShowAddCateModal(false);
    const handleShowAddCateModal = (value) => {
        setShowAddCateModal(true);
        setCheckMainSubCate(value)
    }
    const classes = useStyles();
    const [AddCategory, SetAddCategory] = useState("")
    console.log("AddCategory =>", AddCategory)
    const [AddPercentage, SetAddPercentage] = useState("")
    const [AddPercentageErr, SetAddPercentageErr] = useState("")
    const [AddCategoryErr, SetAddCategoryErr] = useState("")

    // Add Sub Category Model States
    const [showAddSubCateModal, setShowAddSubCateModal] = useState(false);
    const [subCateName, setSubCatename] = useState();
    const [subCateId, setSubCateId] = useState();
    const handleCloseAddSubCateModal = () => setShowAddSubCateModal(false);
    const handleShowAddSubCateModal = (mainName, mainId) => {
        setShowAddSubCateModal(true);
        setSubCatename(mainName);
        setSubCateId(mainId);
    }

    // Edit Category Modal States
    const [showEditCateModal, setShowEditCateModal] = useState(false);
    const [checkEditSubCate, setCheckEditSubCate] = useState("");

    console.log("values is ", checkEditSubCate)

    const handleCloseEditCateModal = () => setShowEditCateModal(false);
    const handleShowEditCateModal = (value) => {
        setShowEditCateModal(true);
        setCheckEditSubCate(value)
        // console.log("cateName =>", value);
        console.log("this edit", checkEditSubCate, "and", value)
    };
    const [EditCategoryName, SetEditCategoryName] = useState("")
    // console.log("EditCategoryName = =>", EditCategoryName)
    const [EditPercentage, SetEditPercentage] = useState("")
    const [AddEPercentageErr, SetAddEPercentageErr] = useState("")
    const [AddECategoryErr, SetAddECategoryErr] = useState("")
    const [EditId, SetEditId] = useState("")




    //DELETR STATE
    const [DeleteId, setDeleteId] = useState("")

    // GET token from local storage
    const Token = localStorage.getItem('Token');

    // VIEW ALLCategories API

    const ViewAllCategories = () => {
        setLoader(true)
        let cateObj = [];
        let counter = 0;
        getApiWithToken(`${BaseUrl.Admin_Url}/GetCategories`, "", Token)
            .then(({ data }) => {
                setLoader(false);
                if (data.status) {
                    data.data.map((v, i) => {
                        counter++;
                        cateObj.push({ id: counter, actualId: v.id, category: v.CategoryName, percentage: v.Percentage })
                    })
                    setViewCategories(cateObj)

                    // data.data.map((value, index) => {
                    //     getApiWithToken(`${BaseUrl.Admin_Url}/GetSubcategories?categoryName=${value.CategoryName}`, "", Token)
                    //         .then(({ data }) => {
                    //             if (data.status === true && data.data.length !== 0) {
                    //                 data.data.map((v, i) => {
                    //                     counter++;
                    //                     cateObj.push({ id: counter, actualId: v.id, category: v.myCategory, percentage: v.Percentage, parentId: viewCategories.find(i => i.actualId == value.id).id })
                    //                 })
                    //                 setViewCategories(cateObj)
                    //             }
                    //         }).catch((e) => {
                    //             console.log("e => ", e)
                    //         })

                    //     // setViewCategories(cateObj)
                    // })
                } else {
                    Swal.fire({
                        title: "Opps",
                        text: data.message,
                        icon: "error"
                    })
                }
            }).catch(function (e) {
                // $(".loader").hide();
                Swal.fire({
                    title: "Opps",
                    text: e.message,
                    icon: "error"
                })
            })
    }

    useEffect(() => {
        ViewAllCategories()
    }, [])

    // Add Main Category:

    const addCategory = () => {

        const isValid = formValidation();
        if (isValid == true) {
            setLoader(true);
            var formdata = new FormData();

            if (checkMainSubCate === "main") {
                formdata.append("CategoryName", AddCategory);
                formdata.append("Percentage", AddPercentage);

                PostApi(`${BaseUrl.Admin_Url}/GetCategories`, formdata, Token)
                    .then(({ data }) => {
                        setLoader(false);
                        if (data.status) {
                            console.log("AddCategory==>", data)
                            SetAddPercentage("")
                            SetAddCategory("")
                            ViewAllCategories()
                            handleCloseAddCateModal()

                            Swal.fire({
                                title: "success",
                                text: data.message,
                                icon: "success"
                            })
                        } else {
                            // $(".loader").hide();
                            Swal.fire({
                                title: "Opps",
                                text: data.message,
                                icon: "error"
                            })
                        }
                    }).catch(function (e) {
                        // $(".loader").hide();
                        Swal.fire({
                            title: "Opps",
                            text: e.message,
                            icon: "error"
                        })
                    })
            }
            else {
                formdata.append("id", subCateId);
                formdata.append("SubcategoryName", AddCategory);
                formdata.append("Percentage", AddPercentage);

                PostApi(`${BaseUrl.Admin_Url}/GetSubcategories`, formdata, Token)
                    .then(({ data }) => {
                        setLoader(false);
                        if (data.status) {
                            console.log("AddCategory==>", data)
                            setSubCateId()

                            // SetAddPercentage("")
                            // SetAddCategory("")
                            // ViewAllCategories()
                            // handleCloseAddCateModal()

                            Swal.fire({
                                title: "success",
                                text: data.message,
                                icon: "success"
                            })
                        }
                        else {
                            // $(".loader").hide();
                            Swal.fire({
                                title: "Opps",
                                text: data.message,
                                icon: "error"
                            })
                        }
                    }).catch(function (e) {
                        // $(".loader").hide();
                        Swal.fire({
                            title: "Opps",
                            text: e.message,
                            icon: "error"
                        })
                        console.log("e =>", e);
                    })

                SetAddCategory();
                SetAddPercentage();
                setSubCategories([]);
                setSubCateId();
                setCheckMainSubCate();
                handleCloseAddCateModal();
                handleCloseAddSubCateModal();
            }
        } else {
            formValidation()
        }
    }

    // Add Main Category Validation:
    const formValidation = () => {
        const AddCategoryErr = {};
        const AddPercentageErr = {};


        let isValid = true;

        if (AddCategory.length == 0) {
            AddCategoryErr.firstNamenull = "Required Category";
            isValid = false;
        }

        if (AddPercentage.length == 0) {
            AddPercentageErr.firstNamenull = "Required Percentage";
            isValid = false;
        }

        SetAddPercentageErr(AddPercentageErr);
        SetAddCategoryErr(AddCategoryErr);

        return isValid;
    }

    // View Sub Category

    const viewSubCategory = (name, id) => {
        console.log("my================", id)
        console.log("myname================", name)
        setSubCategories([])
        // console.log("addSubCategory = >", addSubCategory);
        getApiWithToken(`${BaseUrl.Admin_Url}/GetSubcategories?categoryName=${name}`, "", Token)
            .then(({ data }) => {
                if (data.status === true && data.data.length !== 0) {
                    console.log("data =>", data.data);
                    setSubCategories(data.data);
                }
            }).catch((e) => {
                console.log("e => ", e)
            })

    }

    const viewSub1Category = (name, id) => {
        handleShowAddSubCateModal(name, id)
        console.log("id========================", id)
        console.log("name========================", name)
        viewSubCategory(name, id)
    }

    const closeModal = () => {
        setSubCategories([])
        handleCloseAddSubCateModal()
    }

    const deleteCategory = (index, value) => {
        // handleDeleteCate("sub");
        Delete(subCategories[index].id, value);
    }

    // DELETE API

    const Delete = (id, val) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You want to delete it",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                DeleteCategory(id, val)
            }
        })

    }

    const DeleteCategory = (id, val) => {

        setLoader(true);

        let checkMainSub = val;
        var formdata = new FormData();

        formdata.append("id", id);

        if (checkMainSub === "main") {
            // console.log("Delete =>", checkMainSub)
            DeleteApi(`${BaseUrl.Admin_Url}/GetCategories`, formdata, Token)
                .then(({ data }) => {
                    setLoader(false);
                    if (data.status) {
                        console.log("dELETECAT==>", data)
                        ViewAllCategories()
                        handleCloseEditCateModal()

                        Swal.fire({
                            title: "success",
                            text: data.message,
                            icon: "success"
                        })
                    } else {
                        // $(".loader").hide();
                        Swal.fire({
                            title: "Opps",
                            text: data.message,
                            icon: "error"
                        })
                    }
                }).catch(function (e) {
                    // $(".loader").hide();
                    Swal.fire({
                        title: "Opps",
                        text: e.message,
                        icon: "error"
                    })
                })
            checkMainSub = "";
        }
        else {
            console.log("Delete =>", checkMainSub)
            DeleteApi(`${BaseUrl.Admin_Url}/GetSubcategories`, formdata, Token)
                .then(({ data }) => {
                    setLoader(false);
                    if (data.status) {
                        console.log("dELETECAT==>", data)
                        // ViewAllCategories()
                        // handleCloseEditCateModal()
                        handleCloseAddSubCateModal()
                        Swal.fire({
                            title: "success",
                            text: data.message,
                            icon: "success"
                        })
                    } else {
                        // $(".loader").hide();
                        Swal.fire({
                            title: "Opps",
                            text: data.message,
                            icon: "error"
                        })
                    }
                }).catch(function (e) {
                    // $(".loader").hide();
                    Swal.fire({
                        title: "Opps",
                        text: e.message,
                        icon: "error"
                    })
                })
            checkMainSub = "";
        }
    }

    // Edit Category Api

    const UpdateCategory = () => {
        const isValid = UpdateformValidation();
        if (isValid == true) {
            setLoader(true);
            var formdata = new FormData();

            if (checkEditSubCate === "main") {

                formdata.append("id", EditId);
                formdata.append("CategoryName", EditCategoryName);
                formdata.append("Percentage", EditPercentage);

                PutApi(`${BaseUrl.Admin_Url}/GetCategories`, formdata, Token)
                    .then(({ data }) => {
                        setLoader(false);
                        if (data.status) {
                            console.log("edit category main==>", data)
                            ViewAllCategories()
                            handleCloseEditCateModal()



                            Swal.fire({
                                title: "success",
                                text: data.message,
                                icon: "success"
                            })
                        } else {
                            // $(".loader").hide();
                            Swal.fire({
                                title: "Opps",
                                text: data.message,
                                icon: "error"
                            })
                        }
                    }).catch(function (e) {
                        // $(".loader").hide();
                        Swal.fire({
                            title: "Opps",
                            text: e.message,
                            icon: "error"
                        })
                    })
            }
            else {

                console.log("edit subbbbbb")

                formdata.append("id", EditId);
                formdata.append("SubcategoryName", EditCategoryName);
                formdata.append("Percentage", EditPercentage);
                PutApi(`${BaseUrl.Admin_Url}/GetSubcategories`, formdata, Token)
                    .then(({ data }) => {
                        setLoader(false);
                        if (data.status) {
                            console.log("editCategorysub==>", data)


                            Swal.fire({
                                title: "success",
                                text: data.message,
                                icon: "success"
                            })
                        }
                        else {
                            // $(".loader").hide();
                            Swal.fire({
                                title: "Opps",
                                text: data.message,
                                icon: "error"
                            })
                        }
                    }).catch(function (e) {
                        // $(".loader").hide();
                        Swal.fire({
                            title: "Opps",
                            text: e.message,
                            icon: "error"
                        })
                        console.log("e =>", e);
                    })

                    ViewAllCategories()
                    handleCloseEditCateModal()
                    handleCloseAddSubCateModal()
                    setCheckEditSubCate()
                    SetEditCategoryName()
                    SetEditPercentage()
                    SetEditId()




            }



        } else {
            UpdateformValidation()
        }
    }




    const UpdateformValidation = () => {
        const AddECategoryErr = {};
        const AddEPercentageErr = {};


        let isValid = true;

        if (EditCategoryName.length == 0) {
            AddECategoryErr.firstNamenull = "Required Category";
            isValid = false;
        }

        if (EditPercentage.length == 0) {
            AddEPercentageErr.firstNamenull = "Required Percentage";
            isValid = false;
        }

        SetAddEPercentageErr(AddEPercentageErr);
        SetAddECategoryErr(AddECategoryErr);

        return isValid;
    }


    // Add Sub Cate 1:
    const addSubCate1 = () => {
        // console.log("subCateId =>", subCateId);
        handleShowAddCateModal("sub")
    }



    const EditSubCate1 = (v) => {
        console.log("v", v)
        // console.log("i",i)


        SetEditCategoryName(v.myCategory)
        SetEditPercentage(v.Percentage)
        SetEditId(v.id)
        handleShowEditCateModal("sub")
    }

    return (
        <>
            {Loader ? <div class="loader"></div> : null}
            <div id="app">
                <AdminHeader />
                <div class="main-wrapper main-wrapper-1">
                    <div className="main-content">
                        <section className="section">
                            <ul className="breadcrumb breadcrumb-style ">
                                <li className="breadcrumb-item">
                                    <h4 className="page-title m-b-0"> Subadmin</h4>
                                </li>
                                <li className="breadcrumb-item active">View Category</li>
                                <div class="row">
                                    <div class="col-md-12  text-right">
                                        <button onClick={() => handleShowAddCateModal("main")} type="button" class="btn btn-primary">Add Category</button>
                                    </div>
                                </div>
                            </ul>
                            <div style={{ maxWidth: '100%' }}>
                                <MaterialTable
                                    title="Categories"
                                    data={viewCategories}

                                    columns={[
                                        { title: 'CATEGORY', field: 'category' },
                                        { title: 'PERCENTAGE', field: 'percentage', type: 'numeric' },
                                    ]}
                                    actions={[
                                        rowData => ({
                                            icon: 'view_list',
                                            tooltip: 'View Sub Category',
                                            onClick: () => {
                                                handleShowAddSubCateModal(rowData.category, rowData.actualId)

                                                viewSubCategory(rowData.category, rowData.actualId)
                                            }

                                        }),

                                        rowData => ({
                                            icon: 'edit',
                                            tooltip: 'Edit Category',
                                            onClick: () => {
                                                handleShowEditCateModal("main")
                                                SetEditCategoryName(rowData.category)
                                                SetEditPercentage(rowData.percentage)
                                                SetEditId(rowData.actualId)
                                            }

                                        }),

                                        rowData => ({
                                            icon: 'delete',
                                            tooltip: 'Delete Category',
                                            onClick: () => {
                                                Delete(rowData.actualId, "main")
                                            }
                                        })
                                    ]}
                                    options={{
                                        maxBodyHeight: 400,
                                        overflowX: 'x-scroll',
                                        actionsColumnIndex: 7,
                                        headerStyle: {
                                            backgroundColor: '#222',
                                            color: "white"
                                        }
                                    }}
                                />
                            </div>
                        </section>
                    </div>
                    <AdminFooter />
                </div>
            </div>
            {/* Add CATEGORY MODAL  */}
            <Modal
                size="md"
                aria-labelledby="example-modal-sizes-title-lg"
                show={showAddCateModal}
                onHide={handleCloseAddCateModal}>
                <Modal.Header>
                    <Modal.Title>Add Category</Modal.Title>
                    <i onClick={handleCloseAddCateModal} style={{ fontSize: "24px" }} className=" fa-10X fas fa-window-close"></i>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="form-row text-center " style={{ display: "block" }}>
                        </div>
                        <div className="form-row mt-4">
                            <div className="form-group col-md-12">
                                <label htmlFor="inputAR">Add Category</label>
                                <input value={AddCategory} onChange={e => SetAddCategory(e.target.value)} type="text" className="form-control" id="inputAR" placeholder=" Add Category" />
                                <div>
                                    {Object.keys(AddCategoryErr).map((key) => {
                                        return <div style={{ color: "red" }}>{AddCategoryErr[key]}</div>
                                    })}
                                </div>
                            </div>
                            <div className="form-group col-md-12">
                                <label htmlFor="inputP">Add Percentage</label>
                                <input value={AddPercentage} onChange={(e) => SetAddPercentage(e.target.value)} type="number" className="form-control" id="inputP" placeholder=" Add Percentage" />
                                <div>
                                    {Object.keys(AddPercentageErr).map((key) => {
                                        return <div style={{ color: "red" }}>{AddPercentageErr[key]}</div>
                                    })}
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleCloseAddCateModal}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={() => addCategory()} >
                        Add
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* View CATEGORY MODAL  */}
            <Modal
                size="md"
                aria-labelledby="example-modal-sizes-title-lg"
                show={showAddSubCateModal}
                onHide={handleCloseAddCateModal}>
                <Modal.Header>
                    <Modal.Title style={{ color: "black" }}>{subCateName}  <i onClick={addSubCate1} style={{ marginLeft: 6, marginRight: 6, fontSize: 16 }} class="fas fa-plus"></i></Modal.Title>
                    <i onClick={handleCloseAddSubCateModal} style={{ fontSize: "24px" }} className=" fa-10X fas fa-window-close"></i>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        {subCategories.map((v, i) => {
                            return (
                                <div className="row" style={{ marginBottom: 5 }}>
                                    <span className="col-8" style={{ fontSize: 18, color: "black" }}>{v.myCategory}</span>
                                    <span className="col-4" style={{ color: "black" }}><i onClick={() => viewSub1Category(v.myCategory, v.id)} style={{ marginLeft: 10, marginRight: 10, fontSize: 16 }} class="fas fa-eye"></i>  <i onClick={() => deleteCategory(i, "sub")} style={{ marginLeft: 10, marginRight: 10, fontSize: 16 }} class="fas fa-trash-alt"></i>  <i onClick={() => EditSubCate1(v)} style={{ marginLeft: 10, fontSize: 16 }} class="fas fa-pencil-alt"></i></span>
                                </div>
                            )
                        })}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => closeModal()}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Edit CATEGORY MODAL  */}
            <Modal
                size="md"
                aria-labelledby="example-modal-sizes-title-lg"
                show={showEditCateModal}
                onHide={handleCloseEditCateModal}>
                <Modal.Header   >
                    <Modal.Title>Edit Category</Modal.Title>
                    <i onClick={handleCloseEditCateModal} style={{ fontSize: "24px" }} className=" fa-10X fas fa-window-close"></i>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="form-row mt-4">
                            <div className="form-group col-md-12">
                                <label htmlFor="inputAR">Edit Category</label>
                                <input value={EditCategoryName} onChange={e => SetEditCategoryName(e.target.value)} type="text" className="form-control" id="inputAR" placeholder=" Add Category" />
                                <div>
                                    {Object.keys(AddECategoryErr).map((key) => {
                                        return <div style={{ color: "red" }}>{AddECategoryErr[key]}</div>
                                    })}
                                </div>
                                <p value={EditCategoryName} onChange={e => SetEditCategoryName(e.target.value)}></p>
                            </div>
                            <div className="form-group col-md-12">
                                <label htmlFor="inputpr">Edit Percentage</label>
                                <input value={EditPercentage} onChange={(e) => SetEditPercentage(e.target.value)} type="text" className="form-control" id="inputpr" placeholder=" Add Category" />
                                <div>
                                    {Object.keys(AddEPercentageErr).map((key) => {
                                        return <div style={{ color: "red" }}>{AddEPercentageErr[key]}</div>
                                    })}
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleCloseEditCateModal}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={() => UpdateCategory()}   >
                        Update
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}