
/* eslint-disable */
import React, { useState, useEffect, useRef } from "react"
import Footer from '../../../components/footer/footer'
import { useHistory } from "react-router"
import Header from '../../../components/header/header'
import '../newAddproduct/newAddproduct.css'
// import JoditEditor from "jodit-react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import Swal from 'sweetalert2';
import BaseUrl from "../../../../../src/config/BaseUrl"
import image_url from "../../../../../src/config/BaseUrl"
import {
  BrowserRouter as Router,
  Switch,
  useLocation
} from "react-router-dom";
import { getApi, getApiWithToken, PutApi, PostApi, DeleteApi } from "../../../../config/Api/FakeuserApi";

export default function ViewProductDetails(props) {

  // ============== BASIC INFO ==============

  const [productName, setProductName] = useState("");
  console.log("productName =>", productName)
  const [productNameErr, setProductNameErr] = useState({});

  const [VideoUrl, SetVideoUrl] = useState("")
  const [BrandId, SetBrandId] = useState("")

  // Suggestion States:

  const [showCategory, setShowCategory] = useState("");
  const [categories, setCategories] = useState([]);
  const [category, setcategory] = useState("")
  const [categoriesId, setCategoriesId] = useState([]);
  console.log("categoriesId =>", categoriesId)

  const [selectCateAttr, setSelectCateAttr] = useState(false);
  const [categoryChecker1, setCategoryChecker1] = useState(true);
  const [categoryChecker2, setCategoryChecker2] = useState(true);
  const [categoryChecker3, setCategoryChecker3] = useState(true);
  const [categoryChecker4, setCategoryChecker4] = useState(true);
  const [categoryChecker5, setCategoryChecker5] = useState(true);
  const [categoryChecker6, setCategoryChecker6] = useState(false);
  const [categoryChecker7, setCategoryChecker7] = useState(false);
  const [categoryChecker8, setCategoryChecker8] = useState(false);

  const [showDropdown, setShowDropdown] = useState(false);
  const [showSubDropdown1, setShowSubDropdown1] = useState(false);
  const [showSubDropdown2, setShowSubDropdown2] = useState(false);
  const [showSubDropdown3, setShowSubDropdown3] = useState(false);
  const [showSubDropdown4, setShowSubDropdown4] = useState(false);
  const [showSubDropdown5, setShowSubDropdown5] = useState(false);
  const [showSubDropdown6, setShowSubDropdown6] = useState(false);
  const [showSubDropdown7, setShowSubDropdown7] = useState(false);
  const [showSubDropdown8, setShowSubDropdown8] = useState(false);

  const [mainCategory, setMainCategory] = useState([]);
  const [subCategory1, setSubCategory1] = useState([]);
  const [subCategory2, setSubCategory2] = useState([]);
  const [subCategory3, setSubCategory3] = useState([]);
  const [subCategory4, setSubCategory4] = useState([]);
  const [subCategory5, setSubCategory5] = useState([]);
  const [subCategory6, setSubCategory6] = useState([]);
  const [subCategory7, setSubCategory7] = useState([]);
  const [subCategory8, setSubCategory8] = useState([]);

  const [showCategoryErr, setShowCategoryErr] = useState({});
  const [container, setContainer] = useState(false);
  const [cateSelection, setCateSelection] = useState(false);
  const [splitCategoryArr, setSplitCateArr] = useState([]);
  console.log("Split cateSelection => ", cateSelection);

  // Editor States:
  const [selectedFiles, setSelectedFiles] = useState([]);
  console.log("selectedFiles =>", selectedFiles)

  const [GetAllAIvendors, SetGetAIllAvendors] = useState([])

  const [ShortDescription, SetShortDescription] = useState("")
  const [LongDescription, SetLongDescription] = useState("")
  const [EnglishDescription, SetEnglishDescription] = useState("")
  const [WhatsintheBox, SetWhatsintheBox] = useState("")

  console.log("ShortDescription", ShortDescription)

  useEffect(() => {
    var myeditor = EditorState.createWithContent(
      ContentState.createFromBlockArray(

        convertFromHTML(ShortDescription)
      ));
    setEditorState1(myeditor);
  }, [ShortDescription])

  useEffect(() => {
    var myeditor2 = EditorState.createWithContent(
      ContentState.createFromBlockArray(

        convertFromHTML(LongDescription)
      ));
    setEditorState2(myeditor2);
  }, [LongDescription])

  useEffect(() => {
    var myeditor3 = EditorState.createWithContent(
      ContentState.createFromBlockArray(

        convertFromHTML(EnglishDescription)
      ));
    setEditorState3(myeditor3);
  }, [EnglishDescription])




  const [editorState1, setEditorState1] = useState(

    ""
  );




  const [editorState2, setEditorState2] = useState(
    ""
  );

  const [editorState3, setEditorState3] = useState(
    () => EditorState.createWithContent(
      ContentState.createFromBlockArray(
        // convertFromHTML(EnglishDescription)
        convertFromHTML("<p><strong>1 Wax Heater</strong></p>" +
          "<p><strong>1 Lock</strong></p>" +
          "<p><em>1 Ekstra tin can.</em></p>" +
          "<p><em>Usermanual</em></p>")
      )
    )
  );

  // ============== PRICE & STOCK ==============

  const [imgLoader, setImgLoader] = useState(false);
  const [verifyImgObj, setVerifyImgObj] = useState([]);
  const [Quantity, SetQuantity] = useState("")

  const [PromotionStartDate, SetPromotionStartDate] = useState("")
  const [PromotionEndDate, SetPromotionEndDate] = useState("")
  const [PromotionStartTime, SetPromotionStartTime] = useState("")
  const [PromotionEndTime, SetPromotionEndTime] = useState("")

  const [price, setPrice] = useState("");
  const [specialPrice, setSpecialPrice] = useState(0);
  const [sppriceErr, setspPriceErr] = useState({});
  const [loaderActive , setLoaderActive] = useState(true)


  const [priceErr, setPriceErr] = useState({});

  // ============== SERVICE & DELIVERY ==============

  const [weight, setWeight] = useState(0);
  const [length, setLength] = useState(0);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const [SellerId, SetSellerId] = useState(3)
  const [DangerousGoods, SetDangerousGoods] = useState("")

  const [Warranty, SetWarranty] = useState("")
  const [WarrantyPeriod, SetWarrantyPeriod] = useState("")

  const [weightErr, setWeightErr] = useState({});
  const [lengthErr, setLengthErr] = useState({});
  const [widthErr, setWidthErr] = useState({});
  const [heightErr, setHeightErr] = useState({});

  const Token = localStorage.getItem('SellerToken');

  const location = useLocation()
  // console.log("data===>", location)

  useEffect(() => {
    ViewProductData(location.state.productid)
  }, []);

  const history = useHistory();
  const [Step1, setStep1] = useState(true)
  const [Step2, setStep2] = useState(false)
  const [Step3, setStep3] = useState(false)
  const [Step4, setStep4] = useState(false)
  const [Loader, setLoader] = useState(false)
  //   const [found, setfound] = useState("")
  // console.log("found==>",found)

  const NextStep = (val) => {
    if (val == 1) {
      setStep1(false)
      setStep2(true)
      setStep3(false)
      setStep4(false)
    } else if (val == 2) {
      setStep1(false)
      setStep2(false)
      setStep3(true)
      setStep4(false)
    } else if (val == 3) {
      setStep1(false)
      setStep2(false)
      setStep3(false)
      setStep4(true)
    } else if (val == 4) {
      setStep1(false)
      setStep2(false)
      setStep3(false)
      setStep4(true)
    }
  }
  const PreviousStep = (val) => {
    if (val == 1) {
      setStep1(true);
      setStep2(false)
      setStep3(false)
      setStep4(false)
    } else if (val == 2) {
      setStep1(true)
      setStep2(false)
      setStep3(false)
      setStep4(false)
    } else if (val == 3) {
      setStep1(false)
      setStep2(true)
      setStep3(false)
      setStep4(false)
    } else if (val == 4) {
      setStep1(false)
      setStep2(false)
      setStep3(true)
      setStep4(false)
    }
  }
  const CurruntStep = (val) => {
    if (val == 1) {
      setStep1(true);
      setStep2(false)
      setStep3(false)
      setStep4(false)
    } else if (val == 2) {
      setStep1(false)
      setStep2(true)
      setStep3(false)
      setStep4(false)
    } else if (val == 3) {
      setStep1(false)
      setStep2(false)
      setStep3(true)
      setStep4(false)
    } else if (val == 4) {
      setStep1(false)
      setStep2(false)
      setStep3(false)
      setStep4(true)
    }
  }

  // view specific data

  const ViewProductData = (id) => {
    setLoader(true);
    var url = [];
    getApiWithToken(`${BaseUrl.base_url}/GetDetailsofProduct?id=${id}`, "", Token)
      .then(({ data }) => {
        setLoaderActive(false)

        setLoader(false);
        if (data.status) {
          console.log("data", data)
          SetGetAIllAvendors(data)
          // data.ImageData.map((e) => {
          //   url.push(e.image)
          //   console.log(e.image)
          // })
          let viewData = data.ProductDetails
          // Basic Info
          setcategory(viewData.Category)
          setCategoriesId(viewData.Categoryid)
          setProductName(viewData.Product_Name)
          SetVideoUrl(viewData.VideoUrl)
          SetBrandId(viewData.Brand_id)
          // Detail Des
          SetShortDescription(viewData.Short_Description)
          SetLongDescription(viewData.long_Description)
          SetEnglishDescription(viewData.English_Description)
          SetWhatsintheBox(viewData.Whats_in_the_Box)
          // Price & Stock
          SetQuantity(viewData.Quantity)
          setPrice(viewData.Price)
          setSpecialPrice(viewData.Special_Price)
          SetPromotionStartDate(viewData.Promotion_Start_Date)
          SetPromotionEndDate(viewData.Promotion_End_Date)
          SetPromotionStartTime(viewData.Promotion_Start_Time)
          SetPromotionEndTime(viewData.Promotion_End_Time)
          // Service & Delivery
          setWeight(viewData.Package_Weight)
          setLength(viewData.Lenght)
          setWidth(viewData.Width)
          setHeight(viewData.Height)
        } else {
          // $(".loader").hide();
          Swal.fire({
            title: "Opps",
            text: data.message,
            icon: "error"
          })
        }
      }).catch(function (e) {
        setLoaderActive(false)

        // $(".loader").hide();
        Swal.fire({
          title: "Opps",
          text: e.message,
          icon: "error"
        })
      })
    // setSelectedFiles(url)
  }

  if (GetAllAIvendors.length == 0) return <div className="loader" />

  // ============== BASIC INFO ==============

  // Not Null Validation fn:

  const onSubmitBasicinfo = (e) => {
    e.preventDefault();
    const isValid = formValidationBasicinfo();
    if (isValid == true) {
      NextStep(1);
    }
    console.log(productName);
  }

  const formValidationBasicinfo = () => {
    const productNameErr = {};
    const categoryErr = {};
    const showCategoryErr = {};

    let isValid = true;

    if (productName.trim().length == 0) {
      productNameErr.productNameNull = "Product name is required";
      isValid = false;
    }
    if (showCategory.trim().length == "") {
      showCategoryErr.categoryNull = "Category required";
      isValid = false;
    }

    setProductNameErr(productNameErr);
    setShowCategoryErr(showCategoryErr);

    return isValid;
  }

  // --------- Category Suggestion API ---------

  // On hover Suggestion:

  function MouseOver(event) {
    event.target.style.cursor = "pointer";
  }

  const displaySuggestionBox = () => {

    setCateSelection(true)
    console.log("cateSelection===>",cateSelection)
    let cateArr = category.split("/");

    console.log("category display =>", category)
    let a = cateArr.slice(0, -1)
    setSplitCateArr(a);
    // let matchValue = cateArr.find((ele) => ele == "Home Appliances");

    // ----------------- Main Category -----------------

    setContainer(true);

    getApi(`${BaseUrl.base_url}/GetCategories`, "")
      .then((data) => {
        let cateData = data.data.data;
        console.log("cateData main =>", cateData)
        let found;
        cateData.map((v, i) => a[0] == (v.CategoryName) ? found = v.CategoryName : null);
        console.log("cateArr[0] =>", a[0])
        console.log("Main Category Found 1 =>", found)

        setTimeout(() => {
          console.log("settimeout==>", found)
          let targetFound = document.getElementById(found);
          targetFound.style.backgroundColor = "lightblue";
          console.log("targetFound 1 =>", targetFound)
        }, 300)
        setMainCategory(cateData);
        // let matchValue = cateData.map((v, i) => cateArr.find((ele) => ele == v.CategoryName));
        // let matchValue = cateData.map((v, i) => (cateArr[0] == v.CategoryName) ? found = v.CategoryName : null);
        // console.log("matchValue =>", matchValue);
        // console.log(matchValue.map((v,i) => v !== null))
      }).catch((e) => {
        console.log("error===> 1", e)
      })
    setShowDropdown(true);
    setSelectCateAttr(true);

    // ----------------- Sub Category: 1 -----------------
    // console.log("cateArr[000]",cateArr[0])
    if (a.length > 1) {



      getApi(`${BaseUrl.base_url}/GetSubcategories?categoryName=${a[0]}`, "")
        .then((data) => {

          let cateData = data.data.data;
          console.log("cateData 0 =>", cateData)
          let found;
          cateData.map((v, i) => a[1] == (v.myCategory) ? found = v.myCategory : null);
          // console.log("cateArr[1] =>", cateArr[1])
          // console.log("vvvvvvvvvvv 2====>",v)
          console.log("Sub Category 0 Found =>", found)
          setTimeout(() => {
            console.log("set time 2", found)
            let targetFound = document.getElementById(found);
            targetFound.style.backgroundColor = "lightblue";
            console.log("targetFound 0 =>", targetFound)
          }, 300)
          if (cateData[0].myCategory !== undefined) {
            setCategoryChecker1(true);
            setSubCategory1(cateData);
          }
          else {
            setCategoryChecker1(false);
            setCategoryChecker2(false);
          }
        }).catch((e) => {
          console.log("error===> 2", e)
        })

      setShowSubDropdown1(true);
      console.log(subCategory1);
    }

    // ----------------- Sub Category: 2 -----------------
    if (a.length > 2) {

      console.log("1================>", a.length)
      getApi(`${BaseUrl.base_url}/GetSubcategories?categoryName=${a[1]}`, "")
        .then((data) => {
          let cateData = data.data.data;
          console.log("cateData 1 =>", cateData)
          var found;
          cateData.map((v, i) => a[2] == (v.myCategory) ? found = v.myCategory : null);
          console.log("Sub Category 1 Found =>", found)
          setTimeout(() => {
            let targetFound = document.getElementById(found);
            targetFound.style.backgroundColor = "lightblue";
            console.log("targetFound 1 =>", targetFound)
          }, 300)

          if (cateData[0].myCategory !== undefined) {
            setCategoryChecker2(true);
            setSubCategory2(cateData);
          }
          else {
            setCategoryChecker2(false);
          }
        }).catch((e) => {
          console.log("error===> 3", e)
        })
      setShowSubDropdown2(true);
      console.log(subCategory1);
    }

    // ----------------- Sub Category: 3 -----------------
    if (a.length > 3) {


      getApi(`${BaseUrl.base_url}/GetSubcategories?categoryName=${a[2]}`, "")
        .then((data) => {
          let cateData = data.data.data;
          console.log("cateData 2 =>", cateData)

          var found;
          cateData.map((v, i) => a[3] == (v.myCategory) ? found = v.myCategory : null);
          console.log("Sub Category 2 Found =>", found)
          setTimeout(() => {
            let targetFound = document.getElementById(found);
            // targetFound.style.backgroundColor = "lightblue";
            console.log("targetFound 2 =>", targetFound)
          }, 300)
          if (cateData[0].myCategory !== undefined) {
            setCategoryChecker3(true);
            setSubCategory3(cateData);
          }
          else {
            setCategoryChecker3(false);
          }
        }).catch((e) => {
          console.log("error===>", e)
        })
      setShowSubDropdown3(true);
      console.log(subCategory1);
    }

    // ----------------- Sub Category: 4 -----------------
    if (a.length > 4) {

      console.log("cons 3=======", cateArr.length)

      getApi(`${BaseUrl.base_url}/GetSubcategories?categoryName=${a[3]}`, "")
        .then((data) => {
          let cateData = data.data.data;
          console.log("cateData 3 =>", cateData)

          var found;
          cateData.map((v, i) => a[4] == (v.myCategory) ? found = v.myCategory : null);
          console.log("Sub Category 3 Found =>", found)
          setTimeout(() => {
            let targetFound = document.getElementById(found);
            targetFound.style.backgroundColor = "lightblue";
            console.log("targetFound 3 =>", targetFound)
          }, 300)
          if (cateData[0].myCategory !== undefined) {
            setCategoryChecker4(true);
            setSubCategory4(cateData);
          }
          else {
            setCategoryChecker4(false);
          }
        }).catch((e) => {
          console.log("error===>", e)
        })
      setShowSubDropdown4(true);
      console.log(subCategory1);
    }
    // ----------------- Sub Category: 5 -----------------
    if (a.length > 5) {

      console.log("cons 4=======")
      getApi(`${BaseUrl.base_url}/GetSubcategories?categoryName=${a[4]}`, "")
        .then((data) => {
          let cateData = data.data.data;
          var found;
          cateData.map((v, i) => a[5] == (v.myCategory) ? found = v.myCategory : null);
          console.log("Sub Category 5 Found =>", found)
          // setTimeout(() => {
          //   let targetFound = document.getElementById(found);
          //   targetFound.style.backgroundColor = "lightblue";
          //   console.log("targetFound =>", targetFound)
          // }, 300)
          if (cateData[0].myCategory !== undefined) {
            setCategoryChecker5(true);
            setSubCategory5(cateData);
          }
          else {
            setCategoryChecker5(false);
          }
        }).catch((e) => {
          console.log("error===>", e)
        })
      setShowSubDropdown5(true);
      console.log(subCategory1);
    }

    // ----------------- Sub Category: 6 -----------------
    if (a.length > 6) {


      console.log("cons 5========")
      getApi(`${BaseUrl.base_url}/GetSubcategories?categoryName=${a[5]}`, "")
        .then((data) => {
          let cateData = data.data.data;
          var found;
          cateData.map((v, i) => (a[6] == v.myCategory) ? found = v.myCategory : null);
          console.log("Sub Category 6 Found =>", found)
          // setTimeout(() => {
          //   let targetFound = document.getElementById(found);
          //   targetFound.style.backgroundColor = "lightblue";
          //   console.log("targetFound =>", targetFound)
          // }, 300)
          if (cateData[0].myCategory !== undefined) {
            setCategoryChecker6(true);
            setSubCategory6(cateData);
          }
          else {
            setCategoryChecker6(false);
          }
        }).catch((e) => {
          console.log("error===>", e)
        })
      setShowSubDropdown6(true);
      console.log(subCategory1);
    }

    // ----------------- Sub Category: 7 -----------------
    if (a.length > 7) {
      getApi(`${BaseUrl.base_url}/GetSubcategories?categoryName=${a[6]}`, "")
        .then((data) => {
          let cateData = data.data.data;
          var found;
          cateData.map((v, i) => (a[7] == v.myCategory) ? found = v.myCategory : null);
          console.log("Sub Category 7 Found =>", found)
          // setTimeout(() => {
          //   let targetFound = document.getElementById(found);
          //   targetFound.style.backgroundColor = "lightblue";
          //   console.log("targetFound =>", targetFound)
          // }, 300)
          if (cateData[0].myCategory !== undefined) {
            setCategoryChecker7(true);
            setSubCategory7(cateData);
          }
          else {
            setCategoryChecker7(false);
          }
        }).catch((e) => {
          console.log("error===>", e)
        })
      setShowSubDropdown7(true);
      console.log(subCategory1);
    }
    // ----------------- Sub Category: 8 -----------------

    if (a.length > 8) {
      getApi(`${BaseUrl.base_url}/GetSubcategories?categoryName=${a[7]}`, "")
        .then((data) => {
          let cateData = data.data.data;
          var found;
          cateData.map((v, i) => (a[8] == v.myCategory) ? found = v.myCategory : null);
          console.log("Sub Category 8 Found =>", found)
          // setTimeout(() => {
          //   let targetFound = document.getElementById(found);
          //   targetFound.style.backgroundColor = "lightblue";
          //   console.log("targetFound =>", targetFound)
          // }, 300)
          if (cateData[0].myCategory !== undefined) {
            setCategoryChecker8(true);
            setSubCategory8(cateData);
          }
          else {
            setCategoryChecker8(false);
          }
        }).catch((e) => {
          console.log("error===>", e)
        })
      setShowSubDropdown8(true);
      console.log(subCategory1);
      setCategories(cateArr)
    }
  }




  // Sub Category Suggestion API:

  const showSubCategory0 = (name, index) => {
    console.log("main==================", name)
    categoryClear()

    mainCategory.map((v, i) => {
      (v.CategoryName != name) ?
        document.getElementById(v.CategoryName).style.backgroundColor = "#f2ebeb" :
        document.getElementById(v.CategoryName).style.backgroundColor = "lightblue"
    })
    console.log("name =>", name)

    setCateSelection(true)
    setCategories([name.CategoryName])
    setCategoriesId(name.id)
    console.log("name.id==>", name.id)

    // console.log("index =>", index)

    getApi(`${BaseUrl.base_url}/GetSubcategories?categoryName=${name.CategoryName}`, "")
      .then((data) => {
        // console.log("if================--------------0  ",cateData[0].myCategory)
        let targetFound = document.getElementById(name.CategoryName);
        console.log("targetFound 0 =>", targetFound)
        targetFound.style.backgroundColor = "lightblue";

        let cateData = data.data.data;
        console.log("cateData00", cateData)
        if (cateData.length > 0) {

          if (cateData[0].myCategory !== undefined) {
            console.log("if================--------------0  ", cateData[0].myCategory)
            let targetFound = document.getElementById(name.CategoryName);
            console.log("targetFound 0 =>", targetFound)
            targetFound.style.backgroundColor = "lightblue";
            setCategoryChecker1(true);
            setSubCategory1(cateData);
          }

          else {

            // categoryClear()
            setCategoryChecker1(false);
            setCategoryChecker2(false);
          }
        }
      }).catch((e) => {
        let targetFound = document.getElementById(name.CategoryName);
        targetFound.style.backgroundColor = "lightblue";
        console.log("error===> 1", e)
      })
    setShowSubDropdown1(true);
    console.log(subCategory1);
  }
  const showSubCategory1 = (name, index) => {


    subCategory1.map((v, i) => {
      (v.myCategory != name) ?
        document.getElementById(v.myCategory).style.backgroundColor = "#f2ebeb" :
        document.getElementById(v.myCategory).style.backgroundColor = "lightblue"
    })

    let cateList = [...categories]
    // console.log("categories22==>",[...categories])
    cateList[1] = name.myCategory
    setCategories(cateList)
    console.log("categoriesId", categoriesId)
    let cateID = [categoriesId]
    console.log("cateID1", cateID)
    cateID[1] = name.id
    setCategoriesId(cateID)
    setCateSelection(true)
    getApi(`${BaseUrl.base_url}/GetSubcategories?categoryName=${name.myCategory}`, "")

      .then((data) => {
        let targetFound = document.getElementById(name.myCategory);
        targetFound.style.backgroundColor = "lightblue";

        let cateData = data.data.data;
        if (cateData.length > 0) {




          console.log("data11 ====> ", cateData);
          if (cateData[0].myCategory !== undefined) {
            let targetFound = document.getElementById(name.myCategory);
            console.log("targetFound 1 =>", targetFound)
            targetFound.style.backgroundColor = "lightblue";
            setCategoryChecker2(true);
            setSubCategory2(cateData);
          }
          else {

            setCategoryChecker2(false);
          }
        }
      }).catch((e) => {
        let targetFound = document.getElementById(name.myCategory);
        targetFound.style.backgroundColor = "lightblue";
        console.log("error===>", e)
      })
    setShowSubDropdown2(true);
    console.log(subCategory1);
  }
  const showSubCategory2 = (name, index) => {
    subCategory2.map((v, i) => {
      (v.myCategory != name) ?
        document.getElementById(v.myCategory).style.backgroundColor = "#f2ebeb" :
        document.getElementById(v.myCategory).style.backgroundColor = "lightblue"
    })

    let cateList = [...categories]
    cateList[2] = name.myCategory
    setCategories(cateList)
    let cateID = categoriesId
    console.log("cateID2", cateID)
    cateID[2] = name.id
    setCategoriesId(cateID)
    setCateSelection(true)
    getApi(`${BaseUrl.base_url}/GetSubcategories?categoryName=${name.myCategory}`, "")
      .then((data) => {
        let targetFound = document.getElementById(name.myCategory);
        targetFound.style.backgroundColor = "lightblue";
        let cateData = data.data.data;
        if (cateData.length > 0) {




          if (cateData[0].myCategory !== undefined) {
            let targetFound = document.getElementById(name.myCategory);
            targetFound.style.backgroundColor = "lightblue";
            console.log("targetFound 2 =>", targetFound)
            setCategoryChecker3(true);
            setSubCategory3(cateData);
          }
          else {
            setCategoryChecker3(false);
          }
        }
      }).catch((e) => {
        let targetFound = document.getElementById(name.myCategory);
        targetFound.style.backgroundColor = "lightblue";
        console.log("error===>", e)
      })
    setShowSubDropdown3(true);
    console.log(subCategory1);
  }
  const showSubCategory3 = (name, index) => {
    subCategory3.map((v, i) => {
      (v.myCategory != name) ?
        document.getElementById(v.myCategory).style.backgroundColor = "#f2ebeb" :
        document.getElementById(v.myCategory).style.backgroundColor = "lightblue"
    })
    let cateList = [...categories]
    cateList[3] = name.myCategory
    setCategories(cateList)
    let cateID = categoriesId
    console.log("cateID3", cateID)

    cateID[3] = name.id
    setCategoriesId(cateID)
    setCateSelection(true)
    getApi(`${BaseUrl.base_url}/GetSubcategories?categoryName=${name.myCategory}`, "")
      .then((data) => {
        let targetFound = document.getElementById(name.myCategory);
        targetFound.style.backgroundColor = "lightblue";
        let cateData = data.data.data;
        if (cateData.length > 0) {




          if (cateData[0].myCategory !== undefined) {
            let targetFound = document.getElementById(name.myCategory);
            targetFound.style.backgroundColor = "lightblue";
            console.log("targetFound 3 =>", targetFound)
            setCategoryChecker4(true);
            setSubCategory4(cateData);
          }
          else {
            setCategoryChecker4(false);
          }
        }

      }).catch((e) => {
        let targetFound = document.getElementById(name.myCategory);
        console.log("targetFound 1 =>", targetFound)
        targetFound.style.backgroundColor = "lightblue";
        console.log("error===>", e)
      })
    setShowSubDropdown4(true);
    console.log(subCategory1);
  }
  const showSubCategory4 = (name, index) => {
    subCategory4.map((v, i) => {
      (v.myCategory != name) ?
        document.getElementById(v.myCategory).style.backgroundColor = "#f2ebeb" :
        document.getElementById(v.myCategory).style.backgroundColor = "lightblue"
    })
    let cateList = [...categories]
    cateList[4] = name.myCategory
    setCategories(cateList)
    let cateID = categoriesId
    cateID[4] = name.id
    setCategoriesId(cateID)
    setCateSelection(true)
    getApi(`${BaseUrl.base_url}/GetSubcategories?categoryName=${name.myCategory}`, "")
      .then((data) => {
        let targetFound = document.getElementById(name.myCategory);
        targetFound.style.backgroundColor = "lightblue";
        let cateData = data.data.data;

        if (cateData.length > 0) {



          if (cateData[0].myCategory !== undefined) {
            let targetFound = document.getElementById(name.myCategory);
            targetFound.style.backgroundColor = "lightblue";
            console.log("targetFound 4 =>", targetFound)
            setCategoryChecker5(true);
            setSubCategory5(cateData);
          }
          else {
            setCategoryChecker5(false);
          }
        }
      }).catch((e) => {
        let targetFound = document.getElementById(name.myCategory);
        console.log("targetFound 1 =>", targetFound)
        targetFound.style.backgroundColor = "lightblue";
        console.log("error===>", e)
      })
    setShowSubDropdown5(true);
    console.log(subCategory1);
  }
  const showSubCategory5 = (name, index) => {
    subCategory5.map((v, i) => {
      (v.myCategory != name) ?
        document.getElementById(v.myCategory).style.backgroundColor = "#f2ebeb" :
        document.getElementById(v.myCategory).style.backgroundColor = "lightblue"
    })
    let cateList = [...categories]
    cateList[5] = name.myCategory
    setCategories(cateList)
    let cateID = categoriesId
    cateID[5] = name.id
    setCategoriesId(cateID)
    setCateSelection(true)
    getApi(`${BaseUrl.base_url}/GetSubcategories?categoryName=${name.myCategory}`, "")
      .then((data) => {
        let targetFound = document.getElementById(name.myCategory);
        targetFound.style.backgroundColor = "lightblue";
        let cateData = data.data.data;
        if (cateData.length > 0) {




          if (cateData[0].myCategory !== undefined) {
            let targetFound = document.getElementById(name.myCategory);
            console.log("targetFound 5 =>", targetFound)
            targetFound.style.backgroundColor = "lightblue";
            setCategoryChecker6(true);
            setSubCategory6(cateData);
          }
          else {
            setCategoryChecker6(false);
          }
        }
      }).catch((e) => {
        let targetFound = document.getElementById(name.myCategory);
        targetFound.style.backgroundColor = "lightblue";


      })
    setShowSubDropdown6(true);
    console.log(subCategory1);
  }
  const showSubCategory6 = (name, index) => {
    subCategory6.map((v, i) => {
      (v.myCategory != name) ?
        document.getElementById(v.myCategory).style.backgroundColor = "#f2ebeb" :
        document.getElementById(v.myCategory).style.backgroundColor = "lightblue"
    })
    let cateList = [...categories]
    cateList[6] = name.myCategory
    setCategories(cateList)
    let cateID = categoriesId
    cateID[6] = name.id
    setCategoriesId(cateID)
    setCateSelection(true)
    getApi(`${BaseUrl.base_url}/GetSubcategories?categoryName=${name.myCategory}`, "")
      .then((data) => {
        let targetFound = document.getElementById(name.myCategory);
        targetFound.style.backgroundColor = "lightblue";
        let cateData = data.data.data;
        if (cateData.length > 0) {




          if (cateData[0].myCategory !== undefined) {
            let targetFound = document.getElementById(name.myCategory);
            targetFound.style.backgroundColor = "lightblue";
            console.log("targetFound 6 =>", targetFound)
            setCategoryChecker7(true);
            setSubCategory7(cateData);
          }
          else {

            console.log("elsee===>")
            setCategoryChecker7(false);
          }
        }
      }).catch((e) => {
        let targetFound = document.getElementById(name.myCategory);
        targetFound.style.backgroundColor = "lightblue";
        console.log("error===>", e)
      })
    setShowSubDropdown7(true);
    console.log(subCategory1);
  }
  const showSubCategory7 = (name, index) => {
    subCategory7.map((v, i) => {
      (v.myCategory != name) ?
        document.getElementById(v.myCategory).style.backgroundColor = "#f2ebeb" :
        document.getElementById(v.myCategory).style.backgroundColor = "lightblue"
    })
    let cateList = [...categories]
    cateList[7] = name.myCategory
    setCategories(cateList)
    let cateID = categoriesId
    cateID[7] = name.id
    setCategoriesId(cateID)
    setCateSelection(true)
    getApi(`${BaseUrl.base_url}/GetSubcategories?categoryName=${name.myCategory}`, "")
      .then((data) => {
        let targetFound = document.getElementById(name.myCategory);
        targetFound.style.backgroundColor = "lightblue";
        let cateData = data.data.data;
        if (cateData.length > 0) {



          if (cateData[0].myCategory !== undefined) {
            let targetFound = document.getElementById(name.myCategory);
            targetFound.style.backgroundColor = "lightblue";
            console.log("targetFound 7 =>", targetFound)
            setCategoryChecker8(true);
            setSubCategory8(cateData);
          }
          else {

            setCategoryChecker8(false);
          }
        }
      }).catch((e) => {
        let targetFound = document.getElementById(name.myCategory);
        console.log("targetFound 1 =>", targetFound)
        targetFound.style.backgroundColor = "lightblue";
        console.log("error===>", e)
      })
    setShowSubDropdown8(true);
    console.log(subCategory1);
  }



  // Confirm btn fn:

  const showFinalCategory = () => {
    console.log(document.getElementById("editCate"))
    let cateSlashFor = "";
    categories.map((v, i) => cateSlashFor += `${v}/`)
    setcategory(cateSlashFor)
    setContainer(false);
  }

  // useEffect(() => {
  // }, [])
  // console.log(document.getElementById("editCate").setAttribute("value", JSON.stringify(showCategory)))

  // Confirm btn fn:

  const categoryClear = () => {
    mainCategory.map((v, i) => {
      document.getElementById(v.CategoryName).style.backgroundColor = "#f2ebeb"
    })
    setCateSelection(false);
    setCategoryChecker1(false);
    setCategoryChecker2(false);
    setCategoryChecker3(false);
    setCategoryChecker4(false);
    setCategoryChecker5(false);
    setCategoryChecker6(false);
    setCategoryChecker7(false);
    setCategoryChecker8(false);
  }

  // ============== DETAILED DES ==============

  // Set Editor Content:

  const onEditorStateChange1 = (e) => {
    console.log("e 1==========>", e)
    setEditorState1(e);
  }
  const onEditorStateChange2 = (e) => {
    setEditorState2(e);
  }
  const onEditorStateChange3 = (e) => {
    setEditorState3(e);
  }

  var shortDes = draftToHtml(convertToRaw(editorState1.getCurrentContent()))
  var longDes = draftToHtml(convertToRaw(editorState2.getCurrentContent()))
  var engDes = draftToHtml(convertToRaw(editorState3.getCurrentContent()))
  console.log("shortDes======>", shortDes)

  // ============== PRICE & STOCK ==============

  console.log("verifyImgObj", verifyImgObj);
  var verifyImg = [];
  var rejectedImg = [];

  const handleImageChange = async (e) => {
    // setLoader(true);
    setImgLoader(true);
    var formdata = new FormData();
    var filesArray = [];
    setVerifyImgObj(e.target.files)
    if (e.target.files) {
      for (var i = 0; i < e.target.files.length; i++) {
        formdata.append("image", e.target.files.item(i));
        // console.log("formdata", formdata);
        await PostApi(`${BaseUrl.base_url}/CheckImage`, formdata, Token)
          .then(({ data }) => {
            if (data.status) {
              console.log(data.status);
              verifyImg.push(e.target.files.item(i))
              filesArray = Array.from(verifyImg).map((file) => URL.createObjectURL(file));
            }
            else {
              console.log(data.status);
              rejectedImg.push(e.target.files[i])
              console.log("Rejected Img =>", rejectedImg)
            }
          }
          ).catch((e) => {
            console.log("error =>", e)
          }
          )
      }
      // setLoader(false);
      setImgLoader(false);
      setSelectedFiles((prevImages) => prevImages.concat(filesArray));
      setVerifyImgObj(verifyImg);
      Array.from(verifyImg).map(
        (file) => URL.revokeObjectURL(file) // avoid memory leak
      );
    }
  }

  // Remove Single fn:

  const deleteImagePer = (i) => {
    console.log("i =>", verifyImgObj[i]);
    var formdata = new FormData();
    formdata.append("image", verifyImgObj[i]);

    DeleteApi(`${BaseUrl.base_url}/AddProduct?id=${i}`, formdata, Token)
      .then(({ data }) => {
        console.log("data =>", data);
      }).catch((e) => {
        console.log("e =>", e)
      })
  }

  const removeImage = (index, id) => {
    setSelectedFiles([
      ...selectedFiles.slice(0, index),
      ...selectedFiles.slice(index + 1)
    ]);
    setVerifyImgObj([
      ...verifyImgObj.slice(0, index),
      ...verifyImgObj.slice(index + 1)
    ]);
    deleteImagePer(id);
  }

  // Render Images fn:

  const renderPhotos = (source) => {
    return source.map((photo, index) => {
      return (
        <span className="imgUp">
          <img style={{ border: "1px solid black" }} src={(photo)} width="150" height="150" alt="" key={photo} />
          <button type="button" onClick={() => removeImage(index)}><i style={{ fontSize: 18 }} className="fas fa-trash-alt"></i></button>
        </span>
      )
    });
  };

  const specialPriceEvent = () => {
    if (specialPrice != 0) {
      return true;
    }
  }

  // Not Null Validation fn:

  const onSubmitPrice = (e) => {
    e.preventDefault();
    const isValid = formValidationPrice();
    if (isValid == true) {
      NextStep(4);
    }
  }

  const formValidationPrice = () => {
    const priceErr = {};
    const sppriceErr = {};

    let isValid = true;

    if (price.trim().length == 0) {
      priceErr.priceNull = "safdar";
      isValid = false;
    }
    if (specialPrice.trim().length == 0) {
      sppriceErr.priceNull = "please enter less price then actual price";
      isValid = false;

    }

    setPriceErr(priceErr);
    setspPriceErr(sppriceErr);

    return isValid;
  }

  // ============== SERVICE & DELIVERY ==============

  // Not Null Validation fn:

  const onSubmitDelivery = (e) => {
    e.preventDefault();
    const isValid = formValidationDelivery();
    if (isValid == true) {
      console.log("isValid working")
      NextStep(4);
    }
  }

  const formValidationDelivery = () => {
    const weightErr = {};
    const lengthErr = {};
    const widthErr = {};
    const heightErr = {};

    let isValid = true;

    if (weight == 0) {
      weightErr.weightNull = "Enter product weight";
      isValid = false;
    }
    if (length == 0) {
      lengthErr.lengthNull = "Enter length";
      isValid = false;
    }
    if (width == 0) {
      widthErr.widthNull = "Enter width";
      isValid = false;
    }
    if (height == 0) {
      heightErr.heightNull = "Enter height";
      isValid = false;
    }

    setWeightErr(weightErr);
    setLengthErr(lengthErr);
    setWidthErr(widthErr);
    setHeightErr(heightErr);

    return isValid;
  }

  const editProduct = () => {
    setLoader(true);
    var formdata = new FormData();

    formdata.append("Product_Name", productName);
    formdata.append("Product_id", location.state.productid);
    formdata.append("Categoryid", JSON.stringify(categoriesId));
    formdata.append("VideoUrl", VideoUrl);
    formdata.append("Brand_id", "2");
    formdata.append("Short_Description", shortDes);
    formdata.append("long_Description", longDes);
    formdata.append("English_Description", engDes);
    formdata.append("Whats_in_the_Box", WhatsintheBox);
    formdata.append("Price", price);
    formdata.append("Special_Price", specialPrice);
    formdata.append("Promotion_Start_Date", PromotionStartDate);
    formdata.append("Promotion_End_Date", PromotionEndDate);
    formdata.append("Promotion_Start_Time", PromotionStartTime);
    formdata.append("Promotion_End_Time", PromotionEndTime);
    formdata.append("Quantity", Quantity);
    formdata.append("Warranty", Warranty);
    formdata.append("Warranty_Period", WarrantyPeriod);
    formdata.append("Package_Weight", weight);
    formdata.append("Lenght", length);
    formdata.append("Width", width);
    formdata.append("Height", height);
    formdata.append("Seller_id", SellerId);
    formdata.append("Dangerous_Goods", DangerousGoods);
    for (let i = 0; i < verifyImgObj.length; i++) {
      console.log("img is ", verifyImgObj[i])
      formdata.append('image', verifyImgObj[i])
    }
    formdata.append("Category", category);

    var check = category.split("/")
    console.log("showcategory===>",category)
    if (check.length > 2) {
      formdata.append("CategoryId", categoriesId[0]);

    }

    else {
      formdata.append("CategoryId", categoriesId);

    }

    PutApi(`${BaseUrl.base_url}/EditProduct`, formdata, Token)
      .then(({ data }) => {
        setLoader(false);
        if (data.status) {

          Swal.fire({
            title: "Success",
            text: data.message,
            icon: "success"
          })
          console.log("view product detailss")
          // ViewProductData()
          history.push('./productManagement');
        } else {
          // $(".loader").hide();
          Swal.fire({
            title: "Opps",
            text: data.message,
            icon: "error"
          })
        }
      }).catch(function (e) {
        console.log("error =>", e)
      })

  }


  if(loaderActive){
    return(
      <div style={{width:'100%',height:'100vh',display:'flex',justifyContent:'center',alignItems:'center'}}>
        
        <img src={'./assets/img/loader.svg'} alt="loader"/>
    
        </div>
    )}

  return (
    <>
      {Loader ? <div className="loader" /> : null}
      <div id="app">
        <div className="main-wrapper main-wrapper-1">
          <Header />
          <div className="main-content">
            <section className="section">
              <div className="row">
                <div className="col-md-12">
                  <div className="card card-primary">
                    <div className="card-header">
                      <h4>View Product Details</h4>
                    </div>
                    <div className="card-body">
                      {Step1 ?
                        <>
                          {/* <h3>Personal Information</h3> */}
                          <form method="POST" onSubmit={onSubmitBasicinfo}>
                            <div className="row">
                              <div className="col-md-12 mb-2 text-center">
                                <button onClick={() => CurruntStep(1)} style={{ width: "22%", height: "50px", fontSize: "15px" }} className="btn btn-primary">Basic Information</button>
                                <button disabled onClick={() => CurruntStep(2)} style={{ width: "22%", height: "50px", fontSize: "15px" }} className="btn btn-primary ml-2">Detail Description</button>
                                <button disabled onClick={() => CurruntStep(3)} style={{ width: "22%", height: "50px", fontSize: "15px" }} className="btn btn-primary ml-2">Price & Stock</button>
                                <button disabled onClick={() => CurruntStep(4)} style={{ width: "22%", height: "50px", fontSize: "15px" }} className="btn btn-primary ml-2">Service & Delivery</button>
                              </div>
                            </div>
                            <hr />
                            <fieldset>
                              <div className="row basicInfo px-5" >
                                <div className="col-md-12">
                                  <div className="form-group form-float">
                                    <div className="form-line">
                                      <h6 style={{ marginBottom: 10 }}>BASIC INFORMATION</h6>
                                      <label className="form-label"><b>Product Name</b><span style={{ color: "red" }}> *</span></label>
                                      <input value={productName} onChange={(e) => setProductName(e.target.value)} className="form-control" for="items" placeholder="Nikon Coolpix A300 Digital Camera" type="text" />
                                      {/* Category Suggestions */}
                                      <div className="row" style={{ backgroundColor: "white", marginRight: 15, marginLeft: 15, overflowX: "auto" }}>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row basicInfo px-5">
                                <div className="col-md-12">
                                  <div className="form-group form-float">
                                    <div className="form-line">
                                      <label className="form-label"><b>Category</b><span style={{ color: "red" }}> *</span></label>
                                      <input id="editCate" onClick={() => displaySuggestionBox()} value={category} placeholder="" type="text" className="form-control" />
                                      {container ?
                                        <div style={{ borderRadius: 5, border: "1px solid grey", padding: 10 }}>
                                          <div class="costumRow" style={{ padding: 0, display: "flex", border: "1px solid grey", backgroundColor: "white", marginRight: 15, marginLeft: 15, overflowX: "auto" }}>
                                            <div>
                                              {(showDropdown) ?
                                                <div style={{ border: "1px solid grey", height: 318, width: 169, display: "inline-block" }}>
                                                  <div style={{ margin: 10, border: "1px solid white" }}><input style={{ width: 148, border: "1px solid grey" }} type="search" placeholder="Keyword" /></div>
                                                  <ul class="list-unstyled column">
                                                    {/* <li style={{margin: 10}}><input type="search" placeholder="Keyword"/></li> */}
                                                    {mainCategory.map((v, i) => {
                                                      return (
                                                        <li id={v.CategoryName} style={{ border: "1px solid white", color: "black", backgroundColor: "#f2ebeb", height: 40, padding: 10 }} onMouseOver={MouseOver} onClick={() => showSubCategory0(v, i)} key={i}>{v.CategoryName}</li>
                                                      )
                                                    })}
                                                  </ul>
                                                  {/* <SlickSlider /> */}
                                                </div>
                                                : null
                                              }
                                            </div>
                                            {categoryChecker1 ?
                                              <div>
                                                {(showSubDropdown1) ?
                                                  <div style={{ border: "1px solid grey", height: 318, width: 169, display: "inline-block" }}>
                                                    <div style={{ margin: 10, border: "1px solid white" }}><input style={{ width: 148, border: "1px solid grey" }} type="search" placeholder="Keyword" /></div>
                                                    <ul class="list-unstyled column">
                                                      {subCategory1.map((v, i) => {
                                                        return (
                                                          <li id={v.myCategory} style={{ border: "1px solid white", color: "black", backgroundColor: "#f2ebeb", height: 40, padding: 10 }} onMouseOver={MouseOver} onClick={() => showSubCategory1(v, i)} key={i}>{v.myCategory}</li>
                                                        )
                                                      })}
                                                    </ul>
                                                  </div>
                                                  : null
                                                }
                                              </div>
                                              : null
                                            }
                                            {categoryChecker2 ?
                                              <div>
                                                {(showSubDropdown2) ?
                                                  <div style={{ border: "1px solid grey", height: 318, width: 169, display: "inline-block" }}>
                                                    <div style={{ margin: 10, border: "1px solid white" }}><input style={{ width: 148, border: "1px solid grey" }} type="search" placeholder="Keyword" /></div>
                                                    <ul class="list-unstyled column">
                                                      {subCategory2.map((v, i) => {
                                                        return (
                                                          <li id={v.myCategory} style={{ border: "1px solid white", backgroundColor: "#f2ebeb", color: "black", height: 40, padding: 10 }} onClick={() => showSubCategory2(v, i)} onMouseOver={MouseOver} key={i}>{v.myCategory}</li>

                                                        )
                                                      })}
                                                    </ul>
                                                  </div>
                                                  : null
                                                }
                                              </div>
                                              : null
                                            }
                                            {categoryChecker3 ?
                                              <div>
                                                {(showSubDropdown3) ?
                                                  <div style={{ border: "1px solid grey", height: 318, width: 169, display: "inline-block" }}>
                                                    <div style={{ margin: 10, border: "1px solid white" }}><input style={{ width: 148, border: "1px solid grey" }} type="search" placeholder="Keyword" /></div>
                                                    <ul class="list-unstyled column">
                                                      {subCategory3.map((v, i) => {
                                                        return (
                                                          <li id={v.myCategory} style={{ border: "1px solid white", backgroundColor: "#f2ebeb", color: "black", height: 40, padding: 10 }} onMouseOver={MouseOver} onClick={() => showSubCategory3(v, i)} key={i}>{v.myCategory}</li>
                                                        )
                                                      })}
                                                    </ul>
                                                  </div>
                                                  : null
                                                }
                                              </div>
                                              : null
                                            }
                                            {categoryChecker4 ?
                                              <div>
                                                {(showSubDropdown4) ?
                                                  <div style={{ border: "1px solid grey", height: 318, width: 169, display: "inline-block" }}>
                                                    <div style={{ margin: 10, border: "1px solid white" }}><input style={{ width: 148, border: "1px solid grey" }} type="search" placeholder="Keyword" /></div>
                                                    <ul class="list-unstyled column">
                                                      {subCategory4.map((v, i) => {
                                                        return (
                                                          <li id={v.myCategory} style={{ border: "1px solid white", backgroundColor: "#f2ebeb", color: "black", height: 40, padding: 10 }} onMouseOver={MouseOver} onClick={() => showSubCategory4(v, i)} key={i}>{v.myCategory}</li>
                                                        )
                                                      })}
                                                    </ul>
                                                  </div>
                                                  : null
                                                }
                                              </div>
                                              : null
                                            }
                                            {categoryChecker5 ?
                                              <div>
                                                {(showSubDropdown5) ?
                                                  <div style={{ border: "1px solid grey", height: 318, width: 169, display: "inline-block" }}>
                                                    <div style={{ margin: 10, border: "1px solid white" }}><input style={{ width: 148, border: "1px solid grey" }} type="search" placeholder="Keyword" /></div>
                                                    <ul class="list-unstyled column">
                                                      {subCategory5.map((v, i) => {
                                                        return (
                                                          <li id={v.myCategory} style={{ border: "1px solid white", backgroundColor: "#f2ebeb", color: "black", height: 40, padding: 10 }} onMouseOver={MouseOver} onClick={() => showSubCategory5(v, i)} key={i}>{v.myCategory}</li>
                                                        )
                                                      })}
                                                    </ul>
                                                  </div>
                                                  : null
                                                }
                                              </div>
                                              : null
                                            }
                                            {categoryChecker6 ?
                                              <div>
                                                {(showSubDropdown6) ?
                                                  <div style={{ border: "1px solid grey", height: 318, width: 169, display: "inline-block" }}>
                                                    <div style={{ margin: 10, border: "1px solid white" }}><input style={{ width: 148, border: "1px solid grey" }} type="search" placeholder="Keyword" /></div>
                                                    <ul class="list-unstyled column">
                                                      {subCategory6.map((v, i) => {
                                                        return (
                                                          <li id={v.myCategory} style={{ border: "1px solid white", backgroundColor: "#f2ebeb", color: "black", height: 40, padding: 10 }} onMouseOver={MouseOver} onClick={() => showSubCategory6(v, i)} key={i}>{v.myCategory}</li>
                                                        )
                                                      })}
                                                    </ul>
                                                  </div>
                                                  : null
                                                }
                                              </div>
                                              : null
                                            }
                                            {categoryChecker7 ?
                                              <div>
                                                {(showSubDropdown7) ?
                                                  <div style={{ border: "1px solid grey", height: 318, width: 169, display: "inline-block" }}>
                                                    <div style={{ margin: 10, border: "1px solid white" }}><input style={{ width: 148, border: "1px solid grey" }} type="search" placeholder="Keyword" /></div>
                                                    <ul class="list-unstyled column">
                                                      {subCategory7.map((v, i) => {
                                                        return (
                                                          <li id={v.myCategory} style={{ border: "1px solid white", backgroundColor: "#f2ebeb", color: "black", height: 40, padding: 10 }} onMouseOver={MouseOver} onClick={() => showSubCategory7(v, i)} key={i}>{v.myCategory}</li>
                                                        )
                                                      })}
                                                    </ul>
                                                  </div>
                                                  : null
                                                }
                                              </div>
                                              : null
                                            }
                                            {categoryChecker8 ?
                                              <div>
                                                {(showSubDropdown8) ?
                                                  <div style={{ border: "1px solid grey", height: 318, width: 169, display: "inline-block" }}>
                                                    <div style={{ margin: 10, border: "1px solid white" }}><input style={{ width: 148, border: "1px solid grey" }} type="search" placeholder="Keyword" /></div>
                                                    <ul class="list-unstyled column">
                                                      {subCategory8.map((v, i) => {
                                                        return (
                                                          <li id={v.myCategory} style={{ border: "1px solid white", backgroundColor: "#f2ebeb", color: "black", height: 40, padding: 10 }} onMouseOver={MouseOver} key={i}>{v.myCategory}</li>
                                                        )
                                                      })}
                                                    </ul>
                                                  </div>
                                                  : null
                                                }
                                              </div>
                                              : null
                                            }
                                          </div>
                                          <div class="row" style={{ backgroundColor: "#f2ebeb", padding: 8, border: "1px solid grey", marginLeft: 16, width: 676, marginTop: 8, marginBottom: 8 }}>
                                            <span>Current Selection:
                                              <span style={{ color: "red" }}>
                                                {(cateSelection) ?
                                                  categories.map((v, i) => {
                                                    console.log("v =>", v)
                                                    return (
                                                      <span>{`${v} >`}</span>
                                                    )
                                                  })
                                                  : null
                                                }
                                              </span>
                                            </span>
                                            <span></span>
                                          </div>
                                          <div class="row" style={{ marginLeft: 15, marginTop: 5 }}>
                                            <button class="btn-sm btn-primary" onClick={showFinalCategory}>Confirm</button>
                                            <button style={{ marginLeft: 5 }} class="btn-sm btn-primary" onClick={() => setContainer(false)}>Cancel</button>
                                            <button style={{ marginLeft: 5 }} class="btn-sm btn-primary" onClick={categoryClear}>Clear</button>
                                          </div>
                                        </div>
                                        : null
                                      }
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* Product Attributes */}
                              <div>

                                <div>
                                  <div className="row basicInfo px-5">
                                    <div className="col-md-12">
                                      <div className="form-group form-float">
                                        <div className="form-line">
                                          <label className="form-label"><b>Video URL</b></label>
                                          <input value={VideoUrl} onChange={e => SetVideoUrl(e.target.value)} placeholder="" type="text" className="form-control" />
                                          {/* <h6 style={{ marginTop: 30 }}>PRODUCT ATTRIBUTES</h6> */}

                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {/* <p style={{ fontWeight: 'normal', fontSize: 14, marginLeft: 50, marginTop: -20 }}>Lorem ipsum dolor sit amet consectetur adipisicing elit. Necessitatibus molestiae veritatis aliquid, laborum quasi atque exercitationem. Voluptate ullam adipisci voluptatibus sapiente.</p> */}
                                  <div className="row d-flex mx-4">
                                    <div className="col-md-4">
                                      <div className="form-group form-float">
                                        <div className="form-line">
                                          <label className="form-label"><b>Brand</b><span style={{ color: "red" }}> *</span></label>
                                          <input value={BrandId} onChange={e => SetBrandId(e.target.value)} placeholder="" type="text" className="form-control" />
                                        </div>
                                      </div>
                                    </div>
                                    {/* <div className="col-md-4">
                                      <div className="form-group form-float">
                                        <div className="form-line">
                                          <label className="form-label"><b>Cable Length</b></label>
                                          <MultilevelDropdown />
                                          <select className="form-control">
                                            <option>Select Length</option>
                                            <option>UPC</option>
                                            <option>ATP</option>
                                          </select>
                                        </div>
                                      </div>
                                    </div> */}
                                  </div>
                                  {/* <div className="d-flex justify-content-end" style={{ marginRight: 50 }}>
                                    <button className="btn btn-outline-danger">Clear All</button>
                                  </div> */}
                                </div>

                              </div>
                            </fieldset>
                            {/* Next Button */}
                            <div className="row">
                              <div className="col-md-12 pt-3 text-center">
                                <hr />
                                {/* <button className="btn btn-primary col-md-3 mx-2" onClick={() => PreviousStep(1)}>Previous</button> */}
                                <button className="btn btn-primary col-md-3 mx-2" onClick={() => CurruntStep(2)} type="button">Next</button>
                              </div>
                            </div>
                          </form>
                        </>
                        : null
                      }
                      {Step2 ?
                        <>
                          {/* <h3>Detailed Description</h3> */}
                          <form  >
                            <div className="row">
                              <div className="col-md-12 mb-2 text-center">
                                <button onClick={() => CurruntStep(1)} style={{ width: "22%", height: "50px", fontSize: "15px" }} className="btn btn-primary">Basic Information</button>
                                <button onClick={() => CurruntStep(2)} style={{ width: "22%", height: "50px", fontSize: "15px" }} className="btn btn-primary ml-2">Detail Description</button>
                                <button disabled onClick={() => CurruntStep(3)} style={{ width: "22%", height: "50px", fontSize: "15px" }} className="btn btn-primary ml-2">Price & Stock</button>
                                <button disabled onClick={() => CurruntStep(4)} style={{ width: "22%", height: "50px", fontSize: "15px" }} className="btn btn-primary ml-2">Service & Delivery</button>
                              </div>
                            </div>
                            <fieldset>
                              <div className="row">
                                <div className="col-md-12 d-flex justify-content-center">
                                  <div className="form-group form-float">
                                    <div className="form-line">
                                      <label className="form-label"><b>Short Description</b><span style={{ color: "red" }}> *</span></label>
                                      {/* <JoditEditor
                                        value={GetAllAIvendors.ProductDetails.Short_Description}
                                      // value={content}
                                      // onBlur={handleUpdate}
                                      /> */}
                                      <Editor
                                        editorState={editorState1}
                                        toolbarClassName="toolbarClassName"
                                        wrapperClassName="demoWrapper"
                                        editorClassName="demoEditor"
                                        onEditorStateChange={onEditorStateChange1}
                                        editorStyle={{ border: "1px solid #F0F0F0", padding: 15, minHeight: 350 }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-12 d-flex justify-content-center">
                                  <div className="form-group form-float">
                                    <div className="form-line">
                                      <label className="form-label"><b>Long Description</b></label>
                                      {/* <JoditEditor
                                        value={GetAllAIvendors.ProductDetails.long_Description}
                                      /> */}
                                      <Editor
                                        editorState={editorState2}
                                        toolbarClassName="toolbarClassName"
                                        wrapperClassName="demoWrapper"
                                        editorClassName="demoEditor"
                                        onEditorStateChange={onEditorStateChange2}
                                        editorStyle={{ border: "1px solid #F0F0F0", padding: 15, minHeight: 350 }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-12 d-flex justify-content-center">
                                  <div className="form-group form-float">
                                    <div className="form-line">
                                      <label className="form-label"><b>English Description</b><span style={{ color: "red" }}> *</span></label>
                                      {/* <JoditEditor
                                        value={GetAllAIvendors.ProductDetails.English_Description}
                                      /> */}
                                      <Editor
                                        editorState={editorState3}
                                        toolbarClassName="toolbarClassName"
                                        wrapperClassName="demoWrapper"
                                        editorClassName="demoEditor"
                                        onEditorStateChange={onEditorStateChange3}
                                        editorStyle={{ border: "1px solid #F0F0F0", padding: 15, minHeight: 350 }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-12 px-5">
                                  <div className="form-group form-float">
                                    <div className="form-line">
                                      <label className="form-label"><b>What's in the box</b></label>
                                      <input value={WhatsintheBox} onChange={e => SetWhatsintheBox(e.target.value)} placeholder="" type="text" className="form-control" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </fieldset>
                            <div className="row">
                              <div className="col-md-12 pt-3 text-center">
                                <hr />
                                <button className="btn btn-primary col-md-3 mx-2" onClick={() => PreviousStep(2)}>Previous</button>
                                <button className="btn btn-primary col-md-3 mx-2" onClick={() => NextStep(2)}>Next</button>
                              </div>
                            </div>
                          </form>
                        </>
                        : null}
                      {Step3 ?
                        <>
                          {/* <h3>Price & Stock</h3> */}
                          <form  >
                            <div className="row">
                              <div className="col-md-12 mb-2 text-center">
                                <button onClick={() => CurruntStep(1)} style={{ width: "22%", height: "50px", fontSize: "15px" }} className="btn btn-primary">Basic Information</button>
                                <button onClick={() => CurruntStep(2)} style={{ width: "22%", height: "50px", fontSize: "15px" }} className="btn btn-primary ml-2">Detail Description</button>
                                <button onClick={() => CurruntStep(3)} style={{ width: "22%", height: "50px", fontSize: "15px" }} className="btn btn-primary ml-2">Price & Stock</button>
                                <button disabled onClick={() => CurruntStep(4)} style={{ width: "22%", height: "50px", fontSize: "15px" }} className="btn btn-primary ml-2">Service & Delivery</button>
                              </div>
                            </div>
                            <fieldset>
                              <div className="form-group form-float">
                                <div className="form-line" >
                                  <div style={{ border: "1px solid gray", borderRadius: 8, padding: 20, marginBottom: 10 }}>
                                    <h6 style={{ color: "black" }}>UPLOAD YOUR PRODUCT IMAGES</h6>
                                    <div style={{ marginTop: -5, marginBottom: 10 }}>Drop images here or click to upload</div>
                                    <hr />
                                    <div className="row">
                                      <div style={{ marginLeft: 20 }}>
                                        <div style={{ marginBottom: 10 }} className="label-holder">
                                          <label style={{ marginBottom: 0 }} htmlFor="file" className="label">
                                            <i style={{ fontSize: 35 }} className="material-icons">add_a_photo</i>
                                          </label>
                                          <br />
                                          <input type="file" id="file" hidden multiple onChange={handleImageChange} />
                                        </div>

                                        <div className="result">
                                          {/* {imgLoader ? <LinearProgressWithLabel /> : null} */}
                                          {GetAllAIvendors.ImageData?.map((v, i) =>
                                            <span className="imgUp">
                                              <img style={{ border: "1px solid black" }} src={(v.image)} width="150" height="150" ></img>
                                              <button type="button" onClick={() => removeImage(i, v.id)}><i style={{ fontSize: 18 }} className="fas fa-trash-alt"></i></button>
                                            </span>
                                          )}
                                          {renderPhotos(selectedFiles)}
                                        </div>
                                      </div>

                                      {/* <div className="result">
                                        {GetAllAIvendors.ImageData?.map((e) =>
                                          <img style={{ maxWidth: "130px", borderRadius: "20px" }} src={image_url.image_url(e.image)}></img>
                                        )}
                                      </div> */}
                                    </div>
                                  </div>
                                  <div className="" style={{ border: "1px solid gray", borderRadius: 8, padding: 20, marginBottom: 10 }} role="alert">
                                    <h6 style={{ color: "black" }}>PRICING & STOCK</h6>
                                    <hr />
                                    <div className="row text-dark" style={{ fontSize: 14 }}>
                                      {/* <div className="col-3"><b>AVALIBILITY</b></div> */}
                                      {/* <div className="col-3">PRICE<span style={{ color: "red" }}> *</span></div>
                                                                            <div className="col-3">SPECIAL PRICE</div> */}
                                      <div className="col-3"><b>QUANTITY</b></div>
                                    </div>
                                    <div className="row text-dark">
                                      <div className="col-md-3">
                                        <div className="form-group form-float">
                                          <div className="form-group form-float">
                                            <div className="form-line">
                                              <input value={Quantity} onChange={e => SetQuantity(e.target.value)} placeholder="" type="number" className="form-control" />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      {/* <div className="col-3">
                                        <div className="form-check" style={{ padding: 10 }} >
                                          <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                          <label className="form-check-label" for="defaultCheck1"><b>Check your Product Availibility</b></label>
                                        </div>
                                      </div> */}
                                      {/* <div className="col-md-3">
                                        <div className="form-group form-float">
                                          <div className="form-group form-float">
                                            <div className="form-line">
                                              <input value={Quantity} onChange={e => SetQuantity(e.target.value)} placeholder="" type="number" className="form-control" />
                                            </div>
                                          </div>
                                        </div>
                                      </div> */}
                                      <div className="row text-dark px-4">
                                        <div className="row text-dark" style={{ fontSize: 14 }}>
                                          <div className="col-3"><b>PRICE</b><span style={{ color: "red" }}> *</span></div>
                                          <div className="col-2"><b>SPECIAL PRICE</b></div>
                                        </div>
                                        <div className="row text-dark">
                                          <div className="col-3">

                                            <input value={price} onChange={(e) => setPrice(e.target.value)} placeholder="" type="number" className="form-control" />

                                          </div>
                                          <div className="col-2">

                                            <input value={specialPrice} onChange={(e) => setSpecialPrice(e.target.value)} type="number" className="form-control" />
                                          </div>

                                          <div>
                                            <div style={{ marginTop: -20 }}>
                                              <div className="col"><b>PROMOTION DATE</b></div>
                                              <div className="row text-dark">
                                                <div className="col">

                                                  <input value={PromotionStartDate} onChange={e => SetPromotionStartDate(e.target.value)} placeholder="Start Date" type="date" className="form-control" />
                                                </div>
                                                <div className="col">

                                                  <input value={PromotionEndDate} onChange={e => SetPromotionEndDate(e.target.value)} placeholder="End Date" type="date" className="form-control" />
                                                </div>
                                              </div>
                                            </div>
                                            <div style={{ marginTop: 5 }}>
                                              <div className="col"><b>PROMOTION TIME</b></div>
                                              <div className="row text-dark">
                                                <div className="col">

                                                  <input value={PromotionStartTime} onChange={e => SetPromotionStartTime(e.target.value)} placeholder="Start Time" type="time" className="form-control" />
                                                </div>
                                                <div className="col">

                                                  <input value={PromotionEndTime} onChange={e => SetPromotionEndTime(e.target.value)} placeholder=" Time End " type="time" className="form-control" />
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </fieldset>
                            <div className="row">
                              <div className="col-md-12 pt-3 text-center">
                                <hr />
                                <button className="btn btn-primary col-md-3 mx-2" onClick={() => PreviousStep(3)}>Previous</button>
                                <button className="btn btn-primary col-md-3 mx-2" onClick={() => CurruntStep(4)} type="button" >Next</button>
                              </div>
                            </div>
                          </form>
                        </>
                        : null
                      }
                      {Step4 ?
                        <>
                          {/* <h3>Service & Delivery</h3> */}
                          {/* <h3>Service & Delivery</h3> */}
                          <form  >
                            <div className="row">
                              <div className="col-md-12 mb-2 text-center">
                                <button onClick={() => CurruntStep(1)} style={{ width: "22%", height: "50px", fontSize: "15px" }} className="btn btn-primary">Basic Information</button>
                                <button onClick={() => CurruntStep(2)} style={{ width: "22%", height: "50px", fontSize: "15px" }} className="btn btn-primary ml-2">Detail Description</button>
                                <button onClick={() => CurruntStep(3)} style={{ width: "22%", height: "50px", fontSize: "15px" }} className="btn btn-primary ml-2">Price & Stock</button>
                                <button onClick={() => CurruntStep(4)} style={{ width: "22%", height: "50px", fontSize: "15px" }} className="btn btn-primary ml-2">Service & Delivery</button>
                              </div>
                            </div>
                            <fieldset>
                              <div className="form-group form-float mt-2">
                                <div className="form-line">
                                  {/* <h5>Service</h5> */}
                                  {/* <hr /> */}
                                  {/* <div className="row">
                                    <div className="col-md-4">
                                      <div className="form-group form-float">
                                        <div className="form-line">
                                          <label className="form-label"><b>Warranty Type</b><span style={{ color: "red" }}> *</span></label>
                                          <select className="form-control">
                                            <option value={GetAllAIvendors.ProductDetails.Warranty} >Choose Type</option>
                                            <option  >Warranty</option>
                                            <option >no Warranty</option>
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      <div className="form-group form-float">
                                        <div className="form-line">
                                          <label className="form-label"><b>Warranty Period</b></label>
                                          <select className="form-control">
                                            <option>Select Period</option>
                                             <option></option>
                                            <option value={GetAllAIvendors.ProductDetails.Warranty_Period}>1 Month</option>
                                            <option value={GetAllAIvendors.ProductDetails.Warranty_Period}>6 Month</option>
                                            <option value={GetAllAIvendors.ProductDetails.Warranty_Period}>1 year</option>

                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-10">
                                      <div className="form-group form-float">
                                        <div className="form-line">
                                          <label className="form-label"><b>Warranty Policy</b></label>
                                          <input placeholder="" type="text" className="form-control" />
                                        </div>
                                      </div>
                                    </div>
                                  </div> */}
                                  <h5>Delivery</h5>
                                  <hr />

                                  


                                  <div className="row">
                                    <div className="col-md-4">
                                      <div className="form-group form-float">

                                        <div className="form-line">
                                          <label className="form-label"><b>Package Weight(Kg)</b><span style={{ color: "red" }}> *</span></label>
                                          <input value={weight} onChange={(e) => setWeight(e.target.value)} placeholder="" type="number" className="form-control" />
                                        </div>

                                      </div>
                                    </div>
                                    
                                  </div>

                                  <div className="row" style={{ paddingLeft: 20 }}>
                                    <div className="form-group form-float">
                                      <div className="form-line">
                                        <label className="form-label"><b>Package Dimensions(cm)</b><span style={{ color: "red" }}> *</span></label>
                                        <div className="row">
                                          <div className="col-md-4">
                                            <input value={length} onChange={(e) => setLength(e.target.value)} placeholder="Length(cm)" type="number" className="form-control" />

                                          </div>
                                          <div className="col-md-4">
                                            <input value={width} onChange={(e) => setWidth(e.target.value)} placeholder="Width(cm)" type="number" className="form-control" />

                                          </div>
                                          <div className="col-md-4">
                                            <input value={height} onChange={(e) => setHeight(e.target.value)} placeholder="Height(cm)" type="number" className="form-control" />

                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </fieldset>
                            <div className="row">
                              <div className="col-md-12 pt-3 text-center">
                                <hr />
                                <button className="btn btn-primary col-md-3 mx-2" onClick={() => PreviousStep(4)}>Previous</button>
                                <button className="btn btn-primary col-md-3 mx-2" onClick={() => editProduct()} type="button" >Update</button>
                              </div>
                            </div>
                          </form>
                        </>
                        : null
                      }
                    </div>
                  </div>
                  {/* <div className="mt-5 text-muted text-center">
                    Don't have an account? <a href="auth-register.html">Create One</a>
                  </div> */}
                </div>
              </div>
            </section>
          </div>
          {/* <Footer /> */}
        </div>
      </div>
    </>
  )
}