/* eslint-disable */
 /* eslint-disable */
import React, { useState, useEffect } from "react";
import Footer from "../../../components/footer/footer";
import Header from "../../../components/header/header";
import ProductTable from "./productTable";
import Swal from "sweetalert2";
import BaseUrl from "../../../../config/BaseUrl";
import MaterialTable from "material-table";
import image_url from "../../../../config/BaseUrl";
import { Link, NavLink, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import ViewProductDetails from "../viewProductdetails/ProductDetails";
import { getApiWithToken,PutApi,PostApi,DeleteApi } from "../../../../config/Api/FakeuserApi";
import Alert from "react-bootstrap/Alert";
import { RemoveRedEye } from "@material-ui/icons";
// import { makeStyles } from "@material-ui/core/styles";
// import MaterialTable from 'material-table'
import "./productManagement.css";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  input: {
    display: "none",
  },
}));

export default function ProductManagement(props) {
  const [GetAccounts, SetGetAccounts] = useState([]);
  const [Loader, setLoader] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  // const [GetAllAIvendors, SetGetAIllAvendors] = useState([])
  const [Active, SetActive] = useState(true);
  const [activateActivebtn, SetactivateActivebtn] = useState(false);
  const [deactivateActivebtn, SetdeactivateActivebtn] = useState(false);
  const [first, Setfirst] = useState(false);
  const [second, Setsecond] = useState(false);
  const [third, Setthird] = useState(false);
  const [fourth, Setfourth] = useState(false);
  const [fifth, Setfifth] = useState(false);
  const [six, Setsix] = useState(false);
  const [seven, Setseven] = useState(false);
  const [eight, Seteight] = useState(false);
  const [loaderActive , setLoaderActive] = useState(true)

  // console.log("get account",GetAccounts)

  const history = useHistory();

  const Token = localStorage.getItem("SellerToken");

  useEffect(() => {
    ViewAllProducts("All");
  }, []);

  // View ALL pending Products

  const ViewAllProducts = (status) => {
    setLoaderActive(true)

    if (status == "All") {
      SetactivateActivebtn(true);
      SetdeactivateActivebtn(false);
      Setfirst(true);
      Setsecond(false);
      Setthird(false);
      Setfourth(false);
      Setfifth(false);
      Setsix(false);
      Setseven(false);
      Seteight(false);

      SetActive("All");
    }
    if (status == "Pending") {
      SetactivateActivebtn(true);
      SetdeactivateActivebtn(false);
      Setfirst(false);
      Setsecond(false);
      Setthird(false);
      Setfourth(true);
      Setfifth(false);
      Setsix(false);
      Setseven(false);
      Seteight(false);
      SetActive("Pending");
    }

    if (status == "Online") {
      SetactivateActivebtn(false);
      SetdeactivateActivebtn(true);
      Setfirst(false);
      Setsecond(true);
      Setthird(false);
      Setfourth(false);
      Setfifth(false);
      Setsix(false);
      Setseven(false);
      Seteight(false);
      SetActive("Online");
    }

    if (status == "OutofStock") {
      SetactivateActivebtn(false);
      SetdeactivateActivebtn(true);
      Setfirst(false);
      Setsecond(false);
      Setthird(false);
      Setfourth(false);
      Setfifth(true);
      Setsix(false);
      Setseven(false);
      Seteight(false);
      SetActive("OutofStock");
    }

    if (status == "Inactive") {
      SetactivateActivebtn(true);
      SetdeactivateActivebtn(false);
      Setfirst(false);
      Setsecond(false);
      Setthird(false);
      Setfourth(false);
      Setfifth(false);
      Setsix(true);
      Setseven(false);
      Seteight(false);
      SetActive("Inactive");
    }

    if (status == "Suspended") {
      SetactivateActivebtn(false);
      SetdeactivateActivebtn(false);
      Setfirst(false);
      Setsecond(false);
      Setthird(false);
      Setfourth(false);
      Setfifth(false);
      Setsix(false);
      Setseven(true);
      Seteight(false);
      SetActive("Suspended");
      // SetActive(false);
    }
    // if (status == "Incomplete") {
    //     Setfirst(false)
    //     Setsecond(false)
    //     Setthird(false)
    //     Setfourth(false)
    //     Setfifth(true)
    //     Setsix(false)
    //     Setseven(false)
    //     Seteight(false)
    //     SetActive(false)

    // }

    if (status == "Deleted") {
      SetactivateActivebtn(true);
      Setfirst(false);
      Setsecond(false);
      Setthird(false);
      Setfourth(false);
      Setfifth(false);
      Setsix(false);
      Setseven(false);
      Seteight(true);
      SetActive("Deleted");

      // SetActive(false);
    }

    setLoader(true);

    getApiWithToken(
      `${BaseUrl.base_url}/AddProduct?Product_Status=${status}`,
      "",
      Token
    )
      .then(({ data }) => {
        setLoader(false);
        if (data.status) {
          SetGetAccounts(data.data);
          console.log("PENDING PRODCT==>", data);
          // setdisable(true);
          // $(".loader").hide();
          setLoaderActive(false)

        } else {
          // $(".loader").hide();
          Swal.fire({
            title: "Opps",
            text: data.message,
            icon: "error",
          });
          setLoaderActive(false)

        }
      })
      .catch(function (e) {
        // $(".loader").hide();
        Swal.fire({
          title: "Opps",
          text: e.message,
          icon: "error",
        });
        setLoaderActive(false)

      });
  };
  // console.log("rowdata==>",rowData)

  // UPDATE STATUS PUT API

  const UpdateStatus = (status, id) => {
    setLoader(true);
    var formdata = new FormData();

    formdata.append("id", id);
    formdata.append("Product_Status", status);

    PutApi(`${BaseUrl.base_url}/AddProduct`, formdata, Token)
      .then(({ data }) => {
        setLoader(false);
        if (data.status) {
          console.log("data==>", data);
          ViewAllProducts("Pending");
          // handleClose2()
          Swal.fire({
            title: "success",
            text: data.message,
            icon: "success",
          });
        } else {
          // $(".loader").hide();
          Swal.fire({
            title: "Opps",
            text: data.message,
            icon: "error",
          });
        }
      })
      .catch(function (e) {
        // $(".loader").hide();
        Swal.fire({
          title: "Opps",
          text: e.message,
          icon: "error",
        });
      });
  };

  
  if(loaderActive){
    return(
      <div style={{width:'100%',height:'100vh',display:'flex',justifyContent:'center',alignItems:'center'}}>
        
        <img src={'./assets/img/loader.svg'} alt="loader"/>
    
        </div>
    )}
 

  return (
    <>
      {/* {Loader ? <div className="loader" /> : null} */}
      <div id="app">
        <div className="main-wrapper main-wrapper-1">
          <Header />
          <div className="main-content">
            <section className="section">
              <div className="row">
                <div className="col-md-12">
                  <div className="card card-primary">
                    <div className="card-header">
                      <h4>Product Management</h4>
                    </div>
                    <div className="card-body">
                      <section className="section">
                        <div className="section-body">
                          <div className="row clearfix">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                              <div className="card">
                                <div className="card-body">
                                  <div className="card-body">
                                    <button
                                      type="button"
                                      onClick={() => ViewAllProducts("All")}
                                      // class="expTopbar btn btn-outline-primary"
                                      class={Active == "All" ? " expTopbar btn btn-primary active" : "expTopbar btn btn-outline-primary"}
                                    >
                                      All Products
                                    </button>
                                    {/* <button
                                      type="button"
                                      onClick={() => ViewAllProducts("Pending")}
                                      class={
                                        Active
                                          ? "expTopbar btn btn-primary active"
                                          : "expTopbar btn btn-outline-primary"
                                      }
                                    >
                                      Pending Products
                                    </button> */}
                                    <button
                                      type="button"
                                      onClick={() => ViewAllProducts("Online")}
                                      // class="expTopbar btn btn-outline-primary"
                                      class={Active == "Online" ? " expTopbar btn btn-primary active" : "expTopbar btn btn-outline-primary"}
                                    >
                                      Online Products
                                    </button>
                                    <button
                                      type="button"
                                      onClick={() =>
                                        ViewAllProducts("OutofStock")
                                      }
                                      // class="expTopbar btn btn-outline-primary"
                                      class={Active == "OutofStock" ? " expTopbar btn btn-primary active" : "expTopbar btn btn-outline-primary"}
                                    >
                                      Out of Stock
                                    </button>
                                    <button
                                      type="button"
                                      onClick={() =>
                                        ViewAllProducts("Inactive")
                                      }
                                      // class="expTopbar btn btn-outline-primary"
                                      class={Active == "Inactive" ? " expTopbar btn btn-primary active" : "expTopbar btn btn-outline-primary"}
                                    >
                                      InActive
                                    </button>
                                    <button
                                      type="button"
                                      onClick={() =>
                                        ViewAllProducts("Suspended")
                                      }
                                      // class="expTopbar btn btn-outline-primary"
                                      class={Active == "Suspended" ? " expTopbar btn btn-primary active" : "expTopbar btn btn-outline-primary"}
                                    >
                                      Suspended
                                    </button>
                                    {/* <button type="button" onClick={() => ViewAllProducts("Incomplete")} class="expTopbar btn btn-outline-primary">InComplete</button> */}
                                    <button
                                      type="button"
                                      onClick={() => ViewAllProducts("Deleted")}
                                      // class="expTopbar btn btn-outline-primary"
                                      class={Active == "Deleted" ? " expTopbar btn btn-primary active" : "expTopbar btn btn-outline-primary"}
                                    >
                                      Deleted
                                    </button>
                                    {/* <button type="button" onClick={() => UpdateStatus()} class="expTopbar btn btn-outline-primary">Deleted</button> */}
                                  </div>
                                  <div>
                                    {first ? (
                                      <Alert variant="secondary">
                                        {/* <i class="fa fa-exclamation-circle" aria-hidden="true"></i> */}
                                        <Alert.Heading
                                          style={{ color: "black" }}
                                        >
                                          <i
                                            style={{ color: "white" }}
                                            class="fa fa-info-circle"
                                          ></i>{" "}
                                          Explanation
                                        </Alert.Heading>
                                        <p>
                                          All product exclude delete and draft.
                                        </p>
                                      </Alert>
                                    ) : (
                                      ""
                                    )}
                                    {second ? (
                                      <Alert variant="secondary">
                                        <Alert.Heading
                                          style={{ color: "black" }}
                                        >
                                          {" "}
                                          <i
                                            style={{ color: "white" }}
                                            class="fa fa-info-circle"
                                          ></i>
                                          Explanation
                                        </Alert.Heading>
                                        <p>
                                          The product which is buyer can see and
                                          stock greter then 0 will appear in
                                          online tab.
                                        </p>
                                      </Alert>
                                    ) : (
                                      ""
                                    )}
                                    {third ? (
                                      <Alert variant="secondary">
                                        <Alert.Heading
                                          style={{ color: "black" }}
                                        >
                                          {" "}
                                          <i
                                            style={{ color: "white" }}
                                            class="fa fa-info-circle"
                                          ></i>
                                          Explanation
                                        </Alert.Heading>
                                        <p>
                                          Added products but are not yet online.
                                        </p>
                                      </Alert>
                                    ) : (
                                      ""
                                    )}

                                    {fourth ? (
                                      <Alert variant="secondary">
                                        <Alert.Heading
                                          style={{ color: "black" }}
                                        >
                                          {" "}
                                          <i
                                            style={{ color: "white" }}
                                            class="fa fa-info-circle"
                                          ></i>
                                          Explanation
                                        </Alert.Heading>
                                        <p>
                                          Products undergoing a quality check.
                                        </p>
                                      </Alert>
                                    ) : (
                                      ""
                                    )}

                                    {fifth ? (
                                      <Alert variant="secondary">
                                        <Alert.Heading
                                          style={{ color: "black" }}
                                        >
                                          {" "}
                                          <i
                                            style={{ color: "white" }}
                                            class="fa fa-info-circle"
                                          ></i>
                                          Explanation
                                        </Alert.Heading>
                                        <p>
                                          Product which sellable stock is 0 and
                                          include live SKU.
                                        </p>
                                      </Alert>
                                    ) : (
                                      ""
                                    )}

                                    {six ? (
                                      <Alert variant="secondary">
                                        <Alert.Heading
                                          style={{ color: "black" }}
                                        >
                                          {" "}
                                          <i
                                            style={{ color: "white" }}
                                            class="fa fa-info-circle"
                                          ></i>
                                          Explanation
                                        </Alert.Heading>
                                        <p>
                                          Product which include inactive SKU.
                                        </p>
                                      </Alert>
                                    ) : (
                                      ""
                                    )}

                                    {seven ? (
                                      <Alert variant="secondary">
                                        <Alert.Heading
                                          style={{ color: "black" }}
                                        >
                                          {" "}
                                          <i
                                            style={{ color: "white" }}
                                            class="fa fa-info-circle"
                                          ></i>
                                          Explanation
                                        </Alert.Heading>
                                        <p>
                                          Product which include punished by
                                          admin SKU.
                                        </p>
                                      </Alert>
                                    ) : (
                                      ""
                                    )}

                                    {eight ? (
                                      <Alert variant="secondary">
                                        <Alert.Heading
                                          style={{ color: "black" }}
                                        >
                                          {" "}
                                          <i
                                            style={{ color: "white" }}
                                            class="fa fa-info-circle"
                                          ></i>
                                          Explanation
                                        </Alert.Heading>
                                        <p>
                                          Deleted by seller or admin exclude
                                          draft.
                                        </p>
                                      </Alert>
                                    ) : (
                                      ""
                                    )}
                                  </div>

                                  <div
                                    className="card-body"
                                    style={{ padding: 5 }}
                                  >
                                    <div
                                      className="row"
                                      style={{ marginLeft: 10 }}
                                    >
                                      {/* <div className="col-1 onlineNav mr-3">
                                                                                <span className="dropdown">
                                                                                    <button style={{ height: 42 }} className="expTopbar btn btn-outline-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                                                        Category
                                                                                    </button>
                                                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                                        <li><a className="dropdown-item" href="#">Action</a></li>
                                                                                        <li><a className="dropdown-item" href="#">Another action</a></li>
                                                                                        <li><a className="dropdown-item" href="#">Something else here</a></li>
                                                                                    </ul>
                                                                                </span>
                                                                            </div> */}
                                      {/* <div className="col-2 onlineNav">
                                                                                <input type="text" className="form-control" id="autoSizingInput" placeholder="Product ID" />
                                                                            </div>
                                                                            <div className="col-2 onlineNav">
                                                                                <input type="text" className="form-control" id="autoSizingInput" placeholder="Product Name" />
                                                                            </div>
                                                                            <div className="col-2 onlineNav">
                                                                                <input type="text" className="form-control" id="autoSizingInput" placeholder="Seller SKU" />
                                                                            </div>
                                                                            <div className="col-2 onlineNav">
                                                                                <input type="text" className="form-control" id="autoSizingInput" placeholder="Shop SKU" />
                                                                            </div>
                                                                            <div className="col-2 onlineNav">
                                                                                <button style={{ height: 42 }} type="search" className="expTopbar btn btn-outline-secondary">Search</button>
                                                                            </div> */}
                                    </div>
                                    {/* <div className="row mt-2">
                                                                            <div className="col-6 pl-4">
                                                                                <span style={{marginTop: 10, marginLeft: 7}}>Selected : 0</span>
                                                                                <button type="search" className="expTopbar2 btn btn-outline-secondary">Inactivate</button>
                                                                                <button type="search" className="expTopbar2 btn btn-outline-secondary">Batch Delete</button>
                                                                            </div>
                                                                            <div className="col-6 onlineNav1">
                                                                                <div className="btn-group" role="group" aria-label="Basic outlined example">
                                                                                    <button type="button" className="btn btn-outline-primary">Product</button>
                                                                                    <button type="button" className="btn btn-outline-primary">SKU</button>
                                                                                </div>
                                                                            </div>

                                                                        </div> */}
                                    <div style={{ maxWidth: "100%" }}>
                                      <MaterialTable
                                        data={GetAccounts}
                                        title=""
                                        columns={[
                                          {
                                            title: "Product",
                                            field: "image1",
                                            render: (item) => (
                                              <img
                                                src={item.image1}
                                                alt=""
                                                border="3"
                                                height="80"
                                                width="80"
                                              />
                                            ),
                                          },
                                          {
                                            title: "Product_Name",
                                            field: "Product_Name",
                                          },
                                          {
                                            title: "Price",
                                            field: "Price",
                                            type: "numeric",
                                          },
                                          {
                                            title: "Special_Price",
                                            field: "Special_Price",
                                            type: "numeric",
                                          },
                                          { title: "Stock", field: "Stock" },
                                          {
                                            title: "Created",
                                            field: "Created",
                                          },

                                          {
                                            title: "Updated",
                                            field: "Updated",
                                          },

                                          {
                                            title: "Dropdown",
                                            render: (rowData) => (
                                              <DropdownButton
                                                id="dropdown-basic-button"
                                                title="Edit More"
                                              >
                                                {activateActivebtn ? (
                                                  <Dropdown.Item
                                                    onClick={() =>
                                                      UpdateStatus(
                                                        "Online",
                                                        rowData.Productid
                                                      )
                                                    }
                                                  >
                                                    Activate
                                                  </Dropdown.Item>
                                                ) : (
                                                  ""
                                                )}

                                                {deactivateActivebtn ? (
                                                  <Dropdown.Item
                                                    onClick={() =>
                                                      UpdateStatus(
                                                        "Inactive",
                                                        rowData.Productid
                                                      )
                                                    }
                                                  >
                                                    Deactivate
                                                  </Dropdown.Item>
                                                ) : (
                                                  ""
                                                )}

                                                <Dropdown.Item
                                                  onClick={() =>
                                                    UpdateStatus(
                                                      "Deleted",
                                                      rowData.Productid
                                                    )
                                                  }
                                                >
                                                  Delete
                                                </Dropdown.Item>
                                              </DropdownButton>
                                            ),
                                          },
                                        ]}
                                        onRowClick={(evt, selectedRow) =>
                                          setSelectedRow(
                                            selectedRow.tableData.id
                                          )
                                        }
                                        options={{
                                          rowStyle: (rowData) => ({
                                            selectableRow: false,
                                            backgroundColor:
                                              selectedRow ===
                                              rowData.tableData.id
                                                ? "#EEE"
                                                : "#FFF",
                                          }),
                                          actionsColumnIndex: -1,
                                        }}
                                        // other props
                                        actions={[
                                          (rowData) => ({
                                            icon: RemoveRedEye,
                                            tooltip: "View Seller Detail",
                                            isFreeAction: true,
                                            onClick: (event, rowData) => {
                                              console.log("rowData", rowData);
                                              history.push({
                                                pathname: "/ViewProductDetails",
                                                state: {
                                                  productid: rowData.Productid,
                                                },
                                              });
                                            },
                                          }),
                                        ]}
                                        options={{
                                          // maxBodyHeight: 400,
                                          // overflowX: 'x-scroll',
                                          actionsColumnIndex: 9,
                                          headerStyle: {
                                            backgroundColor: "#222",
                                            color: "white",
                                          },
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                  {/* <div className="mt-5 text-muted text-center">
                                        Don't have an account? <a href="auth-register.html">Create One</a>
                                    </div> */}
                </div>
              </div>
            </section>
          </div>
          {/* <Footer /> */}
        </div>
      </div>
    </>
  );
}
