/* eslint-disable */
import React, { useState, useEffect } from "react"
import { useHistory } from "react-router"
import BaseUrl from "../../../../config/BaseUrl"
import { postApi  } from "../../../../config/Api/FakeuserApi";
import Swal from 'sweetalert2'
import background from "../SellerLogin/background.png"

export default function SellerLogin() {
    const history = useHistory();
    const [Loader, setLoader] = useState(false)
    const [Email, SetEmail] = useState("")
    const [Password, SetPassword] = useState("")

        //validations
    const [emailErr, SetemailErr] = useState("")
    const [passwordErr, SetpasswordErr] = useState("")


    // const queryString = window.location.search; // This gives "?token=your-token-here"
    // const urlParams = new URLSearchParams(queryString);
    // const token = urlParams.get('token'); // Extract the token
      
    //   if (token) {
    //     console.log("Token from URL:", token);
    //     Swal.fire({
    //         title: "success",
    //         text: "Account Switch success",
    //         icon: "success"
    //     })
    //     localStorage.setItem('SellerToken', token);
    //     history.replace('/newDashboard')
    //   } else {
    //     console.log("No token found in the URL.");
    //   }
      

    const queryString = window.location.search; // This gives "?sellerToken=your-seller-token&buyerToken=your-buyer-token"
    const urlParams = new URLSearchParams(queryString);
    
    // Extract the tokens
    const sellerToken = urlParams.get('sellerToken'); 
    const buyerToken = urlParams.get('buyerToken');
    
    // Check and save the tokens in localStorage
    if (sellerToken) {
        console.log("Seller Token from URL:", sellerToken);
        localStorage.setItem('SellerToken',sellerToken)
        localStorage.setItem('SellerToken', sellerToken);
    } else {
        console.log("No Seller Token found in the URL.");
    }
    
    if (buyerToken) {
        localStorage.setItem('UserToken',buyerToken)
        console.log("Buyer Token from URL:", buyerToken);
        localStorage.setItem('BuyerToken', buyerToken);
    } else {
        console.log("No Buyer Token found in the URL.");
    }
    
    // Show success message and redirect if tokens are found
    if (sellerToken || buyerToken) {
        // Swal.fire({
        //     title: "Success",
        //     text: "Account Switch Success",
        //     icon: "success",
        // });
        // Redirect to the new dashboard or any desired page
        history.replace('/newDashboard');
    } else {
        console.log("No tokens found in the URL.");
    }
    

    // console.log(Password, "passsword==>")
    // console.log(Email, "email==>")



    const SLogin = () => {

        const isValid = formValidation();
        if (isValid== true){
        setLoader(true);
        var formdata = new FormData();
        formdata.append("Email", Email);
        formdata.append("Password", Password);



        postApi(`${BaseUrl.base_url}/SellerLogin`, formdata)
            .then(({ data }) => {
                setLoader(false);
                if (data.status) {
                    console.log("sallerdata==>",data)
                    // localStorage.setItem('seller', JSON.stringify(data?.seller));
                    localStorage.setItem('user', JSON.stringify(data?.user));
                    localStorage.setItem('seller', JSON.stringify(data?.seller));
                    localStorage.setItem('SellerToken', data.tokens.seller_token);
                    localStorage.setItem('UserToken', data.tokens.user_token);
                    // setdisable(true);
                    // $(".loader").hide();
                    history.replace('/newDashboard')
                    SetEmail("");
                    SetPassword("");
                    Swal.fire({
                        title: "success",
                        text: data.message,
                        icon: "success"
                    })
                } else {
                    // $(".loader").hide();
                setLoader(false);

                    Swal.fire({
                        title: "Opps",
                        text: data.message,
                        icon: "error"
                    })
                }
            }).catch(function (e) {
                // $(".loader").hide();
                setLoader(false);

                Swal.fire({
                    title: "Opps",
                    text: e.message,
                    icon: "error"
                })
            })

        }else{
            formValidation()
        }
    }


    //validations

 const formValidation = () => {
    const emailErr = {};
    const passwordErr = {};


    let isValid = true;

    if (Email.length == 0) {
        emailErr.firstNamenull = "Required Email";
        isValid = false;
    }

    if (Password.length == 0) {
        passwordErr.firstNamenull = "Required Password";
        isValid = false;
    }

    SetemailErr(emailErr);
    SetpasswordErr(passwordErr);

    return isValid;
}






    return (
        <>
            {Loader?<div className="loader" />:null}
            <div id="app" style={{backgroundImage: `url(${background})`, marginTop: '-50px', backgroundSize: 'cover', height: '100vh' }}>
                <section className="section">
                    <div className="container mt-5">
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <img style={{ width: "25%" }} alt="img" src="./assets/img/fav.png"></img>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
                                <div className="card card-primary">
                                    <div className="card-header">
                                        <h4>Login</h4>
                                    </div>
                                    <div className="card-body">
                                        <form method="" action="#" className="needs-validation" noValidate>
                                            <div className="form-group">
                                                <label htmlFor="email">Email</label>
                                                <input value={Email} onChange={(e)=>SetEmail(e.target.value)}  id="email" type="email" className="form-control" name="email" tabIndex={1} required autofocus />
                                                <div className="invalid-feedback">
                                                    Please fill in your email
                                                </div>
                                                <div>
                                    {Object.keys(emailErr).map((key) => {
                                        return <div style={{ color: "red" }}>{emailErr[key]}</div>
                                    })}
                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="d-block">
                                                    <label htmlFor="password" className="control-label">Password</label>
                                                </div>
                                                <input  value={Password} onChange={(e)=>SetPassword(e.target.value)} id="password" type="password" className="form-control" name="password" tabIndex={2} required />
                                                <div className="invalid-feedback">
                                                    please fill in your password
                                                </div>
                                                <div>
                                    {Object.keys(passwordErr).map((key) => {
                                        return <div style={{ color: "red" }}>{passwordErr[key]}</div>
                                    })}
                                </div>
                                            </div>



                                            <div className="form-group">
                                                <button onClick={()=>SLogin()}  type="button" className="btn btn-primary btn-lg btn-block" tabIndex={4}>
                                                    Login</button>
                                                <div className="mt-3 text-center">
                                                <a style={{cursor:'pointer',color:"#6e79fb"}} onClick={()=> history.push('/ForgetPassword')} >Forget Password</a>
                                                </div>
                                            </div>
                                        </form>

                                    <div className="mt-5 text-muted text-center">
                                            Don't have an account? <a className="customPointer" onClick={() => history.push('/Memberships')}>Create One</a>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}